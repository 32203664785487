import * as images1 from "../assets/images/cables/1/index";
import { slugify } from "./functions";

export const THERMOCOUPLES_1 = [
  {
    image: images1.t501,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T-501 THERMOCOUPLE WITH THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T-501 THERMOCOUPLE WITH THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t502,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 502 THERMOCOUPLE WITH THERMOWELL & ADJUSTABLE FLANGE",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 502 THERMOCOUPLE WITH THERMOWELL & ADJUSTABLE FLANGE")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t503,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 503 THERMOCOUPLE WITH TAPER THERMOWELL & ADJUSTABLE FLANGE",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 503 THERMOCOUPLE WITH TAPER THERMOWELL & ADJUSTABLE FLANGE")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t504,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 504 THERMOCOUPLE WITH STRAIGHT THERMOWELL, ADJUSTABLE COMPENSATION FITTING & CABLE",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify(
      "T - 504 THERMOCOUPLE WITH STRAIGHT THERMOWELL, ADJUSTABLE COMPENSATION FITTING & CABLE"
    )}`,
    data: [
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t506,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 506 THERMOCOUPLE WITH THERMOWELL & COATING",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 506 THERMOCOUPLE WITH THERMOWELL & COATING")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t651,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 651 THERMOCOUPLE WITH N/U/N & TAPER THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 651 THERMOCOUPLE WITH N/U/N & TAPER THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t652,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 652 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 652 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t653,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 653 THERMOCOUPLE WITH N/U/N & STRAIGHT THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 653 THERMOCOUPLE WITH N/U/N & STRAIGHT THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t650,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 650 THERMOCOUPLE WITH NIPPLE & TAPER THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 650 THERMOCOUPLE WITH NIPPLE & TAPER THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t654,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 654 THERMOCOUPLE WITH STRAIGHT THERMOWELL, ADJUSTABLE COMPRESSION FITTING",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 654 THERMOCOUPLE WITH STRAIGHT THERMOWELL, ADJUSTABLE COMPRESSION FITTING")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t655,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 655 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 655 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t656,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 656 THERMOCOUPLE WITH TAPERED THERMOWELL & ADJUSTABLE COMPENSATION FITTING",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify(
      "T - 656 THERMOCOUPLE WITH TAPERED THERMOWELL & ADJUSTABLE COMPENSATION FITTING"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t657,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 657 THERMOCOUPLE WITH SOLID SINTERED TUNGSTEN CARBIDE THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 657 THERMOCOUPLE WITH SOLID SINTERED TUNGSTEN CARBIDE THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Solid Sintered Tungsten Carbide (SSTC)\n        ",
            value: "SSTC",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 60 (21.34) (1/2SCH60)',
            value: "1/2SCH60",
          },
          {
            title: '1/2"SCH 120 (21.34) (1/2SCH120)',
            value: "1/2SCH120",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH10)',
            value: "3/4SCH10",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 60 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 120 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH10)',
            value: "1SCH10",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 60 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 120 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH10)',
            value: "1-1/4SCH10",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 60 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 120 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 10 (48.26) (1-1/2SCH10)',
            value: "1-1/2SCH10",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '1 3/4"SCH 10 (60.33) (1-3/4SCH10)',
            value: "1-3/4SCH10",
          },
          {
            title: '1 3/4"SCH 40 (60.33) (1-3/4SCH40)',
            value: "1-3/4SCH40",
          },
          {
            title: '1 3/4"SCH 60 (60.33) (1-3/4SCH60)',
            value: "1-3/4SCH60",
          },
          {
            title: '1 3/4"SCH 120 (60.33) (1-3/4SCH120)',
            value: "1-3/4SCH120",
          },
          {
            title: '1 3/4"SCH 160 (60.33) (1-3/4SCH160)',
            value: "1-3/4SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 60 (73.03) (2SCH60)',
            value: "2SCH60",
          },
          {
            title: '2"SCH 120 (73.03) (2SCH120)',
            value: "2SCH120",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (18.04) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (17.12) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 60 (15.80) (1/2SCH60)',
            value: "1/2SCH60",
          },
          {
            title: '1/2"SCH 120 (13.88) (1/2SCH120)',
            value: "1/2SCH120",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (23.37) (3/4SCH10)',
            value: "3/4SCH10",
          },
          {
            title: '3/4"SCH 40 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 60 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 120 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (30.10) (1SCH10)',
            value: "1SCH10",
          },
          {
            title: '1"SCH 40 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 60 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 120 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (38.86) (1-1/4SCH10)',
            value: "1-1/4SCH10",
          },
          {
            title: '1-1/4"SCH 40 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 60 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 120 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (44.96) (1-1/2SCH10)',
            value: "1-1/2SCH10",
          },
          {
            title: '1-1/2"SCH 40 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 60 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 120 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '1-3/4"SCH 10 (57.03) (1-3/4SCH10)',
            value: "1-3/4SCH10",
          },
          {
            title: '1-3/4"SCH 40 (54.79) (1-3/4SCH40)',
            value: "1-3/4SCH40",
          },
          {
            title: '1-3/4"SCH 60 (52.51) (1-3/4SCH60)',
            value: "1-3/4SCH60",
          },
          {
            title: '1-3/4"SCH 120 (49.25) (1-3/4SCH120)',
            value: "1-3/4SCH120",
          },
          {
            title: '1-3/4"SCH 160 (42.91) (1-3/4SCH160)',
            value: "1-3/4SCH160",
          },
          {
            title: '2"SCH 10 (68.81) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (66.93) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 60 (62.71) (2SCH60)',
            value: "2SCH60",
          },
          {
            title: '2"SCH 120 (59.01) (2SCH120)',
            value: "2SCH120",
          },
          {
            title: '2"SCH 160 (53.97) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT )',
            value: "1/4NPT-",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t726,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 726 THERMOCOUPLE WITH NIPPLE & FLANGE THERMOWELL TAPER",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 726 THERMOCOUPLE WITH NIPPLE & FLANGE THERMOWELL TAPER")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t727,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 727 THERMOCOUPLE WITH N/U/N & FLANGE THERMOWELL TAPER",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 727 THERMOCOUPLE WITH N/U/N & FLANGE THERMOWELL TAPER")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t728,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 728 THERMOCOUPLE WITH FLANGE THERMOWELL TAPERED & ADJUSTABLE COMPENSATION FITTING",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify(
      "T - 728 THERMOCOUPLE WITH FLANGE THERMOWELL TAPERED & ADJUSTABLE COMPENSATION FITTING"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t729,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 729 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 729 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t730,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 730 THERMOCOUPLE WITH N/U/N & STRAIGHT THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 730 THERMOCOUPLE WITH N/U/N & STRAIGHT THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t731,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 731 THERMOCOUPLE WITH STRAIGHT THERMOWELL & FITTING",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 731 THERMOCOUPLE WITH STRAIGHT THERMOWELL & FITTING")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t732,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 732 THERMOCOUPLE WITH N/U/N & STEPPED THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 732 THERMOCOUPLE WITH N/U/N & STEPPED THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "10 (10)",
            value: "10",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160 )',
            value: "1SCH160-",
          },
          {
            title: '1-1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 60 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (48.26) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 60 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 60 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 120 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Min. 5 mm (Min. 5 mm)",
            value: "Min.-5-mm",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Tip Length "TL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Stepped Length L1",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t733,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 733 THERMOCOUPLE WITH BUILT UP FABRICATED THERMOWELL",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 733 THERMOCOUPLE WITH BUILT UP FABRICATED THERMOWELL")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Thermowell ID2",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6.5",
            value: "6.5",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12.5",
            value: "12.5",
          },
          {
            title: "13.5",
            value: "13.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images1.t734,
    product: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    title: "T - 734 THERMOCOUPLE WITH THERMOWELL & FLANGE WITH PROTECTION TUBEE",
    path: `/products/${slugify(
      "Base Metal Thermocouples With Thermowells / Protection Tubes"
    )}/${slugify("T - 734 THERMOCOUPLE WITH THERMOWELL & FLANGE WITH PROTECTION TUBEE")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "protection tube (Guide tube) Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS310 (SS310)",
            value: "SS310",
          },
          {
            title: "SS316 (SS316)",
            value: "SS316",
          },
          {
            title: "Mild Steel (MS)",
            value: "MS",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "protection tube (Guide tube) OD",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "70.03",
            value: "70.03",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Protection Tube length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
];
