import { slugify } from "./functions";
import * as images1 from "../assets/images/cables/1/index";
import * as images2 from "../assets/images/cables/2/index";
import * as images3 from "../assets/images/cables/3/index";
import * as images4 from "../assets/images/cables/4/index";
import * as images5 from "../assets/images/cables/5/index";
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
import * as images6 from "../assets/images/cables/6/index";
import * as images7 from "../assets/images/cables/7/index";
import * as images8 from "../assets/images/cables/8/index";
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
import * as images9 from "../assets/images/cables/9/index";
import * as images10 from "../assets/images/cables/10/index";
import * as images11 from "../assets/images/cables/11/index";
import * as images12 from "../assets/images/cables/12/index";
import * as images13 from "../assets/images/cables/13/index";
import * as images14 from "../assets/images/cables/14/index";
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
import * as images15 from "../assets/images/cables/15/index";
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
import * as images16 from "../assets/images/cables/16/index";
import * as images17 from "../assets/images/cables/17/index";
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
import * as images18 from "../assets/images/cables/18/index";
import * as images19 from "../assets/images/cables/19/index";

import BaseMetalThermocouple from "../assets/images/cables/BaseMetalThermocouple.png";

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
import * as pdf16 from "../assets/images/cables/pdf16/index";
import * as pdf17 from "../assets/images/cables/pdf17/index";

const MineralInsulatedThermocoupleCable = () => {
  return (
    <>
      <table className="the-table" style={{ height: "538px", width: "100%", float: "left" }}>
        <tbody>
          <tr style={{ height: "101px" }}>
            <th style={{ width: "63.5556px", height: "60px" }}>
              <p>Number of Pair</p>
            </th>
            <th style={{ width: "74.2222px", height: "60px" }}>
              Sheath
              <br />
              Diameter
              <br />
              (mm)
            </th>
            <th style={{ width: "80.4444px", height: "60px" }}>
              Nominal
              <br />
              Wall
              <br />
              Thickness
              <br />
              (mm)
            </th>
            <th style={{ width: "74.2222px", height: "60px" }}>
              Element
              <br />
              Diameter
              <br />
              (mm)
            </th>
            <th style={{ width: "82.2222px", height: "60px" }}>
              Insulation
              <br />
              Thickness
              <br />
              (mm)
            </th>
            <th style={{ width: "87.5556px", height: "60px" }}>
              Insulation
              <br />
              Resistance
              <br />
              (MΏ)
            </th>
            <th style={{ width: "66.2222px", height: "60px" }}>
              Sheath
              <br />
              Material
            </th>
            <th style={{ width: "83.1111px", height: "60px" }}>
              Conductor
              <br />
              Type
            </th>
            <th style={{ width: "82.2222px", height: "60px" }}>Insulation</th>
            <th style={{ width: "89.3333px", height: "60px" }}>
              Calibration
              <br />
              Accuracy
            </th>
          </tr>
          <tr style={{ height: "21px" }}>
            <td style={{ width: "63.5556px", height: "174px" }} rowSpan={11}>
              <strong>Simplex</strong>
            </td>
            <td style={{ width: "74.2222px", height: "21px", textAlign: "center" }}>1.50</td>
            <td style={{ width: "80.4444px", height: "21px", textAlign: "center" }}>≥ 0.15</td>
            <td style={{ width: "74.2222px", height: "21px", textAlign: "center" }}>≥ 0.23</td>
            <td style={{ width: "82.2222px", height: "21px", textAlign: "center" }}>
              &nbsp;&nbsp; ≥ 0.11
            </td>
            <td style={{ width: "87.5556px", height: "174px", textAlign: "center" }} rowSpan={11}>
              ≥ 1000 MΏ
            </td>
            <td style={{ width: "66.2222px", height: "174px", textAlign: "center" }} rowSpan={11}>
              <p>SS316L</p>
              <p>SS321</p>
              <p>SS310</p>
              <p>SS446</p>
              <p>SS304</p>
              <p>Inconel 600</p>
              <p>Inconel 601</p>
              <p>and others</p>
            </td>
            <td style={{ width: "83.1111px", height: "174px", textAlign: "center" }} rowSpan={11}>
              <p>K Type</p>
              <p>J Type</p>
              <p>E Type</p>
              <p>N-Type</p>
              <p>R Type</p>
              <p>S Type</p>
            </td>
            <td style={{ width: "82.2222px", height: "174px", textAlign: "center" }} rowSpan={11}>
              <p>
                MgO
                <br />
                (Standard
                <br />
                Purity ≥<br />
                97%)
              </p>
              <p>
                <br />
                MgO
                <br />
                (High Purity
                <br />
                ≥99.4%)
              </p>
            </td>
            <td style={{ width: "89.3333px", height: "174px", textAlign: "center" }} rowSpan={11}>
              Class
              <br />
              1/Class 2<br />
              Accuracy
              <br />
              as per IEC
              <br />
              584-2/ANSI
              <br />
              MC
              <br />
              96.1/ASTM
              <br />E 230
            </td>
          </tr>
          <tr style={{ height: "31px" }}>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>2.00&nbsp;</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 0.20</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>
              ≥ 0.30&nbsp;
            </td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥ 0.14</td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "74.2222px", height: "14px", textAlign: "center" }}>2.50</td>
            <td style={{ width: "80.4444px", height: "14px", textAlign: "center" }}>≥ 0.25</td>
            <td style={{ width: "74.2222px", height: "14px", textAlign: "center" }}>≥ 0.38</td>
            <td style={{ width: "82.2222px", height: "14px", textAlign: "center" }}>≥ 0.18</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>3.00</td>
            <td style={{ width: "80.4444px", height: "18px", textAlign: "center" }}>≥ 0.30</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>≥ 0.45</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>≥ 0.21</td>
          </tr>
          <tr style={{ height: "19px" }}>
            <td style={{ width: "74.2222px", height: "19px", textAlign: "center" }}>4.50</td>
            <td style={{ width: "80.4444px", height: "19px", textAlign: "center" }}>≥ 0.45</td>
            <td style={{ width: "74.2222px", height: "19px", textAlign: "center" }}>≥ 0.68</td>
            <td style={{ width: "82.2222px", height: "19px", textAlign: "center" }}>≥ 0.32</td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>6.00</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 0.60</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>≥ 0.90</td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥ 0.42</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>6.40</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 0.64</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>≥ 0.96</td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥ 0.45</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>8.00</td>
            <td style={{ width: "80.4444px", height: "18px", textAlign: "center" }}>≥ 0.80</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>≥ 1.20</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>≥ 0.56</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>9.50</td>
            <td style={{ width: "80.4444px", height: "18px", textAlign: "center" }}>≥ 0.95</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>≥ 1.43</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>≥ 0.67</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td
              style={{
                width: "74.2222px",
                height: "18px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              10.00
            </td>
            <td style={{ width: "80.4444px", height: "18px", textAlign: "center" }}>≥ 1.00</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>≥ 1.50</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>≥ 0.70</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>12.70</td>
            <td style={{ width: "80.4444px", height: "18px", textAlign: "center" }}>≥ 1.27</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>≥ 1.91</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>≥ 0.70</td>
          </tr>
          <tr style={{ height: "21px" }}>
            <td style={{ width: "63.5556px", height: "216px" }} rowSpan={11}>
              <strong>Duplex</strong>
            </td>
            <td style={{ width: "74.2222px", height: "21px", textAlign: "center" }}>1.50</td>
            <td style={{ width: "80.4444px", height: "21px", textAlign: "center" }}>≥ 0.15</td>
            <td style={{ width: "74.2222px", height: "21px", textAlign: "center" }}>≥ 0.18</td>
            <td style={{ width: "82.2222px", height: "21px", textAlign: "center" }}>≥ 0.08</td>
            <td style={{ width: "87.5556px", height: "216px", textAlign: "center" }} rowSpan={11}>
              ≥ 1000 MΏ
            </td>
            <td style={{ width: "66.2222px", height: "216px", textAlign: "center" }} rowSpan={11}>
              <p>SS316L</p>
              <p>SS321</p>
              <p>SS310</p>
              <p>SS446</p>
              <p>SS304</p>
              <p>Inconel 600</p>
              <p>Inconel 601</p>
              <p>and others</p>
            </td>
            <td style={{ width: "83.1111px", height: "216px", textAlign: "center" }} rowSpan={11}>
              <p>K Type</p>
              <p>J Type</p>
              <p>E Type</p>
              <p>N-Type</p>
              <p>R Type</p>
              <p>S Type</p>
            </td>
            <td style={{ width: "82.2222px", height: "216px", textAlign: "center" }} rowSpan={11}>
              <p>
                MgO
                <br />
                (Standard
                <br />
                Purity ≥<br />
                97%)
              </p>
              <p>
                <br />
                MgO
                <br />
                (High Purity
                <br />
                ≥99.4%)
              </p>
            </td>
            <td style={{ width: "89.3333px", height: "216px", textAlign: "center" }} rowSpan={11}>
              Class
              <br />
              1/Class 2<br />
              Accuracy
              <br />
              as per IEC
              <br />
              584-2/ANSI
              <br />
              MC
              <br />
              96.1/ASTM
              <br />E 230
            </td>
          </tr>
          <tr style={{ height: "31px" }}>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>2.00</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 0.20</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>≥0.24</td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥ 0.11</td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "74.2222px", height: "15px", textAlign: "center" }}>2.50</td>
            <td style={{ width: "80.4444px", height: "15px", textAlign: "center" }}>≥ 0.25</td>
            <td style={{ width: "74.2222px", height: "15px", textAlign: "center" }}>≥ 0.30</td>
            <td style={{ width: "82.2222px", height: "15px", textAlign: "center" }}>≥ 0.14</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "25px", textAlign: "center" }}>3.00</td>
            <td style={{ width: "80.4444px", height: "25px", textAlign: "center" }}>≥ 0.30</td>
            <td style={{ width: "74.2222px", height: "25px", textAlign: "center" }}>≥0.36</td>
            <td style={{ width: "82.2222px", height: "25px", textAlign: "center" }}>≥ 0.17</td>
          </tr>
          <tr style={{ height: "19px" }}>
            <td style={{ width: "74.2222px", height: "19px", textAlign: "center" }}>4.50</td>
            <td style={{ width: "80.4444px", height: "19px", textAlign: "center" }}>≥ 0.45</td>
            <td style={{ width: "74.2222px", height: "19px", textAlign: "center" }}>≥ 0.54</td>
            <td style={{ width: "82.2222px", height: "19px", textAlign: "center" }}>≥ 0.25</td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "74.2222px", height: "28px", textAlign: "center" }}>6.00</td>
            <td style={{ width: "80.4444px", height: "28px", textAlign: "center" }}>≥ 0.60</td>
            <td style={{ width: "74.2222px", height: "28px", textAlign: "center" }}>≥ 0.72</td>
            <td style={{ width: "82.2222px", height: "28px", textAlign: "center" }}>≥ 0.33</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>6.40</td>
            <td style={{ width: "80.4444px", height: "18px", textAlign: "center" }}>≥ 0.64</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>≥ 0.77</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>≥ 0.35</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>8.00</td>
            <td style={{ width: "80.4444px", height: "18px", textAlign: "center" }}>≥ 0.80</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>≥0.96</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>≥ 0.44</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>9.50</td>
            <td style={{ width: "80.4444px", height: "18px", textAlign: "center" }}>≥ 0.95</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>≥ 1.14</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>≥ 0.52</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>10.00</td>
            <td style={{ width: "80.4444px", height: "18px", textAlign: "center" }}>≥ 1.00</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>≥ 1.20</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>≥0.55</td>
          </tr>
          <tr style={{ height: "36px" }}>
            <td style={{ width: "74.2222px", textAlign: "center", height: "26px" }}>12.70</td>
            <td style={{ width: "80.4444px", textAlign: "center", height: "26px" }}>≥ 1.27</td>
            <td style={{ width: "74.2222px", textAlign: "center", height: "26px" }}>≥ 1.52</td>
            <td style={{ width: "82.2222px", textAlign: "center", height: "26px" }}>≥ 0.70</td>
          </tr>
          <tr style={{ height: "21px" }}>
            <td style={{ width: "63.5556px", height: "70px" }} rowSpan={7}>
              <strong>Triplex</strong>
            </td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>3.00</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 0.30</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>≥ 0.27</td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥ 0.12</td>
            <td style={{ width: "87.5556px", height: "70px", textAlign: "center" }} rowSpan={7}>
              ≥ 1000 MΏ
            </td>
            <td style={{ width: "66.2222px", height: "70px", textAlign: "center" }} rowSpan={7}>
              <p>SS316L</p>
              <p>SS321</p>
              <p>SS310</p>
              <p>SS446</p>
              <p>SS304</p>
              <p>Inconel 600</p>
              <p>Inconel 601</p>
              <p>and others</p>
            </td>
            <td style={{ width: "83.1111px", height: "70px", textAlign: "center" }} rowSpan={7}>
              <p>K Type</p>
              <p>J Type</p>
              <p>E Type</p>
              <p>N-Type</p>
              <p>R Type</p>
              <p>S Type</p>
            </td>
            <td style={{ width: "82.2222px", height: "70px", textAlign: "center" }} rowSpan={7}>
              <p>
                MgO
                <br />
                (Standard
                <br />
                Purity ≥<br />
                97%)
              </p>
              <p>
                <br />
                MgO
                <br />
                (High Purity
                <br />
                ≥99.4%)
              </p>
            </td>
            <td style={{ width: "89.3333px", height: "70px", textAlign: "center" }} rowSpan={7}>
              Class
              <br />
              1/Class 2<br />
              Accuracy
              <br />
              as per IEC
              <br />
              584-2/ANSI
              <br />
              MC
              <br />
              96.1/ASTM
              <br />E 230
            </td>
          </tr>
          <tr style={{ height: "19px" }}>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>4.50</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 0.45</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>≥ 0.41</td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥ 0.18</td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>6.00</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 0.60</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>≥0.54</td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥ 0.24</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>6.40</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 0.64</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>≥ 0.58</td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥ 0.26</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>8.00</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 0.80</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>≥0.72</td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥ 0.32</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>9.50</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 0.95</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>≥ 0.86</td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥ 0.38</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>10.00</td>
            <td style={{ width: "80.4444px", height: "10px", textAlign: "center" }}>≥ 1.00</td>
            <td style={{ width: "74.2222px", height: "10px", textAlign: "center" }}>≥ 0.90</td>
            <td style={{ width: "82.2222px", height: "10px", textAlign: "center" }}>≥0.40</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "63.5556px", height: "18px" }}>&nbsp;</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>12.70</td>
            <td style={{ width: "80.4444px", height: "18px", textAlign: "center" }}>≥ 1.27</td>
            <td style={{ width: "74.2222px", height: "18px", textAlign: "center" }}>≥1.14</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>≥ 0.51</td>
            <td style={{ width: "87.5556px", height: "18px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "66.2222px", height: "18px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "83.1111px", height: "18px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "82.2222px", height: "18px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "89.3333px", height: "18px", textAlign: "center" }}>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <p style={{ marginTop: "6rem", marginBottom: "2rem" }}>
        Standard Deviation of Mineral Insulated Thermocouple Cable as per IEC 584-2
      </p>
      <table className="the-table" style={{ height: "93px", width: "751px" }} width={469}>
        <tbody>
          <tr style={{ height: "19px" }}>
            <th style={{ width: "52.7167px", height: "21px", textAlign: "center" }}>Type</th>
            <th style={{ width: "169.217px", height: "21px", textAlign: "center" }}>
              Temperature Range
            </th>
            <th style={{ width: "239.55px", height: "21px", textAlign: "center" }}>
              Tolerance for Class 1 Accuracy
            </th>
            <th style={{ width: "251.517px", height: "21px", textAlign: "center" }}>
              Tolerance for Class 2 Accuracy
            </th>
          </tr>
          <tr style={{ height: "16px" }}>
            <td style={{ width: "52.7167px", height: "16px", textAlign: "center" }}>K/N</td>
            <td style={{ width: "169.217px", height: "16px", textAlign: "center" }}>
              -40°C to +1100°C
            </td>
            <td style={{ width: "239.55px", height: "16px", textAlign: "center" }}>
              +/- 1.5°C or +/-0.004(t)
            </td>
            <td style={{ width: "251.517px", height: "16px", textAlign: "center" }}>
              +/- 2.5°C or +/-0.0075(t)
            </td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "52.7167px", height: "18px", textAlign: "center" }}>J</td>
            <td style={{ width: "169.217px", height: "18px", textAlign: "center" }}>
              -40°C to +750°C
            </td>
            <td style={{ width: "239.55px", height: "18px", textAlign: "center" }}>
              +/- 1.5°C or +/-0.004(t)
            </td>
            <td style={{ width: "251.517px", height: "18px", textAlign: "center" }}>
              +/- 2.5°C or +/-0.0075(t)
            </td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "52.7167px", height: "18px", textAlign: "center" }}>T</td>
            <td style={{ width: "169.217px", height: "18px", textAlign: "center" }}>
              -40°C to +350°C
            </td>
            <td style={{ width: "239.55px", height: "18px", textAlign: "center" }}>
              +/- 0.5°C or +/-0.004(t)
            </td>
            <td style={{ width: "251.517px", height: "18px", textAlign: "center" }}>
              +/-1.0°C or +/-0.0075(t)
            </td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td style={{ width: "52.7167px", height: "10px", textAlign: "center" }}>N</td>
            <td style={{ width: "169.217px", height: "10px", textAlign: "center" }}>
              -40°C to +800°C
            </td>
            <td style={{ width: "239.55px", height: "10px", textAlign: "center" }}>
              +/- 1.5°C or +/-0.004(t)
            </td>
            <td style={{ width: "251.517px", height: "10px", textAlign: "center" }}>
              +/- 2.5°C or +/-0.0075(t)
            </td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td style={{ width: "52.7167px", height: "10px", textAlign: "center" }}>R/S</td>
            <td style={{ width: "169.217px", height: "10px", textAlign: "center" }}>
              0°C to +1600°C
            </td>
            <td style={{ width: "239.55px", height: "10px", textAlign: "center" }}>
              +/-1°C or +/-[1+0.003(t-1100)]
            </td>
            <td style={{ width: "251.517px", height: "10px", textAlign: "center" }}>
              +/- 1.5°C or +/-0.0075(t)
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ marginTop: "6rem", marginBottom: "2rem" }}>
        Mineral Insulated Thermocouple Cables with Precious Thermocouple:
      </p>
      <table className="the-table" style={{ height: "145px", width: "834px" }} width={469}>
        <tbody>
          <tr style={{ height: "36px" }}>
            <th style={{ width: "820px", height: "36px" }} colSpan={9}>
              <br />
              Resistance of Precious Metal Mineral Insulated Thermocouples in different Atmospheres
            </th>
          </tr>
          <tr style={{ height: "19px" }}>
            <th style={{ width: "64px", height: "19px", textAlign: "center" }}>Sheath Material</th>
            <th style={{ width: "112.717px", height: "19px", textAlign: "center" }}>
              <span
                style={{
                  left: "164.093px",
                  top: "557.945px",
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  transform: "scaleX(1.05058)",
                }}
              >
                Thermocouple
              </span>
            </th>
            <th style={{ width: "126.333px", height: "19px", textAlign: "center" }}>
              Max.Operating Temperature
            </th>
            <th style={{ width: "62px", height: "19px", textAlign: "center" }}>Oxygen</th>
            <th style={{ width: "68px", height: "19px", textAlign: "center" }}>Nitrogen</th>
            <th style={{ width: "81.05px", height: "19px", textAlign: "center" }}>Hydrogen</th>
            <th style={{ width: "78.2167px", height: "19px", textAlign: "center" }}>Carbon</th>
            <th style={{ width: "84.0667px", height: "19px", textAlign: "center" }}>Chlorine</th>
            <th style={{ width: "79.6167px", height: "19px", textAlign: "center" }}>Sulphur</th>
          </tr>
          <tr style={{ height: "16px" }}>
            <td style={{ width: "64px", height: "52px", textAlign: "center" }} rowSpan={3}>
              <br />
              Inconel 600 2.4816
            </td>
            <td style={{ width: "112.717px", height: "16px", textAlign: "center" }}>S Type</td>
            <td style={{ width: "126.333px", height: "16px", textAlign: "center" }}>1100°C</td>
            <td style={{ width: "62px", height: "16px", textAlign: "center" }}>Good</td>
            <td style={{ width: "68px", height: "16px", textAlign: "center" }}>Good</td>
            <td style={{ width: "81.05px", height: "16px", textAlign: "center" }}>Good</td>
            <td style={{ width: "78.2167px", height: "16px", textAlign: "center" }}>Good</td>
            <td style={{ width: "84.0667px", height: "16px", textAlign: "center" }}>Good</td>
            <td style={{ width: "79.6167px", height: "16px", textAlign: "center" }}>Conditional</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "112.717px", height: "18px", textAlign: "center" }}>R Type</td>
            <td style={{ width: "126.333px", height: "18px", textAlign: "center" }}>1100°C</td>
            <td style={{ width: "62px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "68px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "81.05px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "78.2167px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "84.0667px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "79.6167px", height: "18px", textAlign: "center" }}>Conditional</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "112.717px", height: "18px", textAlign: "center" }}>B Type</td>
            <td style={{ width: "126.333px", height: "18px", textAlign: "center" }}>1100°C</td>
            <td style={{ width: "62px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "68px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "81.05px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "78.2167px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "84.0667px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "79.6167px", height: "18px", textAlign: "center" }}>Conditional</td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td style={{ width: "64px", height: "38px", textAlign: "center" }} rowSpan={3}>
              <span
                style={{
                  left: "80.9383px",
                  top: "740.613px",
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  transform: "scaleX(1.02825)",
                }}
              >
                pt10%Rh
              </span>
            </td>
            <td style={{ width: "112.717px", height: "10px", textAlign: "center" }}>S Type</td>
            <td style={{ width: "126.333px", height: "10px", textAlign: "center" }}>1300°C</td>
            <td style={{ width: "62px", height: "10px", textAlign: "center" }}>Good</td>
            <td style={{ width: "68px", height: "10px", textAlign: "center" }}>Good</td>
            <td style={{ width: "81.05px", height: "10px", textAlign: "center" }}>Conditional</td>
            <td style={{ width: "78.2167px", height: "10px", textAlign: "center" }}>Conditional</td>
            <td style={{ width: "84.0667px", height: "10px", textAlign: "center" }}>Conditional</td>
            <td style={{ width: "79.6167px", height: "10px", textAlign: "center" }}>Conditional</td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td style={{ width: "112.717px", height: "10px", textAlign: "center" }}>R Type</td>
            <td style={{ width: "126.333px", height: "10px", textAlign: "center" }}>1300°C</td>
            <td style={{ width: "62px", height: "10px", textAlign: "center" }}>Good</td>
            <td style={{ width: "68px", height: "10px", textAlign: "center" }}>Good</td>
            <td style={{ width: "81.05px", height: "10px", textAlign: "center" }}>Conditional</td>
            <td style={{ width: "78.2167px", height: "10px", textAlign: "center" }}>Conditional</td>
            <td style={{ width: "84.0667px", height: "10px", textAlign: "center" }}>Conditional</td>
            <td style={{ width: "79.6167px", height: "10px", textAlign: "center" }}>Conditional</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "112.717px", height: "18px", textAlign: "center" }}>B Type</td>
            <td style={{ width: "126.333px", height: "18px", textAlign: "center" }}>1300°C</td>
            <td style={{ width: "62px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "68px", height: "18px", textAlign: "center" }}>Good</td>
            <td style={{ width: "81.05px", height: "18px", textAlign: "center" }}>Conditional</td>
            <td style={{ width: "78.2167px", height: "18px", textAlign: "center" }}>Conditional</td>
            <td style={{ width: "84.0667px", height: "18px", textAlign: "center" }}>Conditional</td>
            <td style={{ width: "79.6167px", height: "18px", textAlign: "center" }}>Conditional</td>
          </tr>
        </tbody>
      </table>
      <h4 style={{ marginBottom: "2rem", marginTop: "6rem" }}>Ordering Example</h4>
      <p style={{ marginBottom: "2rem" }}>
        60 R SC: 6.0 mm OD, R Type compensating Conductor, Simplex, and Copper
      </p>
      <p style={{ marginBottom: "2rem" }}>
        Sheath 60 SS SC: 6.0 mm OD, S Type compensating Conductor, Duplex, and Copper Sheath
      </p>
    </>
  );
};

const MineralInsulatedRTDCables = () => {
  return (
    <>
      <table className="the-table" style={{ width: "611px", height: "530px" }}>
        <tbody>
          <tr style={{ height: "101px" }}>
            <th style={{ width: "69px", height: "60px" }}>
              Sheath
              <br />
              Diameter
              <br />
              (mm)
            </th>
            <th style={{ width: "61px", height: "60px" }}>
              Number of
              <br />
              Core
            </th>
            <th style={{ width: "76px", height: "60px" }}>
              Nominal
              <br />
              Wall
              <br />
              Thickness
              <br />
              (mm)
            </th>
            <th style={{ width: "69px", height: "60px" }}>
              Element
              <br />
              Diameter
              <br />
              (mm)
            </th>
            <th style={{ width: "82px", height: "60px" }}>
              Insulation
              <br />
              Resistance
              <br />
              (MΏ)
            </th>
            <th style={{ width: "61px", height: "60px" }}>
              Sheath
              <br />
              Material
            </th>
            <th style={{ width: "82px", height: "60px" }}>
              Conductor
              <br />
              Type
            </th>
            <th style={{ width: "79px", height: "60px" }}>Insulation</th>
          </tr>
          <tr style={{ height: "31px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>2.00</td>
            <td style={{ width: "61px", textAlign: "center", height: "135px" }} rowSpan={9}>
              <p>2 Core</p>
              <p>&amp;</p>
              <p>3 Core</p>
            </td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.20</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.30</td>
            <td style={{ width: "82px", height: "135px", textAlign: "center" }} rowSpan={9}>
              ≥ 100 MΏ
            </td>
            <td style={{ width: "61px", height: "135px", textAlign: "center" }} rowSpan={9}>
              <p>SS316L</p>
              <p>SS321</p>
              <p>SS310</p>
              <p>SS446</p>
              <p>SS304</p>
              <p>Inconel 600</p>
              <p>Inconel 601</p>
              <p>and others</p>
            </td>
            <td style={{ width: "82px", height: "135px", textAlign: "center" }} rowSpan={9}>
              Copper
              <br />
              Nickel
              <br />
              Nickel-Copper
              <br />
              Alloy
              <br />
              and others
            </td>
            <td style={{ width: "79px", height: "135px", textAlign: "center" }} rowSpan={9}>
              <p>
                MgO
                <br />
                (Standard
                <br />
                Purity ≥<br />
                97%)
              </p>
              <p>
                <br />
                MgO
                <br />
                (High Purity
                <br />
                ≥99.4%)
              </p>
            </td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "69px", height: "14px", textAlign: "center" }}>2.50</td>
            <td style={{ width: "76px", height: "14px", textAlign: "center" }}>≥ 0.25</td>
            <td style={{ width: "69px", height: "14px", textAlign: "center" }}>≥ 0.38</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>3.00</td>
            <td style={{ width: "76px", height: "18px", textAlign: "center" }}>≥ 0.30</td>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>≥ 0.45</td>
          </tr>
          <tr style={{ height: "19px" }}>
            <td style={{ width: "69px", height: "19px", textAlign: "center" }}>4.50</td>
            <td style={{ width: "76px", height: "19px", textAlign: "center" }}>≥ 0.45</td>
            <td style={{ width: "69px", height: "19px", textAlign: "center" }}>≥ 0.68</td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>6.00</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.60</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.90</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>6.40</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.64</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.96</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>8.00</td>
            <td style={{ width: "76px", height: "18px", textAlign: "center" }}>≥ 0.80</td>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>≥ 1.20</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>9.50</td>
            <td style={{ width: "76px", height: "18px", textAlign: "center" }}>≥ 0.95</td>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>≥ 1.43</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>10.00</td>
            <td style={{ width: "76px", height: "18px", textAlign: "center" }}>≥ 1.00</td>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>≥ 1.50</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "61px", textAlign: "center", height: "18px" }}>&nbsp;</td>
            <td style={{ width: "76px", height: "18px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "82px", height: "18px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "61px", height: "18px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "82px", height: "18px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "79px", height: "18px", textAlign: "center" }}>&nbsp;</td>
          </tr>
          <tr style={{ height: "31px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>2.00</td>
            <td style={{ width: "61px", textAlign: "center", height: "161px" }} rowSpan={9}>
              4 core
            </td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.20</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥0.24</td>
            <td style={{ width: "82px", height: "161px", textAlign: "center" }} rowSpan={9}>
              ≥ 100 MΏ
            </td>
            <td style={{ width: "61px", height: "161px", textAlign: "center" }} rowSpan={9}>
              <p>SS316L</p>
              <p>SS321</p>
              <p>SS310</p>
              <p>SS446</p>
              <p>SS304</p>
              <p>Inconel 600</p>
              <p>Inconel 601</p>
              <p>and others</p>
            </td>
            <td style={{ width: "82px", height: "161px", textAlign: "center" }} rowSpan={9}>
              Copper
              <br />
              Nickel
              <br />
              Nickel-Copper
              <br />
              Alloy
              <br />
              and others
            </td>
            <td style={{ width: "79px", height: "161px", textAlign: "center" }} rowSpan={9}>
              <p>
                MgO
                <br />
                (Standard
                <br />
                Purity ≥<br />
                97%)
              </p>
              <p>
                <br />
                MgO
                <br />
                (High Purity
                <br />
                ≥99.4%)
              </p>
            </td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "69px", height: "15px", textAlign: "center" }}>2.50</td>
            <td style={{ width: "76px", height: "15px", textAlign: "center" }}>≥ 0.25</td>
            <td style={{ width: "69px", height: "15px", textAlign: "center" }}>≥ 0.30</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "25px", textAlign: "center" }}>3.00</td>
            <td style={{ width: "76px", height: "25px", textAlign: "center" }}>≥ 0.30</td>
            <td style={{ width: "69px", height: "25px", textAlign: "center" }}>≥0.36</td>
          </tr>
          <tr style={{ height: "19px" }}>
            <td style={{ width: "69px", height: "19px", textAlign: "center" }}>4.50</td>
            <td style={{ width: "76px", height: "19px", textAlign: "center" }}>≥ 0.45</td>
            <td style={{ width: "69px", height: "19px", textAlign: "center" }}>≥ 0.54</td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "69px", height: "28px", textAlign: "center" }}>6.00</td>
            <td style={{ width: "76px", height: "28px", textAlign: "center" }}>≥ 0.60</td>
            <td style={{ width: "69px", height: "28px", textAlign: "center" }}>≥ 0.72</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>6.40</td>
            <td style={{ width: "76px", height: "18px", textAlign: "center" }}>≥ 0.64</td>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>≥ 0.77</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>8.00</td>
            <td style={{ width: "76px", height: "18px", textAlign: "center" }}>≥ 0.80</td>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>≥0.96</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>9.50</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.95</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 1.14</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>10.00</td>
            <td style={{ width: "76px", height: "18px", textAlign: "center" }}>≥ 1.00</td>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>≥ 1.20</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "61px", textAlign: "center", height: "10px" }}>&nbsp;</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "82px", height: "10px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "61px", height: "10px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "82px", height: "10px", textAlign: "center" }}>&nbsp;</td>
            <td style={{ width: "79px", height: "10px", textAlign: "center" }}>&nbsp;</td>
          </tr>
          <tr style={{ height: "21px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>3.00</td>
            <td style={{ width: "61px", textAlign: "center", height: "78px" }} rowSpan={7}>
              6 core
            </td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.30</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.30</td>
            <td style={{ width: "82px", height: "78px", textAlign: "center" }} rowSpan={7}>
              ≥ 100 MΏ
            </td>
            <td style={{ width: "61px", height: "78px", textAlign: "center" }} rowSpan={7}>
              <p>SS316L</p>
              <p>SS321</p>
              <p>SS310</p>
              <p>SS446</p>
              <p>SS304</p>
              <p>Inconel 600</p>
              <p>Inconel 601</p>
              <p>and others</p>
            </td>
            <td style={{ width: "82px", height: "78px", textAlign: "center" }} rowSpan={7}>
              <p>
                Copper
                <br />
                Nickel
                <br />
                Nickel-Copper
                <br />
                Alloy
                <br />
                and others
              </p>
              <p>&nbsp;</p>
            </td>
            <td style={{ width: "79px", height: "78px", textAlign: "center" }} rowSpan={7}>
              <p>
                MgO
                <br />
                (Standard
                <br />
                Purity ≥<br />
                97%)
              </p>
              <p>
                <br />
                MgO
                <br />
                (High Purity
                <br />
                ≥99.4%)
              </p>
            </td>
          </tr>
          <tr style={{ height: "19px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>4.50</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.45</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.45</td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>6.00</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.60</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.60</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>6.40</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.64</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.64</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>8.00</td>
            <td style={{ width: "76px", height: "18px", textAlign: "center" }}>≥ 0.80</td>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>≥ 0.80</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>9.50</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.95</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.95</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>10.00</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 1.00</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 1.00</td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td style={{ width: "69px", textAlign: "center", height: "10px" }}>&nbsp;</td>
            <td style={{ width: "61px", textAlign: "center", height: "10px" }}>&nbsp;</td>
            <td style={{ width: "76px", textAlign: "center", height: "10px" }}>&nbsp;</td>
            <td style={{ width: "69px", textAlign: "center", height: "10px" }}>&nbsp;</td>
            <td style={{ width: "82px", textAlign: "center", height: "10px" }}>&nbsp;</td>
            <td style={{ width: "61px", textAlign: "center", height: "10px" }}>
              <p>&nbsp;</p>
            </td>
            <td style={{ width: "82px", textAlign: "center", height: "10px" }}>
              <p>&nbsp;</p>
            </td>
            <td style={{ width: "79px", textAlign: "center", height: "10px" }}>
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>6.00</td>
            <td style={{ width: "61px", textAlign: "center", height: "58px" }} rowSpan={5}>
              8 core
            </td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.60</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.60</td>
            <td style={{ width: "82px", height: "58px", textAlign: "center" }} rowSpan={5}>
              ≥ 100 MΏ
            </td>
            <td style={{ width: "61px", height: "58px", textAlign: "center" }} rowSpan={5}>
              <p>SS316L</p>
              <p>SS321</p>
              <p>SS310</p>
              <p>SS446</p>
              <p>SS304</p>
              <p>Inconel 600</p>
              <p>Inconel 601</p>
              <p>and others</p>
            </td>
            <td style={{ width: "82px", height: "58px", textAlign: "center" }} rowSpan={5}>
              <p>
                Copper
                <br />
                Nickel
                <br />
                Nickel-Copper
                <br />
                Alloy
                <br />
                and others
              </p>
              <p>&nbsp;</p>
            </td>
            <td style={{ width: "79px", height: "58px", textAlign: "center" }} rowSpan={5}>
              <p>
                MgO
                <br />
                (Standard
                <br />
                Purity ≥<br />
                97%)
              </p>
              <p>
                <br />
                MgO
                <br />
                (High Purity
                <br />
                ≥99.4%)
              </p>
            </td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>6.40</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.64</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.64</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>8.00</td>
            <td style={{ width: "76px", height: "18px", textAlign: "center" }}>≥ 0.80</td>
            <td style={{ width: "69px", height: "18px", textAlign: "center" }}>≥ 0.80</td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>9.50</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 0.95</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 0.95</td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>10.00</td>
            <td style={{ width: "76px", height: "10px", textAlign: "center" }}>≥ 1.00</td>
            <td style={{ width: "69px", height: "10px", textAlign: "center" }}>≥ 1.00</td>
          </tr>
        </tbody>
      </table>
      <h4 style={{ marginBottom: "2rem", marginTop: "6rem" }}>Ordering Example</h4>
      <p style={{ marginBottom: "2rem" }}>
        80-4Cu316 RTD MI Cable: 8.0 mm OD, 4 Cores, Copper Conductor, SS316L Sheath
      </p>
      <p style={{ marginBottom: "2rem" }}>
        60-6NiCu600 RTD MI Cable: 8.0 mm OD, 4 Cores, Nickel-Copper Alloy Conductor (Constantan),
        Inconel 600 Sheath.
      </p>
    </>
  );
};

const ThermocoupleConductor = () => {
  return (
    <>
      <table
        className="the-table"
        style={{ height: "271px", width: "689px", float: "left", marginBottom: "6rem" }}
      >
        <tbody>
          <tr style={{ height: "65px" }}>
            <th style={{ width: "66px", height: "75px" }} rowSpan={2}>
              Type
              <br />
              <br />
            </th>
            <th style={{ width: "194px", height: "65px" }} colSpan={5}>
              Conductor (*)
              <br />
              Chemical Composition %
            </th>
            <th style={{ width: "117px", height: "65px" }} colSpan={3}>
              Conductor (-)
              <br />
              Chemical Composition %
            </th>
            <th style={{ width: "189px", height: "65px" }} colSpan={3}>
              American Standard
              <br />
              ASTM E230/ANSI MC95.2
            </th>
            <th style={{ width: "194px", height: "65px" }} colSpan={3}>
              European Standard IEC 584
            </th>
          </tr>
          <tr style={{ height: "101px" }}>
            <th style={{ width: "32px", height: "10px" }}>
              Ni
              <br />
              <br />
            </th>
            <th style={{ width: "32px", height: "10px" }}>
              Cr
              <br />
              <br />
            </th>
            <th style={{ width: "27px", height: "10px" }}>
              Fe
              <br />
              <br />
            </th>
            <th style={{ width: "27px", height: "10px" }}>Cu</th>
            <th style={{ width: "52px", height: "10px" }}>
              Others
              <br />
              <br />
            </th>
            <th style={{ width: "21px", height: "10px" }}>
              Cu
              <br />
              <br />
            </th>
            <th style={{ width: "32px", height: "10px" }}>
              Ni
              <br />
              <br />
            </th>
            <th style={{ width: "52px", height: "10px" }}>Others</th>
            <th style={{ width: "49px", height: "10px" }}>T/c Range</th>
            <th style={{ width: "59px", height: "10px" }}>
              Class 1<br />
              (0.4%)
            </th>
            <th style={{ width: "69px", height: "10px" }}>
              Class 2<br />
              (0.75%)
            </th>
            <th style={{ width: "54px", height: "10px" }}>T/c Range</th>
            <th style={{ width: "59px", height: "10px" }}>
              Class 1<br />
              (0.4%)
            </th>
            <th style={{ width: "69px", height: "10px" }}>
              Class 2<br />
              (0.75%)
            </th>
          </tr>
          <tr style={{ height: "21px" }}>
            <td style={{ width: "66px", height: "10px", textAlign: "center" }}>KX</td>
            <td style={{ width: "32px", height: "10px", textAlign: "center" }}>90</td>
            <td style={{ width: "32px", height: "10px", textAlign: "center" }}>10</td>
            <td style={{ width: "27px", height: "10px", textAlign: "center" }}>-</td>
            <td style={{ width: "27px", textAlign: "center", height: "10px" }}>-</td>
            <td style={{ height: "10px", textAlign: "center", width: "52px" }}>Si+</td>
            <td style={{ height: "10px", textAlign: "center", width: "21px" }}>-</td>
            <td style={{ height: "10px", textAlign: "center", width: "32px" }}>94</td>
            <td style={{ height: "10px", textAlign: "center", width: "52px" }}>
              Si+ Mn+ <br />
              Others
            </td>
            <td style={{ textAlign: "center", width: "49px", height: "10px" }}>0°C to 200°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "10px" }}>±1.1°C</td>
            <td style={{ textAlign: "center", width: "69px", height: "10px" }}>±2.2°C</td>
            <td style={{ textAlign: "center", width: "54px", height: "10px" }}>-25°C to 200°C</td>
            <td style={{ height: "10px", textAlign: "center", width: "59px" }}>±1.5°C</td>
            <td style={{ textAlign: "center", height: "10px", width: "69px" }}>±2.5°C</td>
          </tr>
          <tr style={{ height: "36px" }}>
            <td style={{ width: "66px", textAlign: "center", height: "36px" }}>
              KCA
              <br />
              (WX)
            </td>
            <td style={{ width: "32px", textAlign: "center", height: "36px" }}>-</td>
            <td style={{ width: "32px", textAlign: "center", height: "36px" }}>-</td>
            <td style={{ width: "27px", textAlign: "center", height: "36px" }}>100</td>
            <td style={{ width: "27px", textAlign: "center", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "52px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "21px", height: "36px" }}>Bal</td>
            <td style={{ textAlign: "center", width: "32px", height: "36px" }}>43</td>
            <td style={{ textAlign: "center", width: "52px", height: "36px" }}>Mn 2 - Fe 2</td>
            <td style={{ textAlign: "center", width: "49px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "59px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "69px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "54px", height: "36px" }}>0°C to 150°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "69px", height: "36px" }}>±2.5°C</td>
          </tr>
          <tr style={{ height: "36px" }}>
            <td style={{ width: "66px", textAlign: "center", height: "36px" }}>KCB (VX)</td>
            <td style={{ width: "32px", textAlign: "center", height: "36px" }}>-</td>
            <td style={{ width: "32px", textAlign: "center", height: "36px" }}>-</td>
            <td style={{ width: "27px", textAlign: "center", height: "36px" }}>-</td>
            <td style={{ width: "27px", textAlign: "center", height: "36px" }}>100</td>
            <td style={{ textAlign: "center", width: "52px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "21px", height: "36px" }}>Bal</td>
            <td style={{ textAlign: "center", width: "32px", height: "36px" }}>44</td>
            <td style={{ textAlign: "center", width: "52px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "49px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "59px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "69px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "54px", height: "36px" }}>0°C to 100°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "36px" }}>-</td>
            <td style={{ textAlign: "center", width: "69px", height: "36px" }}>±2.5°C</td>
          </tr>
          <tr style={{ height: "31px" }}>
            <td style={{ width: "66px", height: "10px", textAlign: "center" }}>NX</td>
            <td style={{ width: "32px", height: "10px", textAlign: "center" }}>84.4</td>
            <td style={{ width: "32px", height: "10px", textAlign: "center" }}>14.2</td>
            <td style={{ width: "27px", height: "10px", textAlign: "center" }}>-</td>
            <td style={{ width: "27px", textAlign: "center", height: "10px" }}>-</td>
            <td style={{ textAlign: "center", width: "52px", height: "10px" }}>Si 1.4</td>
            <td style={{ textAlign: "center", width: "21px", height: "10px" }}>-</td>
            <td style={{ textAlign: "center", width: "32px", height: "10px" }}>95.6</td>
            <td style={{ textAlign: "center", width: "52px", height: "10px" }}>Si4.4</td>
            <td style={{ textAlign: "center", width: "49px", height: "10px" }}>0°C to 200°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "10px" }}>±1.1°C</td>
            <td style={{ textAlign: "center", width: "69px", height: "10px" }}>±2.2°C</td>
            <td style={{ textAlign: "center", width: "54px", height: "10px" }}>-25°C to 200°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "10px" }}>±1.5°C</td>
            <td style={{ textAlign: "center", width: "69px", height: "10px" }}>±2.5°C</td>
          </tr>
          <tr style={{ height: "28px" }}>
            <td style={{ width: "66px", height: "14px", textAlign: "center" }}>J</td>
            <td style={{ width: "32px", height: "14px", textAlign: "center" }}>-</td>
            <td style={{ width: "32px", height: "14px", textAlign: "center" }}>-</td>
            <td style={{ width: "27px", height: "14px", textAlign: "center" }}>100</td>
            <td style={{ width: "27px", textAlign: "center", height: "14px" }}>-</td>
            <td style={{ textAlign: "center", width: "52px", height: "14px" }}>-</td>
            <td style={{ textAlign: "center", width: "21px", height: "14px" }}>Bal</td>
            <td style={{ textAlign: "center", width: "32px", height: "14px" }}>44</td>
            <td style={{ textAlign: "center", width: "52px", height: "14px" }}>Mn+</td>
            <td style={{ textAlign: "center", width: "49px", height: "14px" }}>0°C to 200°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "14px" }}>±1.1°C</td>
            <td style={{ textAlign: "center", width: "69px", height: "14px" }}>±2.2°C</td>
            <td style={{ textAlign: "center", width: "54px", height: "14px" }}>-25°C to 200°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "14px" }}>±1.5°C</td>
            <td style={{ textAlign: "center", width: "69px", height: "14px" }}>±2.5°C</td>
          </tr>
          <tr style={{ height: "18px" }}>
            <td style={{ width: "66px", height: "18px", textAlign: "center" }}>T</td>
            <td style={{ width: "32px", height: "18px", textAlign: "center" }}>-</td>
            <td style={{ width: "32px", height: "18px", textAlign: "center" }}>-</td>
            <td style={{ width: "27px", height: "18px", textAlign: "center" }}>-</td>
            <td style={{ width: "27px", textAlign: "center", height: "18px" }}>100</td>
            <td style={{ textAlign: "center", width: "52px", height: "18px" }}>-</td>
            <td style={{ textAlign: "center", width: "21px", height: "18px" }}>Bal</td>
            <td style={{ textAlign: "center", width: "32px", height: "18px" }}>44</td>
            <td style={{ textAlign: "center", width: "52px", height: "18px" }}>Mn+</td>
            <td style={{ textAlign: "center", width: "49px", height: "18px" }}>0°C to 100°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "18px" }}>±0.5°C</td>
            <td style={{ textAlign: "center", width: "69px", height: "18px" }}>±1°C</td>
            <td style={{ textAlign: "center", width: "54px", height: "18px" }}>-25°C to 200°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "18px" }}>±0.5°C</td>
            <td style={{ textAlign: "center", width: "69px", height: "18px" }}>±1°C</td>
          </tr>
          <tr style={{ height: "19px" }}>
            <td style={{ width: "66px", height: "18px", textAlign: "center" }}>E</td>
            <td style={{ width: "32px", height: "18px", textAlign: "center" }}>90</td>
            <td style={{ width: "32px", height: "18px", textAlign: "center" }}>10</td>
            <td style={{ width: "27px", height: "18px", textAlign: "center" }}>-</td>
            <td style={{ width: "27px", textAlign: "center", height: "18px" }}>-</td>
            <td style={{ textAlign: "center", width: "52px", height: "18px" }}>-</td>
            <td style={{ textAlign: "center", width: "21px", height: "18px" }}>Bal</td>
            <td style={{ textAlign: "center", width: "32px", height: "18px" }}>44</td>
            <td style={{ textAlign: "center", width: "52px", height: "18px" }}>Mn+</td>
            <td style={{ textAlign: "center", width: "49px", height: "18px" }}>
              0°C to 200°C
              <br />
              <br />
            </td>
            <td style={{ textAlign: "center", width: "59px", height: "18px" }}>±1°C</td>
            <td style={{ textAlign: "center", width: "69px", height: "18px" }}>±1.7°C</td>
            <td style={{ textAlign: "center", width: "54px", height: "18px" }}>-25°C to 200°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "18px" }}>±1.5°C</td>
            <td style={{ textAlign: "center", width: "69px", height: "18px" }}>±2.5°C</td>
          </tr>
          <tr style={{ height: "54px" }}>
            <td style={{ width: "66px", textAlign: "center", height: "54px" }}>RCB/SCB</td>
            <td style={{ width: "32px", textAlign: "center", height: "54px" }}>-</td>
            <td style={{ width: "32px", textAlign: "center", height: "54px" }}>100</td>
            <td style={{ width: "27px", textAlign: "center", height: "54px" }}>-</td>
            <td style={{ width: "27px", textAlign: "center", height: "54px" }}>-</td>
            <td style={{ textAlign: "center", width: "52px", height: "54px" }}>-</td>
            <td style={{ textAlign: "center", width: "21px", height: "54px" }}>2</td>
            <td style={{ textAlign: "center", width: "32px", height: "54px" }}>95</td>
            <td style={{ textAlign: "center", width: "52px", height: "54px" }}>Mn+</td>
            <td style={{ textAlign: "center", width: "49px", height: "54px" }}>0°C to 100°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "54px" }}>-</td>
            <td style={{ textAlign: "center", width: "69px", height: "54px" }}>-</td>
            <td style={{ textAlign: "center", width: "54px", height: "54px" }}>-25°C to 200°C</td>
            <td style={{ textAlign: "center", width: "59px", height: "54px" }}>-</td>
            <td style={{ textAlign: "center", width: "69px", height: "54px" }}>±2.5°C</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const ExtensionCompensatingConductor = () => {
  return (
    <table
      className="the-table"
      style={{ height: "271px", width: "689px", float: "left", marginBottom: "6rem" }}
    >
      <tbody>
        <tr style={{ height: "65px" }}>
          <th style={{ width: "66px", height: "75px" }} rowSpan={2}>
            Type
            <br />
            <br />
          </th>
          <th style={{ width: "194px", height: "65px" }} colSpan={5}>
            Conductor (*)
            <br />
            Chemical Composition %
          </th>
          <th style={{ width: "117px", height: "65px" }} colSpan={3}>
            Conductor (-)
            <br />
            Chemical Composition %
          </th>
          <th style={{ width: "189px", height: "65px" }} colSpan={3}>
            American Standard
            <br />
            ASTM E230/ANSI MC95.2
          </th>
          <th style={{ width: "194px", height: "65px" }} colSpan={3}>
            European Standard IEC 584
          </th>
        </tr>
        <tr style={{ height: "101px" }}>
          <th style={{ width: "32px", height: "10px" }}>
            Ni
            <br />
            <br />
          </th>
          <th style={{ width: "32px", height: "10px" }}>
            Cr
            <br />
            <br />
          </th>
          <th style={{ width: "27px", height: "10px" }}>
            Fe
            <br />
            <br />
          </th>
          <th style={{ width: "27px", height: "10px" }}>Cu</th>
          <th style={{ width: "52px", height: "10px" }}>
            Others
            <br />
            <br />
          </th>
          <th style={{ width: "21px", height: "10px" }}>
            Cu
            <br />
            <br />
          </th>
          <th style={{ width: "32px", height: "10px" }}>
            Ni
            <br />
            <br />
          </th>
          <th style={{ width: "52px", height: "10px" }}>Others</th>
          <th style={{ width: "49px", height: "10px" }}>T/c Range</th>
          <th style={{ width: "59px", height: "10px" }}>
            Class 1<br />
            (0.4%)
          </th>
          <th style={{ width: "69px", height: "10px" }}>
            Class 2<br />
            (0.75%)
          </th>
          <th style={{ width: "54px", height: "10px" }}>T/c Range</th>
          <th style={{ width: "59px", height: "10px" }}>
            Class 1<br />
            (0.4%)
          </th>
          <th style={{ width: "69px", height: "10px" }}>
            Class 2<br />
            (0.75%)
          </th>
        </tr>
        <tr style={{ height: "21px" }}>
          <td style={{ width: "66px", height: "10px", textAlign: "center" }}>KX</td>
          <td style={{ width: "32px", height: "10px", textAlign: "center" }}>90</td>
          <td style={{ width: "32px", height: "10px", textAlign: "center" }}>10</td>
          <td style={{ width: "27px", height: "10px", textAlign: "center" }}>-</td>
          <td style={{ width: "27px", textAlign: "center", height: "10px" }}>-</td>
          <td style={{ height: "10px", textAlign: "center", width: "52px" }}>Si+</td>
          <td style={{ height: "10px", textAlign: "center", width: "21px" }}>-</td>
          <td style={{ height: "10px", textAlign: "center", width: "32px" }}>94</td>
          <td style={{ height: "10px", textAlign: "center", width: "52px" }}>
            Si+ Mn+ <br />
            Others
          </td>
          <td style={{ textAlign: "center", width: "49px", height: "10px" }}>0°C to 200°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "10px" }}>±1.1°C</td>
          <td style={{ textAlign: "center", width: "69px", height: "10px" }}>±2.2°C</td>
          <td style={{ textAlign: "center", width: "54px", height: "10px" }}>-25°C to 200°C</td>
          <td style={{ height: "10px", textAlign: "center", width: "59px" }}>±1.5°C</td>
          <td style={{ textAlign: "center", height: "10px", width: "69px" }}>±2.5°C</td>
        </tr>
        <tr style={{ height: "36px" }}>
          <td style={{ width: "66px", textAlign: "center", height: "36px" }}>
            KCA
            <br />
            (WX)
          </td>
          <td style={{ width: "32px", textAlign: "center", height: "36px" }}>-</td>
          <td style={{ width: "32px", textAlign: "center", height: "36px" }}>-</td>
          <td style={{ width: "27px", textAlign: "center", height: "36px" }}>100</td>
          <td style={{ width: "27px", textAlign: "center", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "52px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "21px", height: "36px" }}>Bal</td>
          <td style={{ textAlign: "center", width: "32px", height: "36px" }}>43</td>
          <td style={{ textAlign: "center", width: "52px", height: "36px" }}>Mn 2 - Fe 2</td>
          <td style={{ textAlign: "center", width: "49px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "59px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "69px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "54px", height: "36px" }}>0°C to 150°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "69px", height: "36px" }}>±2.5°C</td>
        </tr>
        <tr style={{ height: "36px" }}>
          <td style={{ width: "66px", textAlign: "center", height: "36px" }}>KCB (VX)</td>
          <td style={{ width: "32px", textAlign: "center", height: "36px" }}>-</td>
          <td style={{ width: "32px", textAlign: "center", height: "36px" }}>-</td>
          <td style={{ width: "27px", textAlign: "center", height: "36px" }}>-</td>
          <td style={{ width: "27px", textAlign: "center", height: "36px" }}>100</td>
          <td style={{ textAlign: "center", width: "52px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "21px", height: "36px" }}>Bal</td>
          <td style={{ textAlign: "center", width: "32px", height: "36px" }}>44</td>
          <td style={{ textAlign: "center", width: "52px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "49px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "59px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "69px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "54px", height: "36px" }}>0°C to 100°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "36px" }}>-</td>
          <td style={{ textAlign: "center", width: "69px", height: "36px" }}>±2.5°C</td>
        </tr>
        <tr style={{ height: "31px" }}>
          <td style={{ width: "66px", height: "10px", textAlign: "center" }}>NX</td>
          <td style={{ width: "32px", height: "10px", textAlign: "center" }}>84.4</td>
          <td style={{ width: "32px", height: "10px", textAlign: "center" }}>14.2</td>
          <td style={{ width: "27px", height: "10px", textAlign: "center" }}>-</td>
          <td style={{ width: "27px", textAlign: "center", height: "10px" }}>-</td>
          <td style={{ textAlign: "center", width: "52px", height: "10px" }}>Si 1.4</td>
          <td style={{ textAlign: "center", width: "21px", height: "10px" }}>-</td>
          <td style={{ textAlign: "center", width: "32px", height: "10px" }}>95.6</td>
          <td style={{ textAlign: "center", width: "52px", height: "10px" }}>Si4.4</td>
          <td style={{ textAlign: "center", width: "49px", height: "10px" }}>0°C to 200°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "10px" }}>±1.1°C</td>
          <td style={{ textAlign: "center", width: "69px", height: "10px" }}>±2.2°C</td>
          <td style={{ textAlign: "center", width: "54px", height: "10px" }}>-25°C to 200°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "10px" }}>±1.5°C</td>
          <td style={{ textAlign: "center", width: "69px", height: "10px" }}>±2.5°C</td>
        </tr>
        <tr style={{ height: "28px" }}>
          <td style={{ width: "66px", height: "14px", textAlign: "center" }}>J</td>
          <td style={{ width: "32px", height: "14px", textAlign: "center" }}>-</td>
          <td style={{ width: "32px", height: "14px", textAlign: "center" }}>-</td>
          <td style={{ width: "27px", height: "14px", textAlign: "center" }}>100</td>
          <td style={{ width: "27px", textAlign: "center", height: "14px" }}>-</td>
          <td style={{ textAlign: "center", width: "52px", height: "14px" }}>-</td>
          <td style={{ textAlign: "center", width: "21px", height: "14px" }}>Bal</td>
          <td style={{ textAlign: "center", width: "32px", height: "14px" }}>44</td>
          <td style={{ textAlign: "center", width: "52px", height: "14px" }}>Mn+</td>
          <td style={{ textAlign: "center", width: "49px", height: "14px" }}>0°C to 200°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "14px" }}>±1.1°C</td>
          <td style={{ textAlign: "center", width: "69px", height: "14px" }}>±2.2°C</td>
          <td style={{ textAlign: "center", width: "54px", height: "14px" }}>-25°C to 200°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "14px" }}>±1.5°C</td>
          <td style={{ textAlign: "center", width: "69px", height: "14px" }}>±2.5°C</td>
        </tr>
        <tr style={{ height: "18px" }}>
          <td style={{ width: "66px", height: "18px", textAlign: "center" }}>T</td>
          <td style={{ width: "32px", height: "18px", textAlign: "center" }}>-</td>
          <td style={{ width: "32px", height: "18px", textAlign: "center" }}>-</td>
          <td style={{ width: "27px", height: "18px", textAlign: "center" }}>-</td>
          <td style={{ width: "27px", textAlign: "center", height: "18px" }}>100</td>
          <td style={{ textAlign: "center", width: "52px", height: "18px" }}>-</td>
          <td style={{ textAlign: "center", width: "21px", height: "18px" }}>Bal</td>
          <td style={{ textAlign: "center", width: "32px", height: "18px" }}>44</td>
          <td style={{ textAlign: "center", width: "52px", height: "18px" }}>Mn+</td>
          <td style={{ textAlign: "center", width: "49px", height: "18px" }}>0°C to 100°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "18px" }}>±0.5°C</td>
          <td style={{ textAlign: "center", width: "69px", height: "18px" }}>±1°C</td>
          <td style={{ textAlign: "center", width: "54px", height: "18px" }}>-25°C to 200°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "18px" }}>±0.5°C</td>
          <td style={{ textAlign: "center", width: "69px", height: "18px" }}>±1°C</td>
        </tr>
        <tr style={{ height: "19px" }}>
          <td style={{ width: "66px", height: "18px", textAlign: "center" }}>E</td>
          <td style={{ width: "32px", height: "18px", textAlign: "center" }}>90</td>
          <td style={{ width: "32px", height: "18px", textAlign: "center" }}>10</td>
          <td style={{ width: "27px", height: "18px", textAlign: "center" }}>-</td>
          <td style={{ width: "27px", textAlign: "center", height: "18px" }}>-</td>
          <td style={{ textAlign: "center", width: "52px", height: "18px" }}>-</td>
          <td style={{ textAlign: "center", width: "21px", height: "18px" }}>Bal</td>
          <td style={{ textAlign: "center", width: "32px", height: "18px" }}>44</td>
          <td style={{ textAlign: "center", width: "52px", height: "18px" }}>Mn+</td>
          <td style={{ textAlign: "center", width: "49px", height: "18px" }}>
            0°C to 200°C
            <br />
            <br />
          </td>
          <td style={{ textAlign: "center", width: "59px", height: "18px" }}>±1°C</td>
          <td style={{ textAlign: "center", width: "69px", height: "18px" }}>±1.7°C</td>
          <td style={{ textAlign: "center", width: "54px", height: "18px" }}>-25°C to 200°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "18px" }}>±1.5°C</td>
          <td style={{ textAlign: "center", width: "69px", height: "18px" }}>±2.5°C</td>
        </tr>
        <tr style={{ height: "54px" }}>
          <td style={{ width: "66px", textAlign: "center", height: "54px" }}>RCB/SCB</td>
          <td style={{ width: "32px", textAlign: "center", height: "54px" }}>-</td>
          <td style={{ width: "32px", textAlign: "center", height: "54px" }}>100</td>
          <td style={{ width: "27px", textAlign: "center", height: "54px" }}>-</td>
          <td style={{ width: "27px", textAlign: "center", height: "54px" }}>-</td>
          <td style={{ textAlign: "center", width: "52px", height: "54px" }}>-</td>
          <td style={{ textAlign: "center", width: "21px", height: "54px" }}>2</td>
          <td style={{ textAlign: "center", width: "32px", height: "54px" }}>95</td>
          <td style={{ textAlign: "center", width: "52px", height: "54px" }}>Mn+</td>
          <td style={{ textAlign: "center", width: "49px", height: "54px" }}>0°C to 100°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "54px" }}>-</td>
          <td style={{ textAlign: "center", width: "69px", height: "54px" }}>-</td>
          <td style={{ textAlign: "center", width: "54px", height: "54px" }}>-25°C to 200°C</td>
          <td style={{ textAlign: "center", width: "59px", height: "54px" }}>-</td>
          <td style={{ textAlign: "center", width: "69px", height: "54px" }}>±2.5°C</td>
        </tr>
      </tbody>
    </table>
  );
};

const tableStyle = {
  border: "1px solid #ccc",
  borderCollapse: "collapse",
  width: "100%",
};

const labelCellStyle = {
  padding: "8px",
  border: "1px solid #ccc",
  backgroundColor: "#f2f2f2",
  fontSize: "14px",
  fontWeight: "bold",
};

const valueCellStyle = {
  padding: "8px",
  border: "1px solid #ccc",
  backgroundColor: "#e6e6e6",
  fontSize: "12px",
};
const headerCellStyle = {
  padding: "8px",
  border: "1px solid #ccc",
  backgroundColor: "#f2f2f2",
  fontSize: "14px",
  fontWeight: "bold",
};

export const PRODUCT_ROUTES_2 = [
  {
    path: `/products/${slugify("Simplex stator winding RTDs")}`,
    title: "Simplex (Single element) stator winding RTDs",
    banner: images18.banner,
    additionalBanners: [images19.banner],
    descriptionParagraphs: [
      "The Stator winding temperature detector (RTD) are used to measure winding temperature of large Motors, Generators etc. These sensor are sandwiched between the windings of Motors/Generators.",
      "Unlike on/off devices, It allows continuous measurement of winding temperature. The NEMA recognizes this kind of sensor as a standard protection for motor and generator Insulation.",
      "Normally, these RTDs are of wire wound and sensing element extends throughout the length of the detector. These feature will provide average temperature reading. Normally six sensors are recommended for each motor, Two per Phase. This RTD are generally flat type in construction and are available in various sizes.",
    ],
    features: [
      "Slim dimensions to get inserted in between windings",
      "Bifilar wound slot resistance thermometer design that prevents induction voltage resulting in measurement errors.",
      "Resistance to shock, vibration and pressure.",
      "Withstand of VPI(Vacuum Pressure Impregnation) Process",
      "High Dielectric Strength.",
      "Reliable and continuous monitoring.",
      "Average temperature readings.",
    ],
    specifications: [
      {
        label: "Model",
        value: "HTSD",
      },
      {
        label: "PT 100(100 ohm at 0°C)*",
        value: "PT 100(100 ohm at 0°C)*",
      },
      {
        label: "Element",
        value: "Single",
      },
      {
        label: "Body Material",
        value: "Fiber Glass Epoxy",
      },
      {
        label: "Temperature Class",
        value: "Class F(Temperature limit:155°C)\nClass H(Temperature limit:180°C)",
      },
      {
        label: "Calibration Standard",
        value: "IEC 60751\nTemperature coefficient(TCR):0.003850",
      },
      {
        label: "Accuracy",
        value: "Class A/B/2B",
      },
      {
        label: "Sensing current",
        value: "10mA maximum",
      },
      {
        label: "Dielectric strength",
        value:
          "3 KV 50/60 Hz for 1 minute between leads and surface of Glass epoxy body (5 KV design are available on request)",
      },
      {
        label: "Number of wire",
        value: "Two or Three or Four",
      },
      {
        label: "Lead Wire",
        value:
          "Single or multiple stranded with Tin/Silver/Nickel Plated Copper Wire with PTFE, PFA, FEP or Polyamide insulation.",
      },
    ],
    note: "Other values like PT-50,PT-200, PT-500, PT-1000 or any other value and temperature coefficient of 0.00375 and 0.003916 are available upon request. Custom design of sensors are also available upon request.",
    importantNote: "RTDs with Temperature upto 250°C are also available on request.",
    model: {
      image: images18.model,
      label: "Model: HTSD series ( Single element)",
    },
    modelTableHeading: "Model: HTSD – ACC – L – T – W – NOW – WG – LL – CC",
    modelTable: (
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td style={labelCellStyle}>ACC</td>
            <td style={valueCellStyle}>Accuracy of RTD element (A / B / 2B)</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>L</td>
            <td style={valueCellStyle}>Length in mm</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>T</td>
            <td style={valueCellStyle}>Thickness in mm</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>W</td>
            <td style={valueCellStyle}>Width in mm</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>NOW</td>
            <td style={valueCellStyle}>Nos of wire ( options 2 / 3 /4 )</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>WG</td>
            <td style={valueCellStyle}>
              Wire Gauge of the lead wire in AWG ( options 22 to 30 AWG)
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>LL</td>
            <td style={valueCellStyle}>Lead length in mmm</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>CC</td>
            <td style={valueCellStyle}>
              Cable Construction(Please refer Cable Construction Table)
            </td>
          </tr>
        </tbody>
      </table>
    ),
    exampleTable: (
      <table style={{ border: "1px solid #ccc", borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          <tr>
            <td style={headerCellStyle} colSpan="4">
              DIMENSION RANCE (IN MM)
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Parameter</td>
            <td style={labelCellStyle}>MIN</td>
            <td style={labelCellStyle}>MAX</td>
            <td style={labelCellStyle}>TOLERANCE</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Length(L)</td>
            <td style={valueCellStyle}>10</td>
            <td style={valueCellStyle}>2400</td>
            <td style={valueCellStyle}>+/- 4</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Width(W)</td>
            <td style={valueCellStyle}>5</td>
            <td style={valueCellStyle}>50</td>
            <td style={valueCellStyle}>+/- 0.4</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Thickness(T)</td>
            <td style={valueCellStyle}>0.5</td>
            <td style={valueCellStyle}>5</td>
            <td style={valueCellStyle}>+/- 0.1</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Lead Length(LL)</td>
            <td style={valueCellStyle}>25</td>
            <td style={valueCellStyle}>100000</td>
            <td style={valueCellStyle}>+.25% / -0.0</td>
          </tr>
        </tbody>
      </table>
    ),
    exampleHeading: "EXAMPLE (Part Number): HTSD-A-250-2-10-3-24-2500-PT-TJ-S",
    cableTablesHeading: "CABLE CONSTRUCTION TABLE",
    cableTables: [
      <table
        key={0}
        style={{ border: "1px solid #ccc", borderCollapse: "collapse", width: "100%" }}
      >
        <tbody>
          <tr>
            <td style={headerCellStyle} colSpan="2">
              First Choice:
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Type of Insulation</td>
            <td style={labelCellStyle}>Code</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>FEP</td>
            <td style={valueCellStyle}>FE</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>PFA</td>
            <td style={valueCellStyle}>PF</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>PTFE</td>
            <td style={valueCellStyle}>PT</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>POLYAMIDE</td>
            <td style={valueCellStyle}>PO</td>
          </tr>
        </tbody>
      </table>,
      <table
        key={1}
        style={{ border: "1px solid #ccc", borderCollapse: "collapse", width: "100%" }}
      >
        <tbody>
          <tr>
            <td style={headerCellStyle} colSpan="2">
              Second Choice:
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Type of Construction</td>
            <td style={labelCellStyle}>Code</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Flat</td>
            <td style={valueCellStyle}>F</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Twisted</td>
            <td style={valueCellStyle}>T</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Flat With Jacket</td>
            <td style={valueCellStyle}>FJ</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Twisted With Jacket</td>
            <td style={valueCellStyle}>TJ</td>
          </tr>
        </tbody>
      </table>,
      <table
        key={2}
        style={{ border: "1px solid #ccc", borderCollapse: "collapse", width: "100%" }}
      >
        <tbody>
          <tr>
            <td style={headerCellStyle} colSpan="2">
              Third Choice:
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Screening</td>
            <td style={labelCellStyle}>Code</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Screening Required</td>
            <td style={valueCellStyle}>S</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Screening Not Required</td>
            <td style={valueCellStyle}>N</td>
          </tr>
        </tbody>
      </table>,
    ],
  },
  {
    path: `/products/${slugify("Duplex stator winding RTDs")}`,
    title: "Duplex (Double element) stator winding RTDs",
    banner: images18.banner,
    additionalBanners: [images19.banner],
    descriptionParagraphs: [
      "The Stator winding temperature detector (RTD) consist of a pair of sensors within a single enclosure. A duplex RTD sensor would have two elements at its hot end instead of a single element. The main reason for such a configuration is to enable redundancy if one of the sensors fails. The Second element comes in use incase of damage of one element.",
    ],
    features: [
      "Slim dimensions to get inserted in between windings.",
      "Bifilar wound slot resistance thermometer design that prevents induction voltage resulting in measurement errors.",
      "induction voltage resulting in measurement errors.",
      "Resistance to shock, vibration and pressure.",
      "Withstand of VPI(Vacuum Pressure Impregnation) Process.",
      "High Dielectric Strength.",
      "Reliable and continuous monitoring.",
      "Average temperature readings.",
    ],
    specifications: [
      { label: "Model", value: "HTSE" },
      { label: "PT 100(100 ohm at 0°C)", value: "PT 100(100 ohm at 0°C)" },
      { label: "Element", value: "Double" },
      { label: "Body Material", value: "Fiber Glass Epoxy" },
      {
        label: "Temperature Class",
        value: ["Class F (Temperature limit: 155°C)", "Class H (Temperature limit: 180°C)"],
      },
      {
        label: "Calibration Standard",
        value: ["IEC 60751", "Temperature coefficient (TCR): 0.003850"],
      },
      { label: "Accuracy", value: "Class A/B/2B" },
      { label: "Sensing current", value: "10mA maximum" },
      {
        label: "Dielectric strength",
        value:
          "3 KV 50/60 Hz for 1 minute between leads and surface of Glass epoxy body (5 KV design are available on request)",
      },
      { label: "Number of wire", value: "Two or Three or Four" },
      {
        label: "Lead Wire",
        value:
          "Single or multiple stranded with Tin/Silver/Nickel Plated Copper Wire with PTFE, PFA, FEP, or Polyamide insulation.",
      },
    ],
    note: "Other values like PT-50,PT-200, PT-500, PT-1000 or any other value and temperature coefficient of 0.00375 and 0.003916 are available upon request. Custom design of sensors are also available upon request.",
    importantNote: "RTDs with Temperature upto 250°C are also available on request.",
    model: {
      image: images19.model,
      label: "Model: HTSE series ( Double element)",
    },
    modelTableHeading: "Model: HTSE – ACC – L – T – W – NOW – WG – LL – CC",
    modelTable: (
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td style={headerCellStyle}>ACC</td>
            <td style={valueCellStyle}>Accuracy of RTD element (A / B / 2B)</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>L</td>
            <td style={valueCellStyle}>Length in mm</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>T</td>
            <td style={valueCellStyle}>Thickness in mm</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>W</td>
            <td style={valueCellStyle}>Width in mm</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>NOW</td>
            <td style={valueCellStyle}>Nos of wire ( options 2 / 3 /4 )</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>WG</td>
            <td style={valueCellStyle}>
              Wire Gauge of the lead wire in AWG ( options 22 to 30 AWG)
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>LL</td>
            <td style={valueCellStyle}>Lead length in mmm</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>CC</td>
            <td style={valueCellStyle}>Cable Construction</td>
          </tr>
        </tbody>
      </table>
    ),
    exampleHeading: "EXAMPLE (Part Number): HTSE-B-300-2-8-2-24-2000-FE-T-N",
    exampleTable: (
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td style={headerCellStyle} colSpan="4">
              DIMENSION RANCE (IN MM)
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={headerCellStyle}>Parameter </td>
            <td style={headerCellStyle}>MIN</td>
            <td style={headerCellStyle}>MAX</td>
            <td style={headerCellStyle}>TOLERANCE</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Length(L)</td>
            <td style={valueCellStyle}>10</td>
            <td style={valueCellStyle}>2400</td>
            <td style={valueCellStyle}>+/- 4</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Width(W)</td>
            <td style={valueCellStyle}>5</td>
            <td style={valueCellStyle}>50</td>
            <td style={valueCellStyle}>+/- 0.4</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Thickness(T)</td>
            <td style={valueCellStyle}>0.5</td>
            <td style={valueCellStyle}>5</td>
            <td style={valueCellStyle}>+/- 0.1</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Lead Length(LL)</td>
            <td style={valueCellStyle}>25</td>
            <td style={valueCellStyle}>100000</td>
            <td style={valueCellStyle}>+.25% / -0.0</td>
          </tr>
        </tbody>
      </table>
    ),
    cableTablesHeading: "CABLE CONSTRUCTION",
    cableTables: [
      <table style={tableStyle} key={0}>
        <tbody>
          <tr>
            <td style={headerCellStyle} colSpan="2">
              First Choice:
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Type of Insulation</td>
            <td style={labelCellStyle}>Code</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>FEP</td>
            <td style={valueCellStyle}>FE</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>PFA</td>
            <td style={valueCellStyle}>PF</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>PTFE</td>
            <td style={valueCellStyle}>PT</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>POLYAMIDE</td>
            <td style={valueCellStyle}>PO</td>
          </tr>
        </tbody>
      </table>,
      <table
        key={1}
        style={{ border: "1px solid #ccc", borderCollapse: "collapse", width: "100%" }}
      >
        <tbody>
          <tr>
            <td style={headerCellStyle} colSpan="2">
              Second Choice:
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Type of Construction</td>
            <td style={labelCellStyle}>Code</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Flat</td>
            <td style={valueCellStyle}>F</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Twisted</td>
            <td style={valueCellStyle}>T</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Flat With Jacket</td>
            <td style={valueCellStyle}>FJ</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Twisted With Jacket</td>
            <td style={valueCellStyle}>TJ</td>
          </tr>
        </tbody>
      </table>,
      <table style={tableStyle} key={2}>
        <tbody>
          <tr>
            <td style={headerCellStyle} colSpan="2">
              Third Choice:
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={labelCellStyle}>Screening</td>
            <td style={labelCellStyle}>Code</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Screening Required</td>
            <td style={valueCellStyle}>S</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td style={valueCellStyle}>Screening Not Required</td>
            <td style={valueCellStyle}>N</td>
          </tr>
        </tbody>
      </table>,
    ],
  },
];

export const PRODUCT_ROUTES = [
  {
    banner: images1.banner,
    hitechBanner: BaseMetalThermocouple,
    tabs: [
      {
        label: "PTFE Insulated",
        content: [
          {
            image: images1.t501,
            label: "T-501 THERMOCOUPLE WITH THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T-501 THERMOCOUPLE WITH THERMOWELL")}`,
          },
          {
            image: images1.t502,
            label: "T - 502 THERMOCOUPLE WITH THERMOWELL & ADJUSTABLE FLANGE",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 502 THERMOCOUPLE WITH THERMOWELL & ADJUSTABLE FLANGE")}`,
          },
          {
            image: images1.t503,
            label: "T - 503 THERMOCOUPLE WITH TAPER THERMOWELL & ADJUSTABLE FLANGE",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 503 THERMOCOUPLE WITH TAPER THERMOWELL & ADJUSTABLE FLANGE")}`,
          },
          {
            image: images1.t504,
            label:
              "T - 504 THERMOCOUPLE WITH STRAIGHT THERMOWELL, ADJUSTABLE COMPENSATION FITTING & CABLE",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify(
              "T - 504 THERMOCOUPLE WITH STRAIGHT THERMOWELL, ADJUSTABLE COMPENSATION FITTING & CABLE"
            )}`,
          },
          {
            image: images1.t506,
            label: "T - 506 THERMOCOUPLE WITH THERMOWELL & COATING",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 506 THERMOCOUPLE WITH THERMOWELL & COATING")}`,
          },
          {
            image: images1.t651,
            label: "T - 651 THERMOCOUPLE WITH N/U/N & TAPER THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 651 THERMOCOUPLE WITH N/U/N & TAPER THERMOWELL")}`,
          },
          {
            image: images1.t652,
            label: "T - 652 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 652 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL")}`,
          },
          {
            image: images1.t653,
            label: "T - 653 THERMOCOUPLE WITH N/U/N & STRAIGHT THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 653 THERMOCOUPLE WITH N/U/N & STRAIGHT THERMOWELL")}`,
          },
          {
            image: images1.t650,
            label: "T - 650 THERMOCOUPLE WITH NIPPLE & TAPER THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 650 THERMOCOUPLE WITH NIPPLE & TAPER THERMOWELL")}`,
          },
          {
            image: images1.t654,
            label: "T - 654 THERMOCOUPLE WITH STRAIGHT THERMOWELL, ADJUSTABLE COMPRESSION FITTING",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify(
              "T - 654 THERMOCOUPLE WITH STRAIGHT THERMOWELL, ADJUSTABLE COMPRESSION FITTING"
            )}`,
          },
          {
            image: images1.t655,
            label: "T - 655 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 655 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL")}`,
          },
          {
            image: images1.t656,
            label: "T - 656 THERMOCOUPLE WITH TAPERED THERMOWELL & ADJUSTABLE COMPENSATION FITTING",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify(
              "T - 656 THERMOCOUPLE WITH TAPERED THERMOWELL & ADJUSTABLE COMPENSATION FITTING"
            )}`,
          },
          {
            image: images1.t657,
            label: "T - 657 THERMOCOUPLE WITH SOLID SINTERED TUNGSTEN CARBIDE THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 657 THERMOCOUPLE WITH SOLID SINTERED TUNGSTEN CARBIDE THERMOWELL")}`,
          },
          {
            image: images1.t726,
            label: "T - 726 THERMOCOUPLE WITH NIPPLE & FLANGE THERMOWELL TAPER",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 726 THERMOCOUPLE WITH NIPPLE & FLANGE THERMOWELL TAPER")}`,
          },
          {
            image: images1.t727,
            label: "T - 727 THERMOCOUPLE WITH N/U/N & FLANGE THERMOWELL TAPER",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 727 THERMOCOUPLE WITH N/U/N & FLANGE THERMOWELL TAPER")}`,
          },
          {
            image: images1.t728,
            label:
              "T - 728 THERMOCOUPLE WITH FLANGE THERMOWELL TAPERED & ADJUSTABLE COMPENSATION FITTING",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify(
              "T - 728 THERMOCOUPLE WITH FLANGE THERMOWELL TAPERED & ADJUSTABLE COMPENSATION FITTING"
            )}`,
          },
          {
            image: images1.t729,
            label: "T - 729 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 729 THERMOCOUPLE WITH NIPPLE & STRAIGHT THERMOWELL")}`,
          },
          {
            image: images1.t730,
            label: "T - 730 THERMOCOUPLE WITH N/U/N & STRAIGHT THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 730 THERMOCOUPLE WITH N/U/N & STRAIGHT THERMOWELL")}`,
          },
          {
            image: images1.t731,
            label: "T - 731 THERMOCOUPLE WITH STRAIGHT THERMOWELL & FITTING",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 731 THERMOCOUPLE WITH STRAIGHT THERMOWELL & FITTING")}`,
          },
          {
            image: images1.t732,
            label: "T - 732 THERMOCOUPLE WITH N/U/N & STEPPED THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 732 THERMOCOUPLE WITH N/U/N & STEPPED THERMOWELL")}`,
          },
          {
            image: images1.t733,
            label: "T - 733 THERMOCOUPLE WITH BUILT UP FABRICATED THERMOWELL",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 733 THERMOCOUPLE WITH BUILT UP FABRICATED THERMOWELL")}`,
          },
          {
            image: images1.t734,
            label: "T - 734 THERMOCOUPLE WITH THERMOWELL & FLANGE WITH PROTECTION TUBEE",
            route: `/products/${slugify(
              "Base Metal Thermocouples With Thermowells / Protection Tubes"
            )}/${slugify("T - 734 THERMOCOUPLE WITH THERMOWELL & FLANGE WITH PROTECTION TUBEE")}`,
          },
        ],
      },
    ],
    rowsHead: "vertical",
    rows: [
      ["Type", "J, K, T, E, N"],
      ["Element size", "1.2,1.6,2.0,2.5,3.2 mm Other sizes on request"],
      ["Sheath Diameter", "3.0, 4.5, 6.0, 8.0 mm Other sizes on request"],
      ["Sheath Material", "SS304, SS321, SS316, SS310, Inconel 600"],
      ["Thermowell Material", "HRS 446, Inconel 600/601/800, Nickel, Hastalloy, etc."],
      ["Configuration", "Simplex/ Duplex/ Multipoint"],
    ],
    rows2Head: "horizontal",
    rows2: [
      ["Type", "Names of Materials", "Application Range", "PDF's", "Application"],
      [
        "J",
        "Iron (+) Constantan (-)",
        "0 to 750ºC",
        "Pdf",
        "Inert Media, Oxidizing Media, Reducing Media Vaccum",
      ],
      ["K", "Chromel (+) Alumel (-)", "-200 to 1250ºC", "Pdf", "Inert Media, Oxidizing Media"],
      [
        "T",
        "Copper (+) Constantan (-)",
        "-200 to 350ºC",
        "Pdf",
        "Inert Media, Oxidizing Media, Reducing Media Vaccum",
      ],
      ["E", "Chromel (+) Constantan (-)", "-200 to 900ºC", "Pdf", "Inert Media, Oxidizing Media"],
      ["N", "Nicorsil (+) Nisil (-)", "-270 to 1300ºC", "Pdf", "Inert Media, Oxidizing Media"],
    ],

    path: `/products/${slugify("Base Metal Thermocouples With Thermowells / Protection Tubes")}`,
    title: "Base Metal Thermocouples With Thermowells / Protection Tubes",
    description:
      "Base Metal Thermocouple types are composed of common, inexpensive metals such as nickel, iron and copper. The thermocouple types E, J, K, N and T are of this group and are the most commonly used type of thermocouple.",
  },
  {
    banner: images2.banner,
    tabs: [
      {
        label: "MI Thermocouple with Head",
        content: [
          {
            image: images2.t103,
            label: "T-103 THERMOCOUPLE WITH HEAD & TIP ASSEMBLY",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T-103 THERMOCOUPLE WITH HEAD & TIP ASSEMBLY"
            )}`,
          },
          {
            image: images2.t104,
            label: "T - 104 THERMOCOUPLE WITH HEAD AND WELD PAD",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T - 104 THERMOCOUPLE WITH HEAD AND WELD PAD"
            )}`,
          },
          {
            image: images2.t274,
            label:
              "T - 274 THERMOCOUPLE WITH JUNCTION BOX & ADJUSTABLE COMPENSATION FITTING WITH STUB",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T - 274 THERMOCOUPLE WITH JUNCTION BOX & ADJUSTABLE COMPENSATION FITTING WITH STUB"
            )}`,
          },
          {
            image: images2.t345,
            label: "T - 345 THERMOCOUPLE INSET",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T - 345 THERMOCOUPLE INSET"
            )}`,
          },
          {
            image: images2.t344,
            label: "T - 344 THERMOCOUPLE INSET WITH FITTING",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T - 344 THERMOCOUPLE INSET WITH FITTING"
            )}`,
          },
          {
            image: images2.t105,
            label: "T - 105 THERMOCOUPLE WITH NIPPLE",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T - 105 THERMOCOUPLE WITH NIPPLE"
            )}`,
          },
          {
            image: images2.t106,
            label: "T - 106 THERMOCOUPLE WITH NIPPLE UNION NIPPLE",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T - 106 THERMOCOUPLE WITH NIPPLE UNION NIPPLE"
            )}`,
          },
          {
            image: images2.t101,
            label: "T - 101 THERMOCOUPLE WITH HEAD",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T - 101 THERMOCOUPLE WITH HEAD"
            )}`,
          },
        ],
      },
      {
        label: "MI Thermocouple with Cable",
        content: [
          {
            image: images2.t203,
            label: "T-203 THERMOCOUPLE WITH BOYONET & CABLE",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T-203 THERMOCOUPLE WITH BOYONET & CABLE"
            )}`,
          },
          {
            image: images2.t204,
            label: "T - 204 THERMOCOUPLE WITH CABLE & FITTING (SPRING LOADED)",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T - 204 THERMOCOUPLE WITH CABLE & FITTING (SPRING LOADED)"
            )}`,
          },
          {
            image: images2.t200,
            label: "T - 200 THERMOCOUPLE WITH CABLE (MINERAL INSULATED)",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T - 200 THERMOCOUPLE WITH CABLE (MINERAL INSULATED)"
            )}`,
          },
          {
            image: "",
            label: `T - 200 THERMOCOUPLE WITH CABLE (MINERAL INSULATED)-Chromel-Alumel (Simplex) (K)-1-SS316 (1.4401) (316)-Grounded (GN) -50-1/4" BSP (1/4BSP)-Teflon/Teflon (A)-500-ANSI MC96.1 (A)`,
          },
        ],
      },
      {
        label: "MI Thermocouple with Connector",
        content: [
          {
            image: images2.t272,
            label: "T-272 THERMOCOUPLE (PROBE) WITH CABLE & CONNECTOR",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T-272 THERMOCOUPLE (PROBE) WITH CABLE & CONNECTOR"
            )}`,
          },
          {
            image: images2.t343,
            label: "T - 343 THERMOCOUPLE WITH CONNECTOR",
            route: `/products/${slugify("MI Thermocouples")}/${slugify(
              "T - 343 THERMOCOUPLE WITH CONNECTOR"
            )}`,
          },
        ],
      },
    ],
    rowsHead: "vertical",
    rows: [
      ["Type", "J, K, T, E, N, R, S"],

      ["Element size (MI)", "0.25, 0.5, 1.0, 1.5, 3.0, 4.5, 6.0, 8.0 mm Other sizes on request"],

      ["Sheath Material", "SS321, SS316, SS310, HRS 446, Inconel 600, Nimonic, Pyrosil etc."],
      ["Configuration", "Simplex/ Duplex/ Multipoint"],

      [
        "Configuration",
        `Miniature Thermocouples with minimum of 0.25 m Dia,
         \n Swaged Tip Thermocouples,
         \n Tube Temperature Skin Type Thermocouples, 
         \n Special Sensors as per ASTM-E235 for critical applications ,
         \n   High Wall Thickness`,
      ],
    ],
    rows2: [
      ["Type", "Names of Materials", "Application Range", "PDF's"],
      ["J", "\n            Iron (+) Constantan (-)\n          ", "-40 to 750ºC", "pdf"],
      ["K", "Chromel (+) Alumel (-)", "-200 to 1200ºC", "pdf"],
      ["T", "\n            Copper (+) Constantan (-)\n          ", "-200 to 350ºC", "pdf"],
      ["E", "\n            Chromel (+) Constantan (-)\n          ", "-200 to 900ºC", "pdf"],
      ["N", "Nicorsil (+) Nisil (-)", "-200 to 1200ºC", "pdf"],
      [
        "R",
        "\n            Platinum 13% Rhodium (+) Platinum (-)\n          ",
        "0 to 1600ºC",
        "pdf",
      ],
      [
        "S",
        "\n            Platinum 10% Rhodium (+) Platinum (-)\n          ",
        "0 to 1600ºC",
        "pdf ",
      ],
    ],
    path: `/products/${slugify("MI Thermocouples")}`,
    title: "MI Thermocouples",
    description:
      "Mineral Insulated Thermocouples, commonly referred to as MgO (Magnesium Oxide) thermocouples, are used in many processes and laboratory applications. They are available in all thermocouple element types and a wide variety of sheath diameters and materials. They are rugged in nature and bendable, and their fairly high-temperature ratings make MI thermocouples a popular choice for a multitude of temperature measuring applications.",
  },
  {
    banner: images3.banner,
    tabs: [
      {
        label: "MI Thermocouple with Head",
        content: [
          {
            image: images3.t800,
            label: "T - 800 STANDARD THERMOCOUPLE WITH CABLE & M-F CONNECTOR",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 800 STANDARD THERMOCOUPLE WITH CABLE & M-F CONNECTOR"
            )}`,
          },
          {
            image: images3.t803,
            label: "T - 803 STANDARD THERMOCOUPLE",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 803 STANDARD THERMOCOUPLE"
            )}`,
          },
          {
            image: images3.t804,
            label: "T - 804 THERMOCOUPLE WITH DOUBLE PROTECTION TUBE & ADJUSTABLE FITTING",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 804 THERMOCOUPLE WITH DOUBLE PROTECTION TUBE & ADJUSTABLE FITTING"
            )}`,
          },
          {
            image: images3.t805,
            label: "T - 805 THERMOCOUPLE WITH DOUBLE PROTECTION TUBE & ADJUSTABLE FLANGE",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 805 THERMOCOUPLE WITH DOUBLE PROTECTION TUBE & ADJUSTABLE FLANGE"
            )}`,
          },
          {
            image: images3.t806,
            label: "T - 806 THERMOCOUPLE WITH SINGLE PROTECTION TUBE & ADJUSTABLE FLANGE",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 806 THERMOCOUPLE WITH SINGLE PROTECTION TUBE & ADJUSTABLE FLANGE"
            )}`,
          },
          {
            image: images3.t900,
            label: "T - 900 TRI-LEVEL THERMOCOUPLE WITH LONG PT THIMBLE",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 900 TRI-LEVEL THERMOCOUPLE WITH LONG PT THIMBLE"
            )}`,
          },
          {
            image: images3.t901,
            label: "T - 901 TRI-LEVEL PT-RH THERMOCOUPLE WITH SMALL PT THIMBLE",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 901 TRI-LEVEL PT-RH THERMOCOUPLE WITH SMALL PT THIMBLE"
            )}`,
          },
          {
            image: images3.t902,
            label: "T - 902 SINGLE LEVEL THERMOCOUPLE WITH SMALL PT THIMBLE",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 902 SINGLE LEVEL THERMOCOUPLE WITH SMALL PT THIMBLE"
            )}`,
          },
          {
            image: images3.t903,
            label: "T - 903 THERMOCOUPLE WITH OUTER-INNER PROTECTION TUBE & COUNTER FLANGE",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 903 THERMOCOUPLE WITH OUTER-INNER PROTECTION TUBE & COUNTER FLANGE"
            )}`,
          },
          {
            image: images3.t904,
            label: "T - 904 GLASS LEVEL PROBE/ ELECTRODE",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 904 GLASS LEVEL PROBE/ ELECTRODE"
            )}`,
          },
          {
            image: images3.t988,
            label: "T - 988 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH CABLE",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 988 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH CABLE"
            )}`,
          },
          {
            image: images3.t989,
            label: "T - 989 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH LEMO CONNECTOR",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 989 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH LEMO CONNECTOR"
            )}`,
          },
          {
            image: images3.t990,
            label: "T - 990 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH MALE FEMALE CONNECTOR",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T - 990 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH MALE FEMALE CONNECTOR"
            )}`,
          },
          {
            image: images3.t991,
            label: "T 991 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH TRANSITION JOINT",
            route: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
              "T 991 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH TRANSITION JOINT"
            )}`,
          },
        ],
      },
    ],
    rowsHead: "vertical",
    rows: [
      ["Type", "R, S, B"],
      ["Element Diameter", "0.30, 0.35, 0.4, 0.45, 0.5 mm other sizes on request"],
      [
        "Sheath Material",
        "\n          Recrystallized Alumina Ceramic(C-799), 610, Inconel, Silicon Carbide,\n          Platinum, etc\n        ",
      ],
      ["Configuration", "Simplex/ Duplex/ Multipoint"],
      [
        "Special",
        "\n          Hot Blast & amp; Stove Dome Thermocouples, Tri-Level Thermocouples,\n          Crown Thermocouples\n        ",
      ],
    ],
    rows2: [
      ["Type", "Names of Materials", "Application Range", "PDF's", "Application"],
      [
        "R",
        "\n            Platinum 13% Rhodium(+) Platinum (-)\n          ",
        "0 to 1450ºC",
        "\n            pdf\n          ",
        "Inert Media, Oxidizing media",
      ],
      [
        "S",
        "\n            Platinum 10% Rhodium (+) Platinum (-)\n          ",
        "0 to 1450ºC",
        "\n           pdf\n          ",
        "Inert Media, Oxidizing media",
      ],
      [
        "B",
        "\n            Platinum30% Rhodium (+) Platinum 6% Rhodium (-)\n          ",
        "800ºC to 1700ºC",
        "\n            pdf\n          ",
        "Inert Media, Oxidizing media",
      ],
    ],
    path: `/products/${slugify("Noble Metal Thermocouples")}`,
    title: "Noble Metal Thermocouples",
    description:
      "Noble Metal Thermocouples are manufactured with precious or noble metals like Platinum and Rhodium. Noble metal thermocouples can be used in oxidizing or inert applications and must be used with a ceramic protection tube surrounding the thermocouple element. These are normally used for high-temperature applications. These sensors are usually fragile and must not be used in applications that are reducing or in applications that contain metallic vapors.",
  },
  {
    banner: images4.banner,
    tabs: [
      {
        label: "MI Thermocouple with Head",
        content: [
          {
            image: images4.t988,
            label: "T - 988 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH CABLE",
            route: `/products/${slugify("Refractory Thermocouples")}/${slugify(
              "T - 988 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH CABLE"
            )}`,
          },
          {
            image: images4.t989,
            label: "T - 989 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH LEMO CONNECTOR",
            route: `/products/${slugify("Refractory Thermocouples")}/${slugify(
              "T - 989 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH LEMO CONNECTOR"
            )}`,
          },
          {
            image: images4.t990,
            label: "T - 990 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH MALE FEMALE CONNECTOR",
            route: `/products/${slugify("Refractory Thermocouples")}/${slugify(
              "T - 990 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH MALE FEMALE CONNECTOR"
            )}`,
          },
          {
            image: images4.t991,
            label: "T 991 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH TRANSITION JOINT",
            route: `/products/${slugify("Refractory Thermocouples")}/${slugify(
              "T 991 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH TRANSITION JOINT"
            )}`,
          },
          {
            image: images4.t992,
            label:
              "T - 992 ULTRA HIGH TEMPERATURE THERMOCOUPLE, TRANSITION JOINT WITH LEMO CONNECTOR",
            route: `/products/${slugify("Refractory Thermocouples")}/${slugify(
              "T - 992 ULTRA HIGH TEMPERATURE THERMOCOUPLE, TRANSITION JOINT WITH LEMO CONNECTOR"
            )}`,
          },
        ],
      },
    ],
    rowsHead: "vertical",
    rows: [
      ["Type", "G, C, D"],
      ["Sheath Diameter", "1.6, 3.2, 6.4, 8.0 mm"],
      ["Sheath Material", "Tantalum, Molybdenum, Inconel 600, Ceramic etc"],
      ["Standard Transition Sleeve", "SS316 or INCONEL"],
      ["Insulation Material", "Magnesium Oxide, Aluminium Oxide, Beryllium Oxide, Hafnium Oxide"],
    ],
    rows2: [
      ["Type", "Names of Materials", "Application Range", "PDF's", "Application"],
      [
        "C",
        "\n            Tungsten5% Rhenium (+) Tungsten26% Rhenium(-)\n          ",
        "0 to 2320ºC",
        "pdf",
      ],
      [
        "D",
        "\n            Tungsten 3% rhenium(+)&nbsp; Tungsten 26% rhenium (-)\n          ",
        "0 to 2320ºC",
        "pdf ",
        "Vacuum Inert and Reducing",
      ],
      [
        "G",
        "\n            Tungsten(+) Tungsten 26% Rhenium (-)\n          ",
        "0 to 2320ºC",
        "pdf",
        "Vacuum Inert and Reducing",
      ],
    ],
    path: `/products/${slugify("Refractory Thermocouples")}`,
    title: "Refractory Thermocouples",
    description:
      "Refractory Metal Thermocouples are manufactured from exotic metals Tungsten and Rhenium. These metals are expensive, difficult to manufacture and are brittle. These are used for high temperature, reducing or vacuum atmosphere conditions.",
  },
  {
    banner: images5.banner,
    tabs: [
      {
        label: "MI Thermocouple with Head",
        content: [
          {
            image: images5.t950,
            label: `T - 950 THERMOCOUPLE FOR MOLTEN ALUMINIUM ("L" SHAPE)`,
            route: `/products/${slugify("Special Thermocouple Types")}/${slugify(
              'T - 950 THERMOCOUPLE FOR MOLTEN ALUMINIUM ("L" SHAPE)'
            )}`,
          },
          {
            image: images5.t951,
            label: "T - 951 THERMOCOUPLE FOR MOLTEN ALUMINIUM (STRAIGHT)",
            route: `/products/${slugify("Special Thermocouple Types")}/${slugify(
              "T - 951 THERMOCOUPLE FOR MOLTEN ALUMINIUM (STRAIGHT)"
            )}`,
          },
          {
            image: images5.t952,
            label: "T - 952 TWO POINT THERMOCOUPLE",
            route: `/products/${slugify("Special Thermocouple Types")}/${slugify(
              "T - 952 TWO POINT THERMOCOUPLE"
            )}`,
          },
          {
            image: images5.t953,
            label: "T - 953 MULTIPOINT THERMOCOUPLE",
            route: `/products/${slugify("Special Thermocouple Types")}/${slugify(
              "T - 953 MULTIPOINT THERMOCOUPLE"
            )}`,
          },
          {
            image: images5.t954,
            label: "T - 954 POT ROOM THERMOCOUPLE WITH CONNECTOR",
            route: `/products/${slugify("Special Thermocouple Types")}/${slugify(
              "T - 954 POT ROOM THERMOCOUPLE WITH CONNECTOR"
            )}`,
          },
          {
            image: images5.t955,
            label: "T - 955 ALUMINIUM POT THERMOCOUPLE",
            route: `/products/${slugify("Special Thermocouple Types")}/${slugify(
              "T - 955 ALUMINIUM POT THERMOCOUPLE"
            )}`,
          },
        ],
      },
    ],

    path: `/products/${slugify("Special Thermocouple Types")}`,
    title: "Special Thermocouple Types",
    description: "",
  },
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  {
    banner: images6.banner,
    tabs: [
      {
        label: "MI Thermocouple with Head",
        content: [
          {
            image: images6.r501,
            label: `R - 501 RTD with Thermowell & Adjustable Fitting`,
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 501 RTD with Thermowell & Adjustable Fitting"
            )}`,
          },
          {
            image: images6.r502,
            label: "R - 502 RTD with Thermowell & Adjustable Flange",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 502 RTD with Thermowell & Adjustable Flange"
            )}`,
          },
          {
            image: images6.r503,
            label: "R - 503 RTD with Taper Thermowell & Adjustable Flange",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 503 RTD with Taper Thermowell & Adjustable Flange"
            )}`,
          },
          {
            image: images6.r504,
            label: "R - 504 RTD with Straight Thermowell Adjustable Comp. Fitting & Cable",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 504 RTD with Straight Thermowell Adjustable Comp. Fitting & Cable"
            )}`,
          },
          {
            image: images6.r650,
            label: "R - 650 RTD with Nipple & Taper Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 504 RTD with Straight Thermowell Adjustable Comp. Fitting & Cable"
            )}`,
          },
          {
            image: images6.r651,
            label: "R - 651 RTD with N/U/N & Taper Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 651 RTD with N/U/N & Taper Thermowell"
            )}`,
          },
          {
            image: images6.r652,
            label: "R - 652 RTD with Nipple & Straight Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 652 RTD with Nipple & Straight Thermowell"
            )}`,
          },
          {
            image: images6.r653,
            label: "R - 653 RTD with N/U/N & Straight Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 653 RTD with N/U/N & Straight Thermowell"
            )}`,
          },
          {
            image: images6.r654,
            label: "R - 654 RTD with Straight Thermowell & Adjustable Comp. Fitting",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 654 RTD with Straight Thermowell & Adjustable Comp. Fitting"
            )}`,
          },
          {
            image: images6.r655,
            label: `R - 655 RTD with Nipple & Straight Thermowell`,
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 655 RTD with Nipple & Straight Thermowell"
            )}`,
          },
          {
            image: images6.r726,
            label: "R - 726 RTD with Nipple & Taper Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 726 RTD with Nipple & Taper Thermowell"
            )}`,
          },
          {
            image: images6.r727,
            label: "R - 727 RTD with N/U/N & Taper Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 727 RTD with N/U/N & Taper Thermowell"
            )}`,
          },
          {
            image: images6.r728,
            label: "R - 728 RTD with Tapered Thermowell & Adjustable Comp. Fitting",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 728 RTD with Tapered Thermowell & Adjustable Comp. Fitting"
            )}`,
          },
          {
            image: images6.r729,
            label: "R - 729 RTD with Nipple & Straight Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 729 RTD with Nipple & Straight Thermowell"
            )}`,
          },
          {
            image: images6.r730,
            label: "R - 730 RTD with N/U/N & Straight Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 730 RTD with N/U/N & Straight Thermowell"
            )}`,
          },
          {
            image: images6.r731,
            label: "R - 731 RTD with Straight Thermowell & Flange",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 731 RTD with Straight Thermowell & Flange"
            )}`,
          },
          {
            image: images6.r732,
            label: "R - 732 RTD with Flange N/U/N & stepped Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 732 RTD with Flange N/U/N & stepped Thermowell"
            )}`,
          },
          {
            image: images6.r734,
            label: "R - 734 RTD with N/U/N & Coated Taper Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 734 RTD with N/U/N & Coated Taper Thermowell"
            )}`,
          },
          {
            image: images6.r735,
            label: "R - 735 RTD with N/U/N & Coated Taper Thermowell",
            route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
              "R - 735 RTD with N/U/N & Coated Taper Thermowell"
            )}`,
          },
        ],
      },
    ],
    rowsHead: "vertical",
    rows: [
      ["Type", "PT50, PT100,PT200, PT500, PT1000"],
      [
        "Element Type",
        "\n          Wire wound ceramic encapsulated, Wire-wound glass encapsulated, Thin\n          film ceramic encapsulated\n        ",
      ],
      [
        "Protection Sheath Material&nbsp;",
        "\n          SS304, SS321, SS316, SS310, Inconel 600/800, HRS 446, Hastalloy, Monel\n        ",
      ],
      ["Wire Configuration", "2, 3, 4 Wire"],
      ["Accuracy ", "Class B, Class A,1/3,1/5,1/10 DIN"],
      ["Configuration", "Simplex/ Duplex"],
    ],
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}`,
    title: "RTDs with Thermowells / Protection Tubes",
    description:
      "Resistance thermometer use metals that alter their electric resistance when heated. Platinum is the most commonly used material for industrial RTD. However, Copper and Nickel are also used for some applications.",
  },
  {
    banner: images7.banner,
    tabs: [
      {
        label: "Mineral Insulated RTD with head",
        content: [
          {
            image: images7.r103,
            label: `R - 103 Standard RTD with Head & Tip Assembly`,
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 103 Standard RTD with Head & Tip Assembly"
            )}`,
          },
          {
            image: images7.r104,
            label: "R - 104 RTD With Head & Weld Pad",
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 104 RTD With Head & Weld Pad"
            )}`,
          },
          {
            image: images7.r105,
            label: "R - 105 RTD with Nipple",
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 105 RTD with Nipple"
            )}`,
          },
          {
            image: images7.r106,
            label: "R - 106 RTD with Nipple Union Nipple",
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 106 RTD with Nipple Union Nipple"
            )}`,
          },
        ],
      },
      {
        label: "Mineral Insulated RTD with cable",
        content: [
          {
            image: images7.r200,
            label: `R - 200 RTD with Cable (Mineral Insulated)`,
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 200 RTD with Cable (Mineral Insulated)"
            )}`,
          },
          {
            image: images7.r201,
            label: "R - 201 RTD with Cable and Adjustable Comp. Fitting",
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 201 RTD with Cable and Adjustable Comp. Fitting"
            )}`,
          },
          {
            image: images7.r203,
            label: "R - 203 RTD with Cable & Bayo Nut",
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 203 RTD with Cable & Bayo Nut"
            )}`,
          },
          {
            image: images7.r204,
            label: "R - 204 RTD with Cable & Fitting (Spring Loaded)",
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 204 RTD with Cable & Fitting (Spring Loaded)"
            )}`,
          },
          {
            image: images7.r206,
            label: "R - 206 RTD with Weld Pad & Cable",
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 206 RTD with Weld Pad & Cable"
            )}`,
          },
        ],
      },
      {
        label: "Mineral Insulated RTD with connector",
        content: [
          {
            image: images7.r271,
            label: `R - 271 RTD with Cable & Connector`,
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 271 RTD with Cable & Connector"
            )}`,
          },
          {
            image: images7.r272,
            label: "R - 272 RTD (Probe) with Cable & Connector",
            route: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
              "R - 272 RTD (Probe) with Cable & Connector"
            )}`,
          },
          // {
          //   image: images7.r203,
          //   label: "R - 203 RTD with Cable & Bayo Nut",
          // },
          // {
          //   image: images7.r204,
          //   label: "R - 204 RTD with Cable & Fitting (Spring Loaded)",
          // },
          // {
          //   image: images7.r206,
          //   label: "R - 206 RTD with Weld Pad & Cable",
          // },
        ],
      },
    ],
    rowsHead: "vertical",
    rows: [
      ["Type", "PT 100, PT200, PT500, PT1000 Cu-50, Cu-53 etc"],
      ["Element Diameter", "1.5, 3.0, 4.5, 6.0, 8.0 mm"],
      ["Connection", "2, 3, 4 Wire"],
      ["Configuration", "Simplex/ Duplex"],
      [
        "Special RTDs",
        "Slide shoe bearing RTDs, Vibration proof RTDs for Bearing & DG sets, Motor & Transformer winding temperature RTDs, Handheld & Probe in various designs, Strap on RTDs for nuclear application etc",
      ],
    ],
    path: `/products/${slugify("Mineral Insulated RTDs")}`,
    title: "Mineral Insulated RTDs",
    description:
      "Mineral Insulated Resistance Thermometers are made with Platinum-measuring resistors Pt100Ω to DIN IEC 751.The measuring resistor will be connected to the inner conductors, is also embedded and is surrounded by the metal sheath to form a hermetically sealed assembly.",
  },
  {
    banner: images8.banner,
    tabs: [
      {
        label: "Mineral Insulated RTD with head",
        content: [
          {
            image: images8.r800,
            label: `R - 800 Slide Shoe RTD with Bakelite Cup & 'U' Clamp`,
            route: `/products/${slugify("Special Resistance Temperature Detectors")}/${slugify(
              "R - 800 Slide Shoe RTD with Bakelite Cup & 'U' Clamp"
            )}`,
          },
        ],
      },
    ],

    path: `/products/${slugify("Special Resistance Temperature Detectors")}`,
    title: "Special Resistance Temperature Detectors",
    description: (
      <div>
        <p>
          Hitech Sensors offer a wide range of special design resistance temperature detectors for
          various industrial applications.
        </p>
        <ul style={{ paddingLeft: "2rem" }}>
          <li>Slide shoe bearing RTDs</li>
          <li>Vibration-proof RTDs for Bearing & DG sets</li>
          <li>Motor & Transformer winding temperature RTDs</li>
          <li>Handheld & Probe in various designs</li>
          <li>RTDs with IBR approved Thermowells</li>
          <li>Strap on RTDs for nuclear application</li>
          <li>High-Accuracy RTDs up to 1/10 DIN</li>
          <li>
            Semi Standard PRTs with NABL Certificate calibrated at Fixed points suitable up to 661°C
          </li>
          <li>Autoclave RTD for Validation.</li>
        </ul>
      </div>
    ),
  },
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  {
    banner: images9.banner,
    rows: [
      [
        "\n            Protection Tube Type\n          ",
        "\n            Ceramic Tubes\n          ",
        "\n            Stainless Steel Tubes\n          ",
      ],
      [
        "\n            Materials\n          ",
        "\n            Recrystallised Alumina 99.7%\n          ",
        "\n            SS 304, SS 304L, SS 310, SS 446, Inconel-800/600,Hastalloy, Titanium\n            etc.\n          ",
      ],
      [
        "\n            Length(mm)\n          ",
        "\n            350, 530, 600, 650, 740, 900, 1030, 1200, 1430 mm etc\n          ",
        "\n            As per customer requirement\n          ",
      ],
      [
        "Type",
        "\n            KER 710(C-799) Open Ended, Close Ended\n          ",
        "\n            Open Ended, Close Ended ,Round Ended\n          ",
      ],
      [
        "\n            Outer Diameter x Inner Diameter(mm)\n          ",
        "\n            \n              6 x 4, 8 x 5, 10 x 6, 12 x 8, 15 x 10, 20 x 15, 24 x 18 mm etc.\n              High wall thickness tubes available\n            \n          ",
        "\n            3 x 2.2, 4 x 3.7, 4.5 x 3.7, 5 x 4, 6 x 5, 8 x 6.5, 9.5 x 7.1, 10 x\n            7 etc.\n          ",
      ],
    ],
    buttons: [{ title: "Weight and thickness of SS Gauge Pipe", action: "" }],
    tabs: [
      {
        label: "Mineral Insulated RTD with head",
        content: [
          {
            image: images9.plain_ceramic,
            label: `Ceramic Plain Tube`,
          },
          {
            image: images9.ceramic,
            label: `Ceramic Tube with Mounting Fitting`,
          },
          {
            image: images9.protecting_nipple,
            label: `Ceramic Tube with Mounting Nipple`,
          },
          {
            image: images9.collar,
            label: `Collar Tube`,
          },
          {
            image: images9.plain_pipe,
            label: `Stainless Steel Plain Tube`,
          },
          {
            image: images9.plain_pipe_tube_with_flange,
            label: `Stainless Steel Tube with Flange`,
          },
          {
            image: images9.protecting_tube_mounting,
            label: `Stainless Steel Tube with Mounting Bush`,
          },
          {
            image: images9.plain_tube,
            label: `Silicon Carbide Pain Tube`,
          },
        ],
      },
    ],

    path: `/products/${slugify("Protection Tubes")}`,
    title: "Protection Tubes",
    description: "",
  },
  {
    banner: images10.slider,
    rows2: [
      [
        <>
          Material
          <br />
        </>,
        "SS316, SS304 ,SS303, Carbon Steel, Brass",
      ],
      [
        <>
          Thread Standard
          <br />
        </>,
        "NPT, BSP",
      ],
    ],
    rows2Head: "vertical",
    tabs: [
      {
        label: "Mineral Insulated RTD with head",
        content: [
          {
            image: images10.back,
            label: `Back Ferrule`,
          },
          {
            image: images10.bsp,
            label: `BSP Fitting`,
          },
          {
            image: images10.elbow,
            label: `Elbow Union`,
          },
          {
            image: images10.female,
            label: `Female Connector`,
          },
          {
            image: images10.front,
            label: `Front Ferrule`,
          },
          {
            image: images10.male_adapter,
            label: `Male Adapter`,
          },
          {
            image: images10.male_connector,
            label: `Male Connector`,
          },
          {
            image: images10.elbow,
            label: `Male Elbow`,
          },
          {
            image: images10.male_pipe,
            label: `Male Pipe Weld Connector`,
          },
          {
            image: images10.male_reducing,
            label: `Male Reducing Adapter`,
          },
          {
            image: images10.nut,
            label: `NUT`,
          },
          {
            image: images10.reducing,
            label: `Reducing Union`,
          },
          {
            image: images10.tapered,
            label: `Tapered Ferrule`,
          },
        ],
      },
    ],

    path: `/products/${slugify("Compression Fittings")}`,
    title: "Compression Fittings",
    description: "",
  },
  {
    banner: images11.slider,
    rows2: [
      [
        <>
          Material
          <br />
        </>,
        "SS316, SS304 ,SS303, Carbon Steel, Brass",
      ],
      [
        <>
          Thread Standard
          <br />
        </>,
        "NPT, BSP",
      ],
    ],
    buttons: [{ title: "BSP Thread Details", action: "" }],
    rows2Head: "vertical",
    tabs: [
      {
        label: "Mineral Insulated RTD with head",
        content: [
          {
            image: images11.adapter,
            label: `Adapter`,
          },
          {
            image: images11.hex_male,
            label: `Hex Male Adapter`,
          },
          {
            image: images11.hex_nipple,
            label: `Hex Nipple`,
          },
          {
            image: images11.hex_reducing_nipple,
            label: `Hex Reducing Nipple`,
          },
          {
            image: images11.long_nipple,
            label: `Long Nipple`,
          },
          {
            image: images11.male_adapter,
            label: `Male Adapter with Locknut`,
          },
          {
            image: images11.nipple,
            label: `Nipple`,
          },
          {
            image: images11.pipe_cap,
            label: `Pipe Cap`,
          },
          {
            image: images11.pipe_plug,
            label: `Pipe Plug`,
          },
          {
            image: images11.reducing_adapter,
            label: `Reducing Adapter`,
          },
          {
            image: images11.reducing_bushing,
            label: `Reducing Bushing`,
          },
          {
            image: images11.three_piece,
            label: `Three Piece Union`,
          },
          {
            image: images10.tapered,
            label: `Tapered Ferrule`,
          },
        ],
      },
    ],

    path: `/products/${slugify("Precision Pipe Fittings")}`,
    title: "Precision Pipe Fittings",
    description: "",
  },
  {
    banner: images12.slider,
    rows2: [
      [
        <>
          Material
          <br />
        </>,
        "SS304,SS316, SS316L,Carbon Steel, Cast Iron etc",
      ],
      ["Size", '1/2" to 4" & Others'],
      ["Rating", "150# to 2500# etc."],
    ],
    buttons: [{ title: "ANSI B16.5 Flange Details", action: "" }],
    rows2Head: "vertical",
    tabs: [
      {
        label: "Mineral Insulated RTD with head",
        content: [
          {
            image: images12.blind,
            label: `Blind Flange`,
          },
          {
            image: images12.threaded,
            label: `Threaded Flange`,
          },
        ],
      },
    ],

    path: `/products/${slugify("Flanges")}`,
    title: "Flanges",
    description: "",
  },
  {
    banner: images13.slider,
    rows2: [
      [
        <>
          Material
          <br />
        </>,
        "SS316, SS304 ,SS303, Carbon Steel, Brass",
      ],
      [
        <>
          Thread Standard
          <br />
        </>,
        "NPT, BSP",
      ],
    ],
    rows2Head: "vertical",
    tabs: [
      {
        label: "Mineral Insulated RTD with head",
        content: [
          {
            image: images13.bayonet,
            label: `Adjustable Bayonut Caps`,
          },
          {
            image: images13.spring,
            label: `Springs`,
          },
        ],
      },
    ],

    path: `/products/${slugify("Boyonet Caps")}`,
    title: "Boyonet Caps",
    description: "",
  },
  {
    banner: images14.slider,
    rows2: [
      [
        <>
          Material
          <br />
        </>,
        "SS316, SS304 ,SS303, Carbon Steel, Brass",
      ],
      [
        <>
          Thread Standard
          <br />
        </>,
        "NPT, BSP",
      ],
    ],
    rows2Head: "vertical",
    tabs: [
      {
        label: "Mineral Insulated RTD with head",
        content: [
          {
            image: images14.connector,
            label: `Connector Seal Pots`,
          },
          {
            image: images14.plain,
            label: `Plain Seal Pots`,
          },
          {
            image: "",
            label: `Threaded Seal Pots`,
          },
        ],
      },
    ],

    path: `/products/${slugify("Seal Pots & Accessories")}`,
    title: "Seal Pots & Accessories",
    description: "",
  },
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  {
    banner: images15.thermowell,
    rows2: [
      ["Type", "Drilled Barstock, Fabricated"],
      ["Construction", "Tapered, Straight, Stepped, Helical"],
      [
        "Thermowell Material",
        "\n          SS304, SS316, SS316L, SS321,SS310, HRS446, INCONEL 600/800/601,\n          Hastalloy, Monel, Titanium etc\n        ",
      ],
      ["Process Connection", "Screwed, Flanged"],
      [
        "Certification",
        "\n          IBR certification on request, Radiography, PMI, Pressure\n          test,Ultrasonic test. Calculation as per PTC 19.3 can be provided\n        ",
      ],
    ],
    rows2Head: "vertical",
    tabs: [
      {
        label: "General Thermowell",
        content: [
          {
            image: images15.a100,
            label: `A100 Straight Thermowell Screwed Type`,
          },

          {
            image: images15.a102,
            label: `A102 Straight Thermowell Female Thread`,
          },
          {
            image: images15.a103,
            label: `A103 Tapered Thermowell Male Thread`,
          },
          {
            image: images15.a104,
            label: `A104 Tapered Thermowell Female Thread`,
          },
          {
            image: images15.a105,
            label: `A105 Stepped Thermowell`,
          },
          {
            image: images15.a106,
            label: `A106 Straight Thermowell Screwed Type (Male)`,
          },
          {
            image: images15.a107,
            label: `A107 Stepped Thermowell Male`,
          },
          {
            image: images15.a108,
            label: `A108 Taper Thermowell Screwed Type`,
          },
          {
            image: images15.a109,
            label: `A109 Heavy Duty Thick Wall Thermowell`,
          },
          {
            image: images15.a110,
            label: `A110 Straight Thermowell with Flange`,
          },
          {
            image: images15.a111,
            label: `A111 Taper Thermowell with Flange`,
          },
          {
            image: images15.a112,
            label: `A112 Welded Thermowell with Flange`,
          },
          {
            image: images15.a113,
            label: `A113 Thermowell with Ceramic Tube`,
          },
          {
            image: images15.a114,
            label: `A114 Double Protection Tube for Boiler Bed Temperature`,
          },
          {
            image: images15.a115,
            label: `A115 Flange with Taper Thermowell`,
          },
        ],
      },
      {
        label: "Special Thermowells",
        content: [
          {
            image: images15.a116,
            label: `A116 Van-Stone Thermowell`,
          },

          {
            image: images15.a117,
            label: `A117 Helical Taper Thermowell (Screwed Type)`,
          },
          {
            image: images15.a118,
            label: `A118 Helical Taper Thermowell With Flange`,
          },
        ],
      },
    ],
    buttons: [
      { title: "Download Catalogue", action: "" },
      { title: "Thermowell Selection", action: "" },
      { title: "Standard Manufacturing Processes", action: "" },
      { title: "Material Selection", action: "" },
      { title: "Special Coating", action: "" },
      { title: "Welding", action: "" },
      { title: "Marking and Packing", action: "" },
      { title: "Vibration Calculation", action: "" },
      { title: "Pressure rating", action: "" },
    ],
    images: [{ src: images15.protection_tube, title: "" }],
    path: `/products/${slugify("Thermowells")}`,
    title: "Thermowells",
    description:
      "Thermowell is a tube, closed at one end, which protects the probe and allows its removal without breaking the liquid seal. Many materials and styles are available to match application requirements. Thermowells drilled from solid bar stock provide the highest pressure ratings, and welded models are also available.",
  },
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  {
    banner: images16.slider,
    rows2: [
      ["Construction", "Single/ Multi-Core"],
      ["Voltage Grade", "Up to 1.1 kV"],
      ["Conductor", "TC, EX, C"],
      ["Type of Conductor", "K, T, J, E, N, R, S, B, D, C"],
      ["Conductor Size", "AWG 12 to AWG 32"],
      ["Conductor Stranding", "Solid or Multi Strand"],
      [
        "Core Insulation",
        "\n          PVC, XLPE, LSZH, PE, PTFE, FEP, PFA, PEEK, Silicon, ETFE, Polyimide,\n          Fiber Glass, Ceramic Fiber, Alumina Fiber\n        ",
      ],
      ["Screening ", "Aluminum Foil Type/ Mesh Braided Type"],
      [
        "Inner/Outer Sheath",
        "\n          PVC, LSZH, PTFE, FEP, PFA, ETFE, Silicon, Polyimide, Fiber Glass,\n          Ceramic Fiber\n        ",
      ],
      ["Armoring", "G.I. Armouring/ SS Braiding (For High-Temperature Insulations)"],
      ["Colour Code", "As per the Colour Code table"],
      ["Standards", "ANSI MC 96.1, IEC 584.3, IS 8784"],
    ],
    rows2Head: "vertical",
    tabs: [
      {
        label: "PTFE Insulated",
        content: [
          {
            image: images16.t101,
            label: `T-101 PTFE Twisted Pair - 260°C`,
            pdf: pdf16.T101,
          },
          {
            image: images16.t201,
            label: `T-201 PTFE-PTFE Insulated 260 °C`,
            pdf: pdf16.T201,
          },
          {
            image: images16.tf301,
            label: `T-301 PTFE-Almyler-Screen-PTFE Insulated 260 °C`,
            pdf: pdf16.T301,
          },
          {
            image: images16.t401,
            label: `T-401 PTFE - PTFE -Steel Braiding Insulated - 260°C`,
            pdf: pdf16.T401,
          },
          {
            image: images16.t501,
            label: `T-501 PTFE - Polyimide - Copper Screening - PTFE Insulated - 260°C`,
            pdf: pdf16.T501,
          },
          {
            image: images16.t701,
            label: `T-701 PTFE-Fiber Glass-Fiber Glass-400°C`,
            pdf: pdf16.T701,
          },
          {
            image: images16.t801,
            label: `T-801 PTFE-PTFE-Asbestos Braiding Insulated 300 °C`,
            pdf: pdf16.T801,
          },
          {
            image: images16.t151,
            label: `T-151 PTFE-Polyimide-Copper Screening-Silicon insulated - 200°C`,
            pdf: pdf16.T151,
          },
          {
            image: images16.t251,
            label: `T-251 PTFE-Polyimide-Copper Screening- PTFE-Asbestos Braiding - 300°C`,
            pdf: pdf16.T251,
          },
          {
            image: images16.t351,
            label: `T-351 PTFE-PTFE-MICA-Fiber Glass insulated - 350°C`,
            pdf: pdf16.T351,
          },
          {
            image: images16.t451,
            label: `T-451 PTFE-Polyimide-PTFE-MICA-FG-SS Braiding insulated - 450°C`,
            pdf: pdf16.T451,
          },
        ],
      },
      {
        label: "Silicon Insulated",
        content: [
          {
            image: images16.s101,
            label: `S-101 Silicon-Silicon insulated - 200°C`,
            pdf: pdf16.S101,
          },
          {
            image: images16.s201,
            label: `S-201 Silicon-Almyler Screen-Silicon - 200°C`,
            pdf: pdf16.S201,
          },
          {
            image: images16.s301,
            label: `S-301 Silicon-Fiber Glass-SS Braiding - 200°C`,
            pdf: pdf16.S301,
          },
          {
            image: images16.s401,
            label: `S-401 Silicon-Copper Braiding-Silicon insulated - 200°C`,
            pdf: pdf16.S401,
          },
        ],
      },
      {
        label: "PVC Insulated",
        content: [
          {
            image: images16.p101,
            label: `P101 PVC-Polyster Tape-Copper Braiding-PVC - 90°C`,
          },
          {
            image: images16.p201,
            label: `P201 PVC-PVC insulated - 90°C`,
            pdf: pdf16.P201,
          },
          {
            image: images16.p301,
            label: `P301 PVC-Almyler Screening-PVC - 90°C`,
            pdf: pdf16.P301,
          },
          {
            image: images16.p401,
            label: `P401 PVC-Almyler-PVC-G.I. Armoring-PVC insulated - 90°C`,
            pdf: pdf16.P401,
          },
          {
            image: images16.p501,
            label: `P501 PVC-PVC-SS Braiding insulated - 90°C`,
            pdf: pdf16.P501,
          },
        ],
      },
      {
        label: "PFA Insulated",
        content: [
          {
            image: images16.pf101,
            label: `PF-101 PFA-PFA insulated - 260°C`,
            pdf: pdf16.PF101,
          },
          {
            image: images16.pf201,
            label: `PF-201 PFA-SS Braiding insulated - 260°C`,
            pdf: pdf16.PF201,
          },
          {
            image: images16.pf301,
            label: `PF-301 PFA-PFA-SS Braiding insulated - 260°C`,
            pdf: pdf16.PF301,
          },
        ],
      },
      {
        label: "Polymide Insulated",
        content: [
          {
            image: images16.k101,
            label: `K-101 Polyimide Twisted - 310°C`,
            pdf: pdf16.K101,
          },
          {
            image: images16.k201,
            label: `K-201 Polyimide-Polyimide - 310°C`,
          },
        ],
      },
      {
        label: "Fiber Glass Insulated",
        content: [
          {
            image: images16.fg101,
            label: `FG-101 Fiber Glass - Fiber Glass insulated - 450°C`,
            pdf: pdf16.FG101,
          },
          {
            image: images16.fg301,
            label: `FG-201 Polyimide-Fiber Glass-Fiber Glass-Asbestos Braiding - 450°C`,
            pdf: pdf16.FG301,
          },
        ],
      },
      {
        label: "FEP Insulated",
        content: [
          {
            image: images16.fp201,
            label: `FP-201 FEP-FEP-SS Braiding insulated - 200°C`,
            pdf: pdf16.FP201,
          },
          {
            image: images16.fp301,
            label: `FP-301 FEP- Almyler-FEP - 200°C`,
            pdf: pdf16.FP301,
          },
          {
            image: images16.fp401,
            label: `FP-401 FEP-Polyimide-Copper Screening-FEP-SS Braiding - 200°C`,
            pdf: pdf16.FP401,
          },
          {
            image: images16.fp501,
            label: `FP-501 FEP-Silicon insulated - 200°C`,
          },
          {
            image: images16.fp601,
            label: `FP-601 FEP-Polyimide-Copper Screening-FEP - 200°C`,
            pdf: pdf16.FP601,
          },
        ],
      },
      {
        label: "Alumina Fiber Insulated",
        content: [
          {
            image: images16.a101,
            label: `A-A01 Alumina Fiber-Alumina Fiber insulated - 1200°C`,
            pdf: pdf16.A101,
          },
        ],
      },
      {
        label: "Ceramic Fiber Insulated",
        content: [],
      },
    ],

    path: `/products/${slugify("Thermocouple Cables")}`,
    title: "Thermocouple Cables",
    description: (
      <>
        <p> Thermocouple Cables are used to measure the temperature directly. </p>
        <p>
          Thermocouple Extension or Compensating wires are only used to extend a thermocouple signal
          from a sensor to an instrument for readings.
        </p>
      </>
    ),
  },
  {
    banner: images16.slider,
    rows2: [
      ["Construction", "Single/ Multi-Core"],
      ["Voltage Grade", "Up to 1.1 kV"],
      ["Conductor", "Electrolytic Grade Bare Copper/ Tinned Copper/ SPC/ NPC"],
      ["Conductor Size", "0.50, 0.75, 1.0, 1.5 Sq. mm up to 48 triad"],
      ["Conductor Stranding", "Solid or Multi Strand"],
      ["Core Insulation", "PTFE, FEP, Silicon, PFA, PVC, PE, XLPE, LSZH Polymer, etc."],
      ["Screening ", "Aluminum Foil Type/Mesh Braided Type"],
      ["Inner/Outer Sheath", "PTFE, FEP, Silicon, PFA, PVC, LSZH Polymer, etc."],
      ["Armoring", "G.I. Armouring/ SS Braiding (For High-Temperature Insulations)"],
      [
        "Standards",
        "\n          As per BS 5308 Part 1 and Part 2, IS 1554, EN 50288-7, IS 7098, DIN\n          43760, JSS 51038\n        ",
      ],
    ],
    rows2Head: "vertical",
    tabs: [
      {
        label: "PTFE Insulated",
        content: [
          {
            image: images17.rt101,
            label: `RT-101 PTFE-PTFE insulated - 260°C`,
            pdf: pdf17.RT101,
          },
          {
            image: images17.rt201,
            label: `RT-201 PTFE-PTFE-SS Braiding insulated - 260°C`,
            pdf: pdf17.RT201,
          },
          {
            image: images17.rt301,
            label: `RT-301 PTFE-PTFE-Copper Screening-PTFE - 260°C`,
            pdf: pdf17.RT301,
          },
          {
            image: images17.rt401,
            label: `RT-401 PTFE-FEP insulated - 200°C`,
          },
          {
            image: images17.rt501,
            label: `RT-501 3 Core RTD Cable, Bare Copper, AWG 24/7/32, Stranded, PTFE-Silicon 200°C`,
          },
          {
            image: images17.rt601,
            label: `RT-601 PTFE-Almyler Screening-PTFE insulated - 260°C`,
            pdf: pdf17.RT601,
          },
          {
            image: images17.rt701,
            label: `RT-701 PTFE-Almyler Screening-PTFE-SS Braiding insulated - 260°C`,
            pdf: pdf17.RT701,
          },
          {
            image: images17.rt801,
            label: `RT-801 PTFE-PTFE-FG-SS Braiding insulated - 350°C`,
          },
          {
            image: images17.rt901,
            label: `RT-901 PTFE-Polyimide-Copper Screen-PTFE insulated - 260°C`,
          },
        ],
      },
      {
        label: "PVC Insulated",
        content: [
          {
            image: images17.rp101,
            label: `RP-101 PVC-PVC insulated - 90°C`,
            pdf: pdf17.RP101,
          },
          {
            image: images17.rp201,
            label: `RP-201 PVC-Polyster Tape-Copper Screening-PVC insulated - 90°C`,
            pdf: pdf17.RP101,
          },
          {
            image: images17.rp301,
            label: `RP-301 PVC-Almyler Screening-PVC insulated - 90°C`,
            pdf: pdf17.RP101,
          },
          {
            image: images17.rp401,
            label: `RP-401 PVC-Almyler Screening-PVC-G.I. Armoring-PVC insulated - 90°C`,
          },
        ],
      },
      {
        label: "FEP Insulated",
        content: [
          {
            image: images17.rtf101,
            label: `RFP-101 FEP-FEP insulated - 200°C`,
            pdf: pdf17.RFP101,
          },
          {
            image: images17.rtf201,
            label: `RFP-201 FEP-FEP-SS Braiding insulated - 200°C`,
            pdf: pdf17.RFP201,
          },
          {
            image: images17.rtf301,
            label: `RFP-301 FEP-Almyler Screen-FEP insulated`,
            pdf: pdf17.RFP301,
          },
          {
            image: images17.rtf401,
            label: `RFP-401 FEP-Almyler Screen-FEP-SS Braiding insulated - 200°C`,
            pdf: pdf17.RFP401,
          },
          {
            image: images17.rtf501,
            label: `RFP-501 FEP-Polyimide-Copper Screening-FEP insulated - 200°C`,
            pdf: pdf17.RFP501,
          },
          {
            image: images17.rtf701,
            label: `RFP-601 FEP-Silicon insulated - 200°C`,
            pdf: pdf17.RFP601,
          },
        ],
      },
      {
        label: "Fiber Glass Insulated",
        content: [
          {
            image: images17.rfg101,
            label: `RFG-101 Polyimide-Fiber Glass-Fiber Glass insulated - 400°C`,
          },
          {
            image: images17.rfg201,
            label: `RFG-201 Fiber Glass-Fiber Glass-SS Braiding insulated - 400°C`,
            pdf: pdf17.RFG201,
          },
        ],
      },
    ],
    path: `/products/${slugify("RTD Cables")}`,
    title: "RTD Cables",
    description: (
      <>
        <p>
          RTD cables are used to carry the RTD signals to the control room or field-mounted
          instruments.
        </p>
        <p>
          Depending on the configuration, they are available in three wires, four wires for simplex
          and, six and eight wires for duplex RTD. These RTD cables are manufactured as per BS 5308
          Part 1 and Part 2, IS 1554, EN 50288-7, IS 7098, DIN 43760, JSS 51038 standards with the
          latest amendment.
        </p>
      </>
    ),
  },
  {
    banner: images16.slider,
    tabs: [],
    path: `/products/${slugify("Mineral Insulated Thermocouple Cable")}`,
    title: "Mineral Insulated Thermocouple Cable",
    description: (
      <>
        <p>
          Mineral insulated thermocouple cables have inner conductors of Thermocouple base material
          as per standard ASTM E 585/ 585M and ASTM E 839.
        </p>
      </>
    ),
    component: <MineralInsulatedThermocoupleCable />,
  },
  {
    banner: images16.slider,
    tabs: [],

    path: `/products/${slugify("Mineral Insulated RTD Cables")}`,
    title: "Mineral Insulated RTD Cables",
    description: (
      <>
        <p>
          Mineral insulated cables for RTDs have inner conductors of copper, copper-nickel alloys,
          nickel, nickel-chromium, or nickel-plated copper.
        </p>
      </>
    ),
    component: <MineralInsulatedRTDCables />,
  },
  {
    banner: images16.slider,
    tabs: [],
    rows2: [
      ["Type", "KX, TX, JX, NX, EX, KCA, KCB, RCB/SCB"],
      ["Diameter", "0.16 mm to 3.0 mm Diameter"],
      ["Standard", "ANSI MC 96.1, other standard as per customer requirement"],
    ],
    rows2Head: "vertical",
    path: `/products/${slugify("Thermocouple Conductor")}`,
    title: "Thermocouple Conductor",
    description: (
      <>
        <p>
          {" "}
          Compensating and extension grades of wire are supplied in bright-annealed condition.
          Extension and compensating cable provide convenient, economic solutions.
        </p>

        <p>
          Extension grade wire is a less expensive, lower grade wire. Extension grade Conductors
          have a working temperature range from 0°C to 200°C.
        </p>
        <p>
          Compensating grade wire is a type of thermocouple wire that uses a different composition
          of alloys. Compensating grade wire is made from cheaper alloys than extension cable.
          Compensating grade Conductors have a working temperature range from 0°C to 100°C.
        </p>

        <p>In case of KCA temperature range is up to 0°C to 150°C.</p>
      </>
    ),
    component: <ThermocoupleConductor />,
  },
  {
    banner: images16.slider,
    tabs: [],
    rows2: [
      ["Type", "KX, TX, JX, NX, EX, KCA, KCB, RCB/SCB"],
      ["Diameter", "0.16 mm to 3.0 mm Diameter"],
      ["Standard", "ANSI MC 96.1, other standard as per customer requirement"],
    ],
    rows2Head: "vertical",
    path: `/products/${slugify("Extension/Compensating Conductor")}`,
    title: "Extension/Compensating Conductor",
    description: (
      <>
        <p>
          Compensating and extension grades of wire are supplied in bright-annealed condition.
          Extension and compensating cable provide convenient, economic solutions.
        </p>

        <p>
          Extension grade wire is a less expensive, lower grade wire. Extension grade Conductors
          have a working temperature range from 0°C to 200°C.
        </p>
        <p>
          Compensating grade wire is a type of thermocouple wire that uses a different composition
          of alloys. Compensating grade wire is made from cheaper alloys than extension cable.
          Compensating grade Conductors have a working temperature range from 0°C to 100°C.
        </p>

        <p>In case of KCA temperature range is up to 0°C to 150°C.</p>
      </>
    ),
    component: <ExtensionCompensatingConductor />,
  },
];
