import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Define the styled component for the container div
const StyledImageContainer = styled.div`
  width: ${(props) => props.size};
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  transition: transform 0.3s ease; /* Transition effect for hover */
  overflow: hidden;

  &:hover {
    transform: scale(1.02); /* Increase size on hover */
  }
`;

// Define the styled image component
const StyledImage = styled.img`
  object-fit: cover; /* Ensure the image covers its container */
  width: 100%;
  height: 100%;
`;

// Image component
const Image = ({ src, alt, size, onClick, style }) => {
  return (
    <StyledImageContainer size={size} onClick={onClick} style={style}>
      <StyledImage src={src} alt={alt} className="styled-image" />
    </StyledImageContainer>
  );
};

// PropTypes
Image.propTypes = {
  src: PropTypes.any,
  alt: PropTypes.any,
  size: PropTypes.any,
  onClick: PropTypes.any,
  style: PropTypes.object,
};

export default Image;
