import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { generateKey } from "utils/functions";

export default function BasicTable({ rows, headDirection = "horizontal" }) {
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const temp = rows.map((row) => createData(...row));
  return (
    <TableContainer component={Paper} sx={{ py: 0 }}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow></TableRow>
        </TableHead>
        <TableBody>
          {temp.map((row, i) => (
            <TableRow
              key={generateKey(row.name, i)}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontWeight:
                    headDirection === "vertical" || (headDirection === "horizontal" && i === 0)
                      ? "600"
                      : "",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: headDirection === "horizontal" && i === 0 ? "600" : "",
                }}
              >
                {row.calories}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: headDirection === "horizontal" && i === 0 ? "600" : "",
                }}
              >
                {row.fat}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: headDirection === "horizontal" && i === 0 ? "600" : "",
                }}
              >
                {row.carbs}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: headDirection === "horizontal" && i === 0 ? "600" : "",
                }}
              >
                {row.protein}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BasicTable.propTypes = {
  rows: PropTypes.any,
  headDirection: PropTypes.any,
};
