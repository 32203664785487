import { slugify } from "./functions";

import * as images3 from "../assets/images/cables/3/index";

export const THERMOCOUPLES_3 = [
  {
    image: images3.t800,
    product: "Noble Metal Thermocouples",
    title: "T - 800 STANDARD THERMOCOUPLE WITH CABLE & M-F CONNECTOR",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 800 STANDARD THERMOCOUPLE WITH CABLE & M-F CONNECTOR"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Standard Plug/ Socket (A)",
            value: "A",
          },
          {
            title: "Miniature Plug/ Socket (B)",
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.40)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.50)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Insulating Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ceramic - 610 (610)",
            value: "610",
          },
          {
            title: "Ceramic - 710 (710)",
            value: "710",
          },
          {
            title: "Ceramic -799 (799)",
            value: "799",
          },
        ],
      },
      {
        label: ' Outer Sheath "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "15 X 10 (E)",
            value: "E",
          },
          {
            title: "17 X 13 (F)",
            value: "F",
          },
          {
            title: "20 X 15 (G)",
            value: "G",
          },
          {
            title: "24 X 18 (H)",
            value: "H",
          },
        ],
      },
      {
        label: "Outer Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "\n          Recrystallised Aluminum Ceramic(99.7%) C-799 – (799)\n        ",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Sealpot length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t803,
    product: "Noble Metal Thermocouples",
    title: "T - 803 STANDARD THERMOCOUPLE",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 803 STANDARD THERMOCOUPLE"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.40)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.50)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insulating Tube "OD" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø3.5 (3.5)",
            value: "3.5",
          },
          {
            title: "Ø4.5 (4.5)",
            value: "4.5",
          },
          {
            title: "Ø5.5 (5.5)",
            value: "5.5",
          },
          {
            title: "Ø8.5 (8.5)",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Insulating Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ceramic - 610 (610)",
            value: "610",
          },
          {
            title: "Ceramic - 710 (710)",
            value: "710",
          },
          {
            title: "Ceramic -799 (799)",
            value: "799",
          },
        ],
      },
      {
        label: ' Outer Sheath "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "15 X 10 (E)",
            value: "E",
          },
          {
            title: "17 X 13 (F)",
            value: "F",
          },
          {
            title: "20 X 15 (G)",
            value: "G",
          },
          {
            title: "24 X 18 (H)",
            value: "H",
          },
        ],
      },
      {
        label: "Outer Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "\n          Recrystallised Aluminum Ceramic(99.7%) C-799 – (799)\n        ",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Holding Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Holding Tube "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: ' Holding Tube "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Holding Tube length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Exposed Length "L2"(mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t803,
    product: "Noble Metal Thermocouples",
    title: "T - 804 THERMOCOUPLE WITH DOUBLE PROTECTION TUBE & ADJUSTABLE FITTING",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 804 THERMOCOUPLE WITH DOUBLE PROTECTION TUBE & ADJUSTABLE FITTING"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.40)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.50)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insulating Tube "OD" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø3.5 (3.5)",
            value: "3.5",
          },
          {
            title: "Ø4.5 (4.5)",
            value: "4.5",
          },
          {
            title: "Ø5.5 (5.5)",
            value: "5.5",
          },
          {
            title: "Ø8.5 (8.5)",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Insulating Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ceramic - 610 (610)",
            value: "610",
          },
          {
            title: "Ceramic - 710 (710)",
            value: "710",
          },
          {
            title: "Ceramic -799 (799)",
            value: "799",
          },
        ],
      },
      {
        label: 'Inner Tube "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "20x15 (E)",
            value: "E",
          },
          {
            title: "24x18 (F)",
            value: "F",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Inner Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "Recrystallised Alumina Ceramic C-799 (799)",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: ' Outer Tube "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "15 X 10 (E)",
            value: "E",
          },
          {
            title: "17 X 13 (F)",
            value: "F",
          },
          {
            title: "20 X 15 (G)",
            value: "G",
          },
          {
            title: "24 X 18 (H)",
            value: "H",
          },
        ],
      },
      {
        label: "Outer Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "\n          Recrystallised Aluminum Ceramic(99.7%) C-799 – (799)\n        ",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Holding Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Holding Tube "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: ' Holding Tube "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Holding Tube length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Exposed Length "L2"(mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t805,
    product: "Noble Metal Thermocouples",
    title: "T - 805 THERMOCOUPLE WITH DOUBLE PROTECTION TUBE & ADJUSTABLE FLANGE",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 805 THERMOCOUPLE WITH DOUBLE PROTECTION TUBE & ADJUSTABLE FLANGE"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.40)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.50)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insulating Tube "OD" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø3.5 (3.5)",
            value: "3.5",
          },
          {
            title: "Ø4.5 (4.5)",
            value: "4.5",
          },
          {
            title: "Ø5.5 (5.5)",
            value: "5.5",
          },
          {
            title: "Ø8.5 (8.5)",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Insulating Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ceramic - 610 (610)",
            value: "610",
          },
          {
            title: "Ceramic - 710 (710)",
            value: "710",
          },
          {
            title: "Ceramic -799 (799)",
            value: "799",
          },
        ],
      },
      {
        label: 'Inner Tube "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "20x15 (E)",
            value: "E",
          },
          {
            title: "24x18 (F)",
            value: "F",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Inner Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "Recrystallised Alumina Ceramic C-799 (799)",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: ' Outer Tube "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "15 X 10 (E)",
            value: "E",
          },
          {
            title: "17 X 13 (F)",
            value: "F",
          },
          {
            title: "20 X 15 (G)",
            value: "G",
          },
          {
            title: "24 X 18 (H)",
            value: "H",
          },
        ],
      },
      {
        label: "Outer Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "\n          Recrystallised Aluminum Ceramic(99.7%) C-799 – (799)\n        ",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Holding Tube length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Exposed Length "L2"(mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t806,
    product: "Noble Metal Thermocouples",
    title: "T - 806 THERMOCOUPLE WITH SINGLE PROTECTION TUBE & ADJUSTABLE FLANGE",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 806 THERMOCOUPLE WITH SINGLE PROTECTION TUBE & ADJUSTABLE FLANGE"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.40)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.50)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insulating Tube "OD" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø3.5 (3.5)",
            value: "3.5",
          },
          {
            title: "Ø4.5 (4.5)",
            value: "4.5",
          },
          {
            title: "Ø5.5 (5.5)",
            value: "5.5",
          },
          {
            title: "Ø8.5 (8.5)",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Insulating Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ceramic - 610 (610)",
            value: "610",
          },
          {
            title: "Ceramic - 710 (710)",
            value: "710",
          },
          {
            title: "Ceramic -799 (799)",
            value: "799",
          },
        ],
      },
      {
        label: ' Outer Sheath "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "15 X 10 (E)",
            value: "E",
          },
          {
            title: "17 X 13 (F)",
            value: "F",
          },
          {
            title: "20 X 15 (G)",
            value: "G",
          },
          {
            title: "24 X 18 (H)",
            value: "H",
          },
        ],
      },
      {
        label: "Outer Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "\n          Recrystallised Aluminum Ceramic(99.7%) C-799 – (799)\n        ",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Holding Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Holding Tube "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: ' Holding Tube "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Holding Tube length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Exposed Length "L2"(mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t900,
    product: "Noble Metal Thermocouples",
    title: "T - 900 TRI-LEVEL THERMOCOUPLE WITH LONG PT THIMBLE",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 900 TRI-LEVEL THERMOCOUPLE WITH LONG PT THIMBLE"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.40)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.50)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insulating Tube "OD" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø3.5 (3.5)",
            value: "3.5",
          },
          {
            title: "Ø4.5 (4.5)",
            value: "4.5",
          },
          {
            title: "Ø5.5 (5.5)",
            value: "5.5",
          },
          {
            title: "Ø8.5 (8.5)",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Insulating Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ceramic - 610 (610)",
            value: "610",
          },
          {
            title: "Ceramic - 710 (710)",
            value: "710",
          },
          {
            title: "Ceramic -799 (799)",
            value: "799",
          },
        ],
      },
      {
        label: "Holding Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Holding Tube "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: ' Holding Tube "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Holding Tube length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Exposed Length "L2"(mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "610",
            value: "610",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Pt thimble OD (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "9.1",
            value: "9.1",
          },
          {
            title: "9.5",
            value: "9.5",
          },
          {
            title: "9.6",
            value: "9.6",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "11",
            value: "11",
          },
        ],
      },
      {
        label: "Wall Thickness (mm )",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "0.30",
            value: "0.30",
          },
          {
            title: "0.40",
            value: "0.40",
          },
          {
            title: "0.50",
            value: "0.50",
          },
        ],
      },
      {
        label: "MOC",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DPH Pt Thimble (A)",
            value: "A",
          },
          {
            title: "ZGS Pt Thimble (B)",
            value: "B",
          },
          {
            title: "Hardened Pt Thimble (C)",
            value: "C",
          },
          {
            title: "Pt Rh 90% 10% Thimble (D)",
            value: "D",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Element length from Tip (B,M,T)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "96-50-2",
            value: "96-50-2",
          },
          {
            title: "100-50-2",
            value: "100-50-2",
          },
          {
            title: "Consult factory(O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t901,
    product: "Noble Metal Thermocouples",
    title: "T - 901 TRI-LEVEL PT-RH THERMOCOUPLE WITH SMALL PT THIMBLE",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 901 TRI-LEVEL PT-RH THERMOCOUPLE WITH SMALL PT THIMBLE"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.40)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.50)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insulating Tube "OD" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø3.5 (3.5)",
            value: "3.5",
          },
          {
            title: "Ø4.5 (4.5)",
            value: "4.5",
          },
          {
            title: "Ø5.5 (5.5)",
            value: "5.5",
          },
          {
            title: "Ø8.5 (8.5)",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Insulating Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ceramic - 610 (610)",
            value: "610",
          },
          {
            title: "Ceramic - 710 (710)",
            value: "710",
          },
          {
            title: "Ceramic -799 (799)",
            value: "799",
          },
        ],
      },
      {
        label: "Inner Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "Recrystallised Alumina Ceramic C-799 (799)",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Inner Tube "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "20x15 (E)",
            value: "E",
          },
          {
            title: "24x18 (F)",
            value: "F",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: ' Outer Tube "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "15 X 10 (E)",
            value: "E",
          },
          {
            title: "17 X 13 (F)",
            value: "F",
          },
          {
            title: "20 X 15 (G)",
            value: "G",
          },
          {
            title: "24 X 18 (H)",
            value: "H",
          },
        ],
      },
      {
        label: "Outer Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "\n          Recrystallised Aluminum Ceramic(99.7%) C-799 – (799)\n        ",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Holding Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Holding Tube "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: ' Holding Tube "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Holding Tube length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Exposed Length "L2"(mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thimble length (TL) (mm )",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "30 mm (30)",
            value: "30",
          },
          {
            title: "35 mm (35)",
            value: "35",
          },
          {
            title: "40 mm (40)",
            value: "40",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Pt thimble OD (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "9.1",
            value: "9.1",
          },
          {
            title: "9.5",
            value: "9.5",
          },
          {
            title: "9.6",
            value: "9.6",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "11",
            value: "11",
          },
        ],
      },
      {
        label: "Wall Thickness (mm )",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "0.30",
            value: "0.30",
          },
          {
            title: "0.40",
            value: "0.40",
          },
          {
            title: "0.50",
            value: "0.50",
          },
        ],
      },
      {
        label: "MOC",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DPH Pt Thimble (A)",
            value: "A",
          },
          {
            title: "ZGS Pt Thimble (B)",
            value: "B",
          },
          {
            title: "Hardened Pt Thimble (C)",
            value: "C",
          },
          {
            title: "Pt Rh 90% 10% Thimble (D)",
            value: "D",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Element length from Tip (B,M,T)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "96-50-2",
            value: "96-50-2",
          },
          {
            title: "100-50-2",
            value: "100-50-2",
          },
          {
            title: "Consult factory(O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t902,
    product: "Noble Metal Thermocouples",
    title: "T - 902 SINGLE LEVEL THERMOCOUPLE WITH SMALL PT THIMBLE",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 902 SINGLE LEVEL THERMOCOUPLE WITH SMALL PT THIMBLE"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.40)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.50)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insulating Tube "OD" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø3.5 (3.5)",
            value: "3.5",
          },
          {
            title: "Ø4.5 (4.5)",
            value: "4.5",
          },
          {
            title: "Ø5.5 (5.5)",
            value: "5.5",
          },
          {
            title: "Ø8.5 (8.5)",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Insulating Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ceramic - 610 (610)",
            value: "610",
          },
          {
            title: "Ceramic - 710 (710)",
            value: "710",
          },
          {
            title: "Ceramic -799 (799)",
            value: "799",
          },
        ],
      },
      {
        label: "Inner Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "Recrystallised Alumina Ceramic C-799 (799)",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: ' Outer Tube "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "15 X 10 (E)",
            value: "E",
          },
          {
            title: "17 X 13 (F)",
            value: "F",
          },
          {
            title: "20 X 15 (G)",
            value: "G",
          },
          {
            title: "24 X 18 (H)",
            value: "H",
          },
        ],
      },
      {
        label: "Outer Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "\n          Recrystallised Aluminum Ceramic(99.7%) C-799 – (799)\n        ",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Holding Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Holding Tube "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: ' Holding Tube "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Holding Tube length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Exposed Length "L2"(mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thimble length (TL) (mm )",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "30 mm (30)",
            value: "30",
          },
          {
            title: "35 mm (35)",
            value: "35",
          },
          {
            title: "40 mm (40)",
            value: "40",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Pt thimble OD (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "9.1",
            value: "9.1",
          },
          {
            title: "9.5",
            value: "9.5",
          },
          {
            title: "9.6",
            value: "9.6",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "11",
            value: "11",
          },
        ],
      },
      {
        label: "Wall Thickness (mm )",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "0.30",
            value: "0.30",
          },
          {
            title: "0.40",
            value: "0.40",
          },
          {
            title: "0.50",
            value: "0.50",
          },
        ],
      },
      {
        label: "MOC",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DPH Pt Thimble (A)",
            value: "A",
          },
          {
            title: "ZGS Pt Thimble (B)",
            value: "B",
          },
          {
            title: "Hardened Pt Thimble (C)",
            value: "C",
          },
          {
            title: "Pt Rh 90% 10% Thimble (D)",
            value: "D",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t903,
    product: "Noble Metal Thermocouples",
    title: "T - 903 THERMOCOUPLE WITH OUTER-INNER PROTECTION TUBE & COUNTER FLANGE",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 903 THERMOCOUPLE WITH OUTER-INNER PROTECTION TUBE & COUNTER FLANGE"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "W5%Re - W26%Re (Simplex) (C)",
            value: "C",
          },
          {
            title: "W5%Re-W26%Re (Duplex) (CC)",
            value: "CC",
          },
          {
            title: "W3%Re - W25%Re (Simplex) (D)",
            value: "D",
          },
          {
            title: "W3%Re-W25%Re (Duplex) (DD)",
            value: "DD",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.40)",
            value: "0.40",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.50)",
            value: "0.50",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insulating Tube "OD" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø3.5 (3.5)",
            value: "3.5",
          },
          {
            title: "Ø4.5 (4.5)",
            value: "4.5",
          },
          {
            title: "Ø5.5 (5.5)",
            value: "5.5",
          },
          {
            title: "Ø8.5 (8.5)",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Insulating Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ceramic - 610 (610)",
            value: "610",
          },
          {
            title: "Ceramic - 710 (710)",
            value: "710",
          },
          {
            title: "Ceramic -799 (799)",
            value: "799",
          },
        ],
      },
      {
        label: 'Inner Tube "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "20x15 (E)",
            value: "E",
          },
          {
            title: "24x18 (F)",
            value: "F",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Inner Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "Recrystallised Alumina Ceramic C-799 (799)",
            value: "799",
          },
          {
            title: "Cast Iron (CS)",
            value: "CS",
          },
          {
            title: "Silcion Nitride (SN)",
            value: "SN",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Coating Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "PTFE Coating (PTFE)",
            value: "PTFE",
          },
          {
            title: "Ceramic Coating (Ceramic)",
            value: "Ceramic",
          },
          {
            title: "Tungsten Carbide Coating (TC)",
            value: "TC",
          },
          {
            title: "Sterlite Coating (STR)",
            value: "STR",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t904,
    product: "Noble Metal Thermocouples",
    title: "T - 904 GLASS LEVEL PROBE/ ELECTRODE",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 904 GLASS LEVEL PROBE/ ELECTRODE"
    )}`,
    data: [
      {
        label: "Element/Wire",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-Rh Alloy (A)",
            value: "A",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Element/Wire Dia",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "0.70 (A)",
            value: "A",
          },
          {
            title: "0.80 (B)",
            value: "B",
          },
          {
            title: "0.90 (C)",
            value: "C",
          },
          {
            title: "1.00 (D)",
            value: "D",
          },
        ],
      },
      {
        label: "Sensor Tip (OD) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "⌀3.20 mm(3.20),",
            value: "3.20",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: ' Outer Tube "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "15 X 10 (E)",
            value: "E",
          },
          {
            title: "17 X 13 (F)",
            value: "F",
          },
          {
            title: "20 X 15 (G)",
            value: "G",
          },
          {
            title: "24 X 18 (H)",
            value: "H",
          },
        ],
      },
      {
        label: "Outer Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "\n          Recrystallised Aluminum Ceramic(99.7%) C-799 – (799)\n        ",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Inner Tube "ODxID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "6x4 (A)",
            value: "A",
          },
          {
            title: "8x5 (B)",
            value: "B",
          },
          {
            title: "10x7 (C)",
            value: "C",
          },
          {
            title: "12x8 (D)",
            value: "D",
          },
          {
            title: "20x15 (E)",
            value: "E",
          },
          {
            title: "24x18 (F)",
            value: "F",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Inner Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 610 (610)\n        ",
            value: "610",
          },
          {
            title: "\n          Recrystallised Alumina Ceramic KER 710 (710)\n        ",
            value: "710",
          },
          {
            title: "Recrystallised Alumina Ceramic C-799 (799)",
            value: "799",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Holding Tube Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Holding Tube "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: ' Holding Tube "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Mounting Arrangement",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Required (A)",
            value: "A",
          },
          {
            title: "Not Required (B)",
            value: "B",
          },
        ],
      },
      {
        label: 'Holding Tube length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Outer Tube Length L2",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thimble length (TL) (mm )",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: "35",
            value: "35",
          },
          {
            title: "40",
            value: "40",
          },
          {
            title: "60",
            value: "60",
          },
          {
            title: "80",
            value: "80",
          },
          {
            title: "85",
            value: "85",
          },
          {
            title: "90",
            value: "90",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t988,
    product: "Noble Metal Thermocouples",
    title: "T - 988 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH CABLE",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 988 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH CABLE"
    )}`,
    data: [
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "W5%Re - W26%Re (Simplex) (C)",
            value: "C",
          },
          {
            title: "W5%Re-W26%Re (Duplex) (CC)",
            value: "CC",
          },
          {
            title: "W3%Re - W25%Re (Simplex) (D)",
            value: "D",
          },
          {
            title: "W3%Re-W25%Re (Duplex) (DD)",
            value: "DD",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.4)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.5)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material ultra high temperature",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Inconel -600 (I)",
            value: "I",
          },
          {
            title: "Platinam Alloy (P)",
            value: "P",
          },
          {
            title: "Tantalum (T)",
            value: "T",
          },
          {
            title: "Molybdenum (M)",
            value: "M",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter(mm) (Ultra High Temperature)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1.6",
            value: "1.6",
          },
          {
            title: "3.2",
            value: "3.2",
          },
          {
            title: "4.8",
            value: "4.8",
          },
          {
            title: "6.4",
            value: "6.4",
          },
          {
            title: "8",
            value: "8",
          },
        ],
      },
      {
        label: "Insulation Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Magnesium Oxide (MgO) (M)",
            value: "M",
          },
          {
            title: "Aluminium Oxide (Al2O3) (A)",
            value: "A",
          },
          {
            title: "Beryllium Oxide (BeO) (B)",
            value: "B",
          },
          {
            title: "Hafnium Oxide (HfO2) (H)",
            value: "H",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t989,
    product: "Noble Metal Thermocouples",
    title: "T - 989 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH LEMO CONNECTOR",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 989 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH LEMO CONNECTOR"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "M/F Lemo Connector (F)",
            value: "F",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "W5%Re - W26%Re (Simplex) (C)",
            value: "C",
          },
          {
            title: "W5%Re-W26%Re (Duplex) (CC)",
            value: "CC",
          },
          {
            title: "W3%Re - W25%Re (Simplex) (D)",
            value: "D",
          },
          {
            title: "W3%Re-W25%Re (Duplex) (DD)",
            value: "DD",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.4)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.5)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material ultra high temperature",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Inconel -600 (I)",
            value: "I",
          },
          {
            title: "Platinam Alloy (P)",
            value: "P",
          },
          {
            title: "Tantalum (T)",
            value: "T",
          },
          {
            title: "Molybdenum (M)",
            value: "M",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter(mm) (Ultra High Temperature)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1.6",
            value: "1.6",
          },
          {
            title: "3.2",
            value: "3.2",
          },
          {
            title: "4.8",
            value: "4.8",
          },
          {
            title: "6.4",
            value: "6.4",
          },
          {
            title: "8",
            value: "8",
          },
        ],
      },
      {
        label: "Insulation Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Magnesium Oxide (MgO) (M)",
            value: "M",
          },
          {
            title: "Aluminium Oxide (Al2O3) (A)",
            value: "A",
          },
          {
            title: "Beryllium Oxide (BeO) (B)",
            value: "B",
          },
          {
            title: "Hafnium Oxide (HfO2) (H)",
            value: "H",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t990,
    product: "Noble Metal Thermocouples",
    title: "T - 990 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH MALE FEMALE CONNECTOR",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T - 990 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH MALE FEMALE CONNECTOR"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Standard Plug/ Socket (A)",
            value: "A",
          },
          {
            title: "Miniature Plug/ Socket (B)",
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "W5%Re - W26%Re (Simplex) (C)",
            value: "C",
          },
          {
            title: "W5%Re-W26%Re (Duplex) (CC)",
            value: "CC",
          },
          {
            title: "W3%Re - W25%Re (Simplex) (D)",
            value: "D",
          },
          {
            title: "W3%Re-W25%Re (Duplex) (DD)",
            value: "DD",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.4)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.5)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material ultra high temperature",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Inconel -600 (I)",
            value: "I",
          },
          {
            title: "Platinam Alloy (P)",
            value: "P",
          },
          {
            title: "Tantalum (T)",
            value: "T",
          },
          {
            title: "Molybdenum (M)",
            value: "M",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter(mm) (Ultra High Temperature)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1.6",
            value: "1.6",
          },
          {
            title: "3.2",
            value: "3.2",
          },
          {
            title: "4.8",
            value: "4.8",
          },
          {
            title: "6.4",
            value: "6.4",
          },
          {
            title: "8",
            value: "8",
          },
        ],
      },
      {
        label: "Insulation Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Magnesium Oxide (MgO) (M)",
            value: "M",
          },
          {
            title: "Aluminium Oxide (Al2O3) (A)",
            value: "A",
          },
          {
            title: "Beryllium Oxide (BeO) (B)",
            value: "B",
          },
          {
            title: "Hafnium Oxide (HfO2) (H)",
            value: "H",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images3.t991,
    product: "Noble Metal Thermocouples",
    title: "T 991 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH TRANSITION JOINT",
    path: `/products/${slugify("Noble Metal Thermocouples")}/${slugify(
      "T 991 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH TRANSITION JOINT"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Standard Plug/ Socket (A)",
            value: "A",
          },
          {
            title: "Miniature Plug/ Socket (B)",
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "W5%Re - W26%Re (Simplex) (C)",
            value: "C",
          },
          {
            title: "W5%Re-W26%Re (Duplex) (CC)",
            value: "CC",
          },
          {
            title: "W3%Re - W25%Re (Simplex) (D)",
            value: "D",
          },
          {
            title: "W3%Re-W25%Re (Duplex) (DD)",
            value: "DD",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.4)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.5)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material ultra high temperature",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Inconel -600 (I)",
            value: "I",
          },
          {
            title: "Platinam Alloy (P)",
            value: "P",
          },
          {
            title: "Tantalum (T)",
            value: "T",
          },
          {
            title: "Molybdenum (M)",
            value: "M",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter(mm) (Ultra High Temperature)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1.6",
            value: "1.6",
          },
          {
            title: "3.2",
            value: "3.2",
          },
          {
            title: "4.8",
            value: "4.8",
          },
          {
            title: "6.4",
            value: "6.4",
          },
          {
            title: "8",
            value: "8",
          },
        ],
      },
      {
        label: "Insulation Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Magnesium Oxide (MgO) (M)",
            value: "M",
          },
          {
            title: "Aluminium Oxide (Al2O3) (A)",
            value: "A",
          },
          {
            title: "Beryllium Oxide (BeO) (B)",
            value: "B",
          },
          {
            title: "Hafnium Oxide (HfO2) (H)",
            value: "H",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
];
