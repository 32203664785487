/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import PropTypes from "prop-types";

import { slugify } from "utils/functions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Modal, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { textToVar } from "utils/functions";
import MKButton from "components/MKButton";
import { CheckCircle, Close } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
function OptionConfigurator({ title, product, data, image }) {
  const ref = useRef();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Please enter a valid email").required("Email is required"),
    company: Yup.string().required("Company Name is required"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    quantity: Yup.number().required("Quantity is required"),
  });
  let initialObj = {};

  for (let i = 0; i < data.length; i++) {
    initialObj[textToVar(data[i].label)] = "";
  }

  const [selectedValues, setSelectedValues] = useState(initialObj);
  const [activateProductCode, setActivateProductCode] = useState(false);

  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLaoding] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState("");

  const handleSelect = (e, label) => {
    setError("");
    setSelectedValues((prev) => {
      return { ...prev, [textToVar(label)]: e.target.value };
    });
  };

  const handleGettingQuote = () => {
    for (let i = 0; i < data.length; i++) {
      if (!Object.values(selectedValues)[i]) {
        console.log(i, Object.values(selectedValues)[i]);
        setError("*Select All options to get a quote");
        return;
      }
    }
    setError("");
    setActivateProductCode(true);
    setModalOpen(true);
  };

  useEffect(() => emailjs.init(process.env.REACT_APP_PUBLIC_KEY), []);

  const sendMail = async ({ name, email, company, phone, address, quantity, message }) => {
    try {
      setLaoding(true);
      await emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, {
        name,
        email,
        company,
        phone,
        address,
        quantity,
        message,
        code: data.map((curr) => selectedValues[textToVar(curr.label)]).join("-"),
      });
      setEmailSuccess("Email sent successfully!");
    } catch (error) {
      console.log(error);
      setEmailError("Something went wrong! Please try again");
    } finally {
      setLaoding(false);
    }
  };

  return (
    <BaseLayout
      title={`${title} Configurator`}
      breadcrumb={[
        { label: "Home", route: "/" },
        { label: product, route: `/products/${slugify(product)}` },
        { label: title },
      ]}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "4rem", margin: "120px 0" }}>
        <div style={{ flex: 1 }}>
          <img
            src={image}
            alt={image}
            style={{ width: "100%", height: "auto", transform: "rotate(90deg)" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: "20px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <p style={{ marginBottom: "20px" }}>
            Model Configurator (Select all options to get a quote)
          </p>
          {data.map((curr, idx) => {
            return (
              <FormControl variant="standard" key={idx} sx={{ m: 1, minWidth: 120, mb: 2 }}>
                <InputLabel id={`${idx}-${curr.label}`}>{curr.label}</InputLabel>
                <Select
                  labelId={`${idx}-${curr.label}`}
                  id={`select-${idx}-${curr.label}`}
                  value={selectedValues[textToVar(curr.label)]}
                  onChange={(e) => handleSelect(e, curr.label)}
                  label={curr.label}
                >
                  {curr.options.map((curr, idx) => {
                    return (
                      <MenuItem value={curr.value} key={idx}>
                        {curr.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            );
          })}
          <span style={{ color: "red", fontSize: "14px" }}>{error}</span>
          {activateProductCode && (
            <p style={{ fontSize: "14px", margin: "12px 0" }}>
              Your Product Code:{" "}
              <span style={{ color: "#666" }}>
                {data.map((curr) => selectedValues[textToVar(curr.label)]).join("-")}
              </span>
            </p>
          )}
          <MKButton
            variant="gradient"
            color="info"
            size="small"
            component="a"
            // href="https://www.creative-tim.com/product/material-kit-react"
            sx={{ mb: 2 }}
            onClick={handleGettingQuote}
          >
            GET QUOTE
          </MKButton>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(true)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "40%",
              height: "95%",
              borderRadius: "4px",
              padding: "20px",
              position: "relative",
            }}
          >
            <Close
              style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
              onClick={() => setModalOpen(false)}
            />

            <h1 style={{ fontSize: "20px", textAlign: "center" }}>
              Submit your details for a quote
            </h1>
            <p style={{ fontSize: "14px", margin: "12px 0" }}>
              Your Product Code:{" "}
              <span style={{ color: "#666" }}>
                {data.map((curr) => selectedValues[textToVar(curr.label)]).join("-")}
              </span>
            </p>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "24px" }}>Sending Mail...</p>
              </div>
            ) : emailSuccess ? (
              emailSuccess && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <CheckCircle style={{ fontSize: "100px", color: "green", marginRight: "12px" }} />
                  <h2 style={{ color: "green" }}>{emailSuccess}</h2>{" "}
                </div>
              )
            ) : (
              <Formik
                innerRef={ref}
                initialValues={{
                  name: "",
                  email: "",
                  company: "",
                  phone: "",
                  address: "",
                  quantity: "",
                  message: "",
                }}
                onSubmit={(values) => {
                  sendMail(values);
                }}
                validationSchema={validationSchema}
              >
                {({ values, errors, handleSubmit, handleChange, touched }) => {
                  return (
                    <form
                      onSubmit={handleSubmit}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        margin: "20px 0",
                      }}
                    >
                      <TextField
                        id="standard-basic"
                        label="Name*"
                        variant="standard"
                        placeholder="Johndoe"
                        onChange={handleChange("name")}
                        value={values.name}
                      />

                      {touched.name && errors.name && (
                        <p style={{ color: "red", fontSize: "14px" }}>{errors.name}</p>
                      )}
                      <TextField
                        id="standard-basic"
                        label="Email*"
                        variant="standard"
                        placeholder="Johndoe@gmail.com"
                        onChange={handleChange("email")}
                        value={values.email}
                      />

                      {touched.email && errors.email && (
                        <p style={{ color: "red", fontSize: "14px" }}>{errors.email}</p>
                      )}

                      <TextField
                        id="standard-basic"
                        label="Company*"
                        variant="standard"
                        placeholder="Johndoe@gmail.com"
                        onChange={handleChange("company")}
                        value={values.company}
                      />

                      {touched.company && errors.company && (
                        <p style={{ color: "red", fontSize: "14px" }}>{errors.company}</p>
                      )}

                      <TextField
                        id="standard-basic"
                        label="Phone"
                        variant="standard"
                        placeholder=""
                        onChange={handleChange("phone")}
                        value={values.phone}
                      />

                      {touched.phone && errors.phone && (
                        <p style={{ color: "red", fontSize: "14px" }}>{errors.phone}</p>
                      )}

                      <TextField
                        id="standard-basic"
                        label="Address"
                        variant="standard"
                        placeholder=""
                        onChange={handleChange("address")}
                        value={values.address}
                      />

                      {touched.address && errors.address && (
                        <p style={{ color: "red", fontSize: "14px" }}>{errors.address}</p>
                      )}

                      <TextField
                        id="standard-basic"
                        label="Quantity*"
                        variant="standard"
                        placeholder=""
                        onChange={handleChange("quantity")}
                        value={values.quantity}
                      />

                      {touched.quantity && errors.quantity && (
                        <p style={{ color: "red", fontSize: "14px" }}>{errors.quantity}</p>
                      )}

                      <TextField
                        id="standard-basic"
                        label="Message"
                        variant="standard"
                        placeholder=""
                        onChange={handleChange("message")}
                        value={values.message}
                        multiline
                      />

                      {touched.message && errors.message && (
                        <p style={{ color: "red", fontSize: "14px" }}>{errors.message}</p>
                      )}

                      <MKButton
                        onClick={handleSubmit}
                        type="submit"
                        variant="gradient"
                        color="info"
                        size="large"
                        component="a"
                        // href="https://www.creative-tim.com/product/material-kit-react"
                        sx={{ mb: 2 }}
                      >
                        GET QUOTE
                      </MKButton>

                      {emailError && <p style={{ color: "red", fontSize: "14px" }}>{emailError}</p>}
                    </form>
                  );
                }}
              </Formik>
            )}
          </div>
        </div>
      </Modal>
    </BaseLayout>
  );
}

export default OptionConfigurator;

OptionConfigurator.propTypes = {
  data: PropTypes.any,
  title: PropTypes.any,
  product: PropTypes.any,
  image: PropTypes.any,
};
