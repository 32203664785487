/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { PhotoProvider, PhotoView } from "react-photo-view";

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import Image from "components/Image/Image";

function PageHeaders({
  title,
  banner,
  additionalBanners,
  descriptionParagraphs = [],
  features = [],
  specifications = [],
  note = "",
  importantNote = "",
  model = { label: "", image: "" },
  modelTable,
  modelTableHeading,
  exampleTable,
  exampleHeading,
  cableTables,
  cableTablesHeading,
}) {
  console.log(features);
  return (
    <BaseLayout breadcrumb={[{ label: "Home", route: "/" }, { label: title }]}>
      <h1 style={{ fontSize: "24px", marginBottom: "3rem" }}>{title}</h1>
      <div>
        <PhotoProvider maskOpacity={0.8}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginBottom: "3rem",
            }}
          >
            <PhotoView key={0} src={banner}>
              <Image
                src={banner}
                alt={title}
                style={{
                  width: "200px",
                  transform: "rotate(90deg)",
                }}
              />
            </PhotoView>
            {additionalBanners.map((curr, i) => {
              return (
                <PhotoView key={i + 1} src={curr}>
                  <Image
                    src={curr}
                    alt={`title-${i}`}
                    style={{
                      width: "200px",
                      transform: "rotate(90deg)",
                    }}
                  />
                </PhotoView>
              );
            })}
          </div>
        </PhotoProvider>
        <ul style={{ margin: "20px 0" }}>
          {descriptionParagraphs.map((curr, i) => {
            return (
              <li
                key={i}
                style={{
                  fontSize: "16px",
                  fontWeight: "300",
                  lineHeight: "1.6",
                  marginBottom: "10px",
                }}
              >
                {curr}
              </li>
            );
          })}
        </ul>
        <h2 style={{ fontSize: "20px", marginBottom: "1rem", marginTop: "3rem" }}>Main Features</h2>
        <ThermometerFeatures features={features} />
        <h2 style={{ fontSize: "20px", marginBottom: "1rem", marginTop: "3rem" }}>Specification</h2>
        <ThermometerSpecifications specifications={specifications} />
        <p style={{ fontSize: "16px", margin: "20px 0" }}>
          <span style={{ fontWeight: "500" }}>Note*:</span> {note}
        </p>
        <p
          style={{
            color: "red",
            textAlign: "center",
            padding: "20px",
            border: "1px solid #ddd",
            marginBottom: "3rem",
          }}
        >
          {importantNote}
        </p>
      </div>
      <h2 style={{ fontSize: "20px", marginBottom: "1rem", marginTop: "3rem" }}>{model.label}</h2>
      <PhotoProvider maskOpacity={0.8}>
        <PhotoView key={title} src={model.image}>
          <Image
            src={model.image}
            alt={model.label}
            size={"1000px"}
            style={{ border: "0px", boxShadow: "none", borderRadius: "0px" }}
          />
        </PhotoView>
      </PhotoProvider>
      <p
        style={{
          fontSize: "20px",
          marginBottom: "1rem",
          marginTop: "3rem",
          color: "red",
          textAlign: "center",
        }}
      >
        {modelTableHeading}
      </p>

      {modelTable}
      <p
        style={{
          fontSize: "20px",
          marginBottom: "1rem",
          marginTop: "3rem",
          color: "red",
          textAlign: "center",
        }}
      >
        {exampleHeading}
      </p>

      {exampleTable}
      <p
        style={{
          fontSize: "20px",
          marginBottom: "1rem",
          marginTop: "3rem",
          color: "red",
          textAlign: "center",
        }}
      >
        {cableTablesHeading}
      </p>
      {cableTables.map((curr, i) => {
        return (
          <div style={{ marginBottom: "20px" }} key={i}>
            {curr}
          </div>
        );
      })}
    </BaseLayout>
  );
}

function ThermometerFeatures({ features = [] }) {
  return (
    <div className="thermometer-features" style={{ marginBottom: "2rem" }}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {features.map((description, index) => (
          <div key={index} style={cardStyle}>
            <p style={descriptionStyle}>{description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const cardStyle = {
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  width: "300px",
  border: "1px solid #ddd",
};

const descriptionStyle = {
  fontSize: "16px",
  lineHeight: "1.6",
  marginBottom: "0",
};
function ThermometerSpecifications({ specifications = [] }) {
  return (
    <div className="thermometer-specifications">
      <div style={{ display: "flex", gap: "20px" }}>
        <div style={columnStyle}>
          <table style={tableStyle}>
            <tbody>
              {specifications.map(
                (spec, index) =>
                  index % 2 === 0 && (
                    <tr key={index}>
                      <td style={{ ...cellStyle, ...labelCellStyle }}>{spec.label}</td>
                      <td style={{ ...cellStyle, ...valueCellStyle }}>{spec.value}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
        <div style={columnStyle}>
          <table style={tableStyle}>
            <tbody>
              {specifications.map(
                (spec, index) =>
                  index % 2 !== 0 && (
                    <tr key={index}>
                      <td style={{ ...cellStyle, ...labelCellStyle }}>{spec.label}</td>
                      <td style={{ ...cellStyle, ...valueCellStyle }}>{spec.value}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const columnStyle = {
  flex: "1",
};

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
};

const cellStyle = {
  padding: "10px",
  borderBottom: "1px solid #ddd",
  fontSize: "14px",
};

const labelCellStyle = {
  backgroundColor: "#F0F8FF", // Light blue
};

const valueCellStyle = {
  backgroundColor: "#FFF0F5", // Light pink
};
ThermometerSpecifications.propTypes = {
  specifications: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

ThermometerFeatures.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string),
};

PageHeaders.propTypes = {
  title: PropTypes.string.isRequired,
  banner: PropTypes.any,
  descriptionParagraphs: PropTypes.any,
  features: PropTypes.any,
  note: PropTypes.any,
  importantNote: PropTypes.any,
  specifications: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  model: PropTypes.any,
  modelTable: PropTypes.any,
  modelTableHeading: PropTypes.any,
  exampleTable: PropTypes.any,
  exampleHeading: PropTypes.any,
  cableTables: PropTypes.any,
  cableTablesHeading: PropTypes.any,
  additionalBanners: PropTypes.any,
};

export default PageHeaders;
