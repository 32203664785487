/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import BasicTable from "./components/Table/Table";
import BasicTabs from "./components/Tabs/Tabs";
import MKButton from "components/MKButton";
// import View from "layouts/sections/components/View";

// PageHeaders page components
// import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";

// PageHeaders page components code
// import headerOneCode from "layouts/sections/page-sections/page-headers/components/HeaderOne/code";

function PageHeaders({
  component = <></>,
  title,
  banner,
  description,
  tabs,
  rows = [],
  rows2 = [],
  buttons = [],
  rowsHead,
  rows2Head,
  images = [],
}) {
  return (
    <BaseLayout title={title} breadcrumb={[{ label: "Home", route: "/" }, { label: title }]}>
      {/* <View title="Header 1" code={headerOneCode} height="40rem">
        <HeaderOne />
      </View> */}
      <div
        style={{
          width: "100%",
          maxHeight: "300px",
          overflow: "hidden",
          backgroundColor: "rgb(240,242,245)",
          borderRadius: "4px",
          height: "200px",
        }}
      >
        <img src={banner} alt="" style={{ width: "100%", backgroundSize: "cover" }} />
      </div>
      <p>{description}</p>
      {rows.length === 0 ? null : (
        <div style={{ margin: "20px 0" }}>
          <BasicTable rows={rows} headDirection={rowsHead} />
        </div>
      )}
      {images.map((curr, i) => {
        return (
          <div key={i} style={{ width: "50%", margin: "100px 0" }}>
            <img
              src={curr.src}
              alt={curr.title}
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </div>
        );
      })}
      {
        <div style={{ margin: "20px 0" }}>
          <BasicTable rows={rows2} headDirection={rows2Head} />
          {buttons.map((curr, i) => {
            return (
              <MKButton
                key={i}
                variant="gradient"
                color="info"
                size="medium"
                // href="https://www.creative-tim.com/product/material-kit-react"
                sx={{ mb: 2, mt: 1, mr: 6, width: 200 }}
              >
                {curr.title}
              </MKButton>
            );
          })}
        </div>
      }

      {tabs.length === 0 ? null : (
        <>
          <h2>Models</h2>
          <BasicTabs tabs={tabs} key={`${title}`} />
        </>
      )}
      <>{component}</>
    </BaseLayout>
  );
}

PageHeaders.propTypes = {
  title: PropTypes.string.isRequired,
  banner: PropTypes.any,
  tabs: PropTypes.any,
  rows: PropTypes.any,
  rows2: PropTypes.any,
  rowsHead: PropTypes.any,
  rows2Head: PropTypes.any,
  description: PropTypes.string.isRequired,
  buttons: PropTypes.any,
  images: PropTypes.any,
  component: PropTypes.any,
};

export default PageHeaders;
