import { slugify } from "./functions";

import * as images2 from "../assets/images/cables/2/index";

export const THERMOCOUPLES_2 = [
  {
    image: images2.t103,
    product: "MI Thermocouples",
    title: "T-103 THERMOCOUPLE WITH HEAD & TIP ASSEMBLY",
    path: `/products/${slugify("MI Thermocouples")}/${slugify(
      "T-103 THERMOCOUPLE WITH HEAD & TIP ASSEMBLY"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Tip Length "TL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: 'Tip Dia. "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t104,
    product: "MI Thermocouples",
    title: "T - 104 THERMOCOUPLE WITH HEAD AND WELD PAD",
    path: `/products/${slugify("MI Thermocouples")}/${slugify(
      "T - 104 THERMOCOUPLE WITH HEAD AND WELD PAD"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Weld Pad",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "25X25X50mm Adj (A)",
            value: "A",
          },
          {
            title: "35X25X15mm Adj (B)",
            value: "B",
          },
          {
            title: "25X25X5mm Fixed (C)",
            value: "C",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Weld Pad Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (A)",
            value: "A",
          },
          {
            title: "SS310 (B)",
            value: "B",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t274,
    product: "MI Thermocouples",
    title: "T - 274 THERMOCOUPLE WITH JUNCTION BOX & ADJUSTABLE COMPENSATION FITTING WITH STUB",
    path: `/products/${slugify("MI Thermocouples")}/${slugify(
      "T - 274 THERMOCOUPLE WITH JUNCTION BOX & ADJUSTABLE COMPENSATION FITTING WITH STUB"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT )',
            value: "1/4NPT-",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory Other)",
            value: "Consult-Factory-Other)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Stub length "T" (mm )',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t345,
    product: "MI Thermocouples",
    title: "T - 345 THERMOCOUPLE INSET",
    path: `/products/${slugify("MI Thermocouples")}/${slugify("T - 345 THERMOCOUPLE INSET")}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Terminal &amp; Ceramic Block (Spring Loaded) (D)\n        ",
            value: "D",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t344,
    product: "MI Thermocouples",
    title: "T - 344 THERMOCOUPLE INSET WITH FITTING",
    path: `/products/${slugify("MI Thermocouples")}/${slugify(
      "T - 344 THERMOCOUPLE INSET WITH FITTING"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "\n          Terminal &amp; Ceramic Block (Spring Loaded) (D)\n        ",
            value: "D",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t105,
    product: "MI Thermocouples",
    title: "T - 105 THERMOCOUPLE WITH NIPPLE",
    path: `/products/${slugify("MI Thermocouples")}/${slugify("T - 105 THERMOCOUPLE WITH NIPPLE")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t106,
    product: "MI Thermocouples",
    title: "T - 106 THERMOCOUPLE WITH NIPPLE UNION NIPPLE",
    path: `/products/${slugify("MI Thermocouples")}/${slugify(
      "T - 106 THERMOCOUPLE WITH NIPPLE UNION NIPPLE"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box (JB)",
            value: "JB",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SCH-80 (A)",
            value: "A",
          },
          {
            title: "SCH-40 (B)",
            value: "B",
          },
          {
            title: "SCH-160 (C)",
            value: "C",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT )',
            value: "1/4NPT-",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory Other)",
            value: "Consult-Factory-Other)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t101,
    product: "MI Thermocouples",
    title: "T - 101 THERMOCOUPLE WITH HEAD",
    path: `/products/${slugify("MI Thermocouples")}/${slugify("T - 101 THERMOCOUPLE WITH HEAD")}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t203,
    product: "MI Thermocouples",
    title: "T-203 THERMOCOUPLE WITH BOYONET & CABLE",
    path: `/products/${slugify("MI Thermocouples")}/${slugify(
      "T-203 THERMOCOUPLE WITH BOYONET & CABLE"
    )}`,
    data: [
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: 'Spring length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "75",
            value: "75",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t204,
    product: "MI Thermocouples",
    title: "T - 204 THERMOCOUPLE WITH CABLE & FITTING (SPRING LOADED)",
    path: `/products/${slugify("MI Thermocouples")}/${slugify(
      "T - 204 THERMOCOUPLE WITH CABLE & FITTING (SPRING LOADED)"
    )}`,
    data: [
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Weld Pad",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "25X25X50mm Adj (A)",
            value: "A",
          },
          {
            title: "35X25X15mm Adj (B)",
            value: "B",
          },
          {
            title: "25X25X5mm Fixed (C)",
            value: "C",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Weld Pad Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (A)",
            value: "A",
          },
          {
            title: "SS310 (B)",
            value: "B",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t200,
    product: "MI Thermocouples",
    title: "T - 200 THERMOCOUPLE WITH CABLE (MINERAL INSULATED)",
    path: `/products/${slugify("MI Thermocouples")}/${slugify(
      "T - 200 THERMOCOUPLE WITH CABLE (MINERAL INSULATED)"
    )}`,
    data: [
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t272,
    product: "MI Thermocouples",
    title: "T-272 THERMOCOUPLE (PROBE) WITH CABLE & CONNECTOR",
    path: `/products/${slugify("MI Thermocouples")}/${slugify(
      "T-272 THERMOCOUPLE (PROBE) WITH CABLE & CONNECTOR"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Standard Plug/ Socket (A)",
            value: "A",
          },
          {
            title: "Miniature Plug/ Socket (B)",
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images2.t343,
    product: "MI Thermocouples",
    title: "T - 343 THERMOCOUPLE WITH CONNECTOR",
    path: `/products/${slugify("MI Thermocouples")}/${slugify(
      "T - 343 THERMOCOUPLE WITH CONNECTOR"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Standard Plug/ Socket (A)",
            value: "A",
          },
          {
            title: "Miniature Plug/ Socket (B)",
            value: "B",
          },
          {
            title: "3-Pin BSM Connector (C)",
            value: "C",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Grounded (GN)",
            value: "GN",
          },
          {
            title: "Ungrounded (UN)",
            value: "UN",
          },
        ],
      },
      {
        label: "Thermocouple Element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Chromel-Alumel (Simplex) (K)",
            value: "K",
          },
          {
            title: "Chromel-Alumel (Duplex) (KK)",
            value: "KK",
          },
          {
            title: "Iron-Constantan (Simplex) (J)",
            value: "J",
          },
          {
            title: "Iron-Constantan (Duplex) (JJ)",
            value: "JJ",
          },
          {
            title: "Nicrosil-Nisil (Simplex) (N)",
            value: "N",
          },
          {
            title: "Nicrosil-Nisil (Duplex) (NN)",
            value: "NN",
          },
          {
            title: "Copper-Constantan (Simplex) (T)",
            value: "T",
          },
          {
            title: "Copper-Constantan (Duplex) (TT)",
            value: "TT",
          },
          {
            title: "Chormel - Constantan (Simplex) (E)",
            value: "E",
          },
          {
            title: "Chormel - Constantan (Duplex) (EE)",
            value: "EE",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
];
