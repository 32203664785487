/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { slugify } from "utils/functions";

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
// import Icon from "@mui/material/Icon";

// @mui icons

// Pages
// import AboutUs from "layouts/pages/landing-pages/about-us";
// import ContactUs from "layouts/pages/landing-pages/contact-us";
// import Author from "layouts/pages/landing-pages/author";
// import SignIn from "layouts/pages/authentication/sign-in";

// Sections
// import PageHeaders from "layouts/sections/page-sections/page-headers";
// import Features from "layouts/sections/page-sections/featuers";
// import Navbars from "layouts/sections/navigation/navbars";
// import NavTabs from "layouts/sections/navigation/nav-tabs";
// import Inputs from "layouts/sections/input-areas/inputs";
// import Forms from "layouts/sections/input-areas/forms";
// import Alerts from "layouts/sections/attention-catchers/alerts";
// import Modals from "layouts/sections/attention-catchers/modals";

const routes = [
  {
    name: "About Us",
    // icon: <Icon>dashboard</Icon>,
    // columns: 1,
    // rowsPerColumn: 2,
    // collapse: [
    //   {
    //     name: "landing pages",
    //     collapse: [
    //       {
    //         name: "about us",
    //         route: "/pages/landing-pages/about-us",
    //         component: <AboutUs />,
    //       },
    //       {
    //         name: "contact us",
    //         route: "/pages/landing-pages/contact-us",
    //         component: <ContactUs />,
    //       },
    //       {
    //         name: "author",
    //         route: "/pages/landing-pages/author",
    //         component: <Author />,
    //       },
    //     ],
    //   },
    //   {
    //     name: "account",
    //     collapse: [
    //       {
    //         name: "sign in",
    //         route: "/pages/authentication/sign-in",
    //         component: <SignIn />,
    //       },
    //     ],
    //   },
    // ],
  },
  {
    name: "Products",
    // icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "Stator Resistance Thermometers",
        dropdown: true,

        collapse: [
          {
            name: "Simplex (Single element) stator winding RTDs",
            route: `/products/${slugify("Simplex stator winding RTDs")}`,
            // component: <Navbars />,
          },
          {
            name: "Duplex (Double element) stator winding RTDs",
            route: `/products/${slugify("Duplex stator winding RTDs")}`,
            // component: <NavTabs />,
          },
        ],
      },
      {
        name: "Contact Temperature Sensors",
        dropdown: true,

        collapse: [
          {
            name: "Thermocouples",
            // route: "/product",
            // description: "222",
            // dropdown: true,
            showSubColumns: true,
            subMenu: [
              {
                name: "Base Metal Thermocouples With Thermowells / Protection Tubes",
                route: `/products/${slugify(
                  "Base Metal Thermocouples With Thermowells / Protection Tubes"
                )}`,
              },
              {
                name: "MI Thermocouples",
                route: `/products/${slugify("MI Thermocouples")}`,
              },
              {
                name: "Noble Metal Thermocouples",
                route: `/products/${slugify("Noble Metal Thermocouples")}`,
              },

              {
                name: "Refractory Thermocouples",
                route: `/products/${slugify("Refractory Thermocouples")}`,
              },
              {
                name: "Special Thermocouple Types",
                route: `/products/${slugify("Special Thermocouple Types")}`,
              },
            ],
          },
          {
            name: "Resistance Temperature Detector",
            // route: "/sections/page-sections/features",
            // component: <Features />,
            showSubColumns: true,
            subMenu: [
              {
                name: "RTDs with Thermowells / Protection Tubes",
                route: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}`,
              },

              {
                name: "Mineral Insulated RTDs",
                route: `/products/${slugify("Mineral Insulated RTDs")}`,
              },
              {
                name: "Special Resistance Temperature Detectors",
                route: `/products/${slugify("Special Resistance Temperature Detectors")}`,
              },
            ],
          },
          {
            name: "Thermowells",
            route: `/products/${slugify("Thermowells")}`,
            // component: <Features />,
          },
          {
            name: "Accessories",
            // route: "/sections/page-sections/features",
            // component: <Features />,
            showSubColumns: true,
            subMenu: [
              {
                name: "Protection Tubes",
                route: `/products/${slugify("Protection Tubes")}`,
              },

              {
                name: "Compression Fittings",
                route: `/products/${slugify("Compression Fittings")}`,
              },
              {
                name: "Precision Pipe Fittings",
                route: `/products/${slugify("Precision Pipe Fittings")}`,
              },
              {
                name: "Flanges",
                route: `/products/${slugify("Flanges")}`,
              },
              {
                name: "Boyonet Caps",
                route: `/products/${slugify("Boyonet Caps")}`,
              },
              {
                name: "Seal Pots & Accessories",
                route: `/products/${slugify("Seal Pots & Accessories")}`,
              },
              {
                name: "Thermocouple Connectors",
                route: "/products/Base-Metal-Thermocouples-With Thermowells-Protection-Tubes",
              },
            ],
          },
        ],
      },
      {
        name: "Cables and Wires",
        dropdown: true,
        collapse: [
          {
            name: "Thermocouple Cables",
            route: `/products/${slugify("Thermocouple Cables")}`,
            // component: <Navbars />,
          },
          {
            name: "RTD Cables",
            route: `/products/${slugify("RTD Cables")}`,
            // component: <NavTabs />,
          },
        ],
      },
      {
        name: "Mineral Insulated Cables",
        dropdown: true,
        collapse: [
          {
            name: "Mineral Insulated Thermocouple Cable",
            route: `/products/${slugify("Mineral Insulated Thermocouple Cable")}`,
            // component: <Inputs />,
          },
          {
            name: "Mineral Insulated RTD Cable",
            route: `/products/${slugify("Mineral Insulated RTD Cables")}`,
            // component: <Forms />,
          },
        ],
      },
      {
        name: "Nickel and Thermocouple Alloy",
        dropdown: true,
        collapse: [
          {
            name: "Thermocouple Conductor",
            route: `/products/${slugify("Thermocouple Conductor")}`,
            // component: <Alerts />,
          },
          {
            name: "Extension/Compensating Conductor",
            route: `/products/${slugify("Extension/Compensating Conductor")}`,
            // component: <Modals />,
          },
        ],
      },
    ],
  },
  {
    name: "Industries",
    // icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Glass Industry",
        // description: "All about overview, quick start, license and contents",
        // href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-kit/",
      },
      {
        name: "Cement Industry",
        // description: "See our colors, icons and typography",
        // href: "https://www.creative-tim.com/learning-lab/react/colors/material-kit/",
      },
      {
        name: "Steel Industry",
        // description: "Explore our collection of fully designed components",
        // href: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
      },
      {
        name: "Petrochemical Industry",
        // description: "Check how you can integrate our plugins",
        // href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-kit/",
      },
      {
        name: "Pharmaceutical Industry",
        // description: "Check how you can integrate our plugins",
        // href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-kit/",
      },
    ],
  },
  {
    name: "Contact Us",
    // icon: <GitHubIcon />,
    // href: "https://www.github.com/creativetimofficial/material-kit-react",
  },
];

export default routes;
