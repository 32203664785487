import { slugify } from "./functions";

import * as images6 from "../assets/images/cables/6/index";

export const THERMOCOUPLES_6 = [
  {
    image: images6.r501,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 501 RTD with Thermowell & Adjustable Fitting`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 501 RTD with Thermowell & Adjustable Fitting"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r502,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 502 RTD with Thermowell & Adjustable Flange`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 502 RTD with Thermowell & Adjustable Flange"
    )}`,
    data: [],
  },
  {
    image: images6.r503,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 503 RTD with Taper Thermowell & Adjustable Flange`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 503 RTD with Taper Thermowell & Adjustable Flange"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (1.4401) (316)",
            value: "316",
          },
          {
            title: "SS310 (1.4845) (310)",
            value: "310",
          },
          {
            title: "Inconel-600 (2.4816) (600)",
            value: "600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r504,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 504 RTD with Straight Thermowell Adjustable Comp. Fitting & Cable`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 504 RTD with Straight Thermowell Adjustable Comp. Fitting & Cable"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r650,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 504 RTD with Straight Thermowell Adjustable Comp. Fitting & Cable`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 504 RTD with Straight Thermowell Adjustable Comp. Fitting & Cable"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r651,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 651 RTD with N/U/N & Taper Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 651 RTD with N/U/N & Taper Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r652,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 652 RTD with Nipple & Straight Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 652 RTD with Nipple & Straight Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r653,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 653 RTD with N/U/N & Straight Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 653 RTD with N/U/N & Straight Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r654,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 654 RTD with Straight Thermowell & Adjustable Comp. Fitting`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 654 RTD with Straight Thermowell & Adjustable Comp. Fitting"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r655,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 655 RTD with Nipple & Straight Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 655 RTD with Nipple & Straight Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r726,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 726 RTD with Nipple & Taper Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 726 RTD with Nipple & Taper Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8.5",
            value: "8.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r727,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 727 RTD with N/U/N & Taper Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 727 RTD with N/U/N & Taper Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "10 (10)",
            value: "10",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160 )',
            value: "1SCH160-",
          },
          {
            title: '1-1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 60 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (48.26) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 60 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 60 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 120 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Min. 5 mm (Min. 5 mm)",
            value: "Min.-5-mm",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r728,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 728 RTD with Tapered Thermowell & Adjustable Comp. Fitting`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 728 RTD with Tapered Thermowell & Adjustable Comp. Fitting"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "10 (10)",
            value: "10",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160 )',
            value: "1SCH160-",
          },
          {
            title: '1-1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 60 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (48.26) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 60 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 60 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 120 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Min. 5 mm (Min. 5 mm)",
            value: "Min.-5-mm",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r729,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 729 RTD with Nipple & Straight Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 729 RTD with Nipple & Straight Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r730,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 730 RTD with N/U/N & Straight Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 730 RTD with N/U/N & Straight Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r731,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 731 RTD with Straight Thermowell & Flange`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 731 RTD with Straight Thermowell & Flange"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r732,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 732 RTD with Flange N/U/N & stepped Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 732 RTD with Flange N/U/N & stepped Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "10 (10)",
            value: "10",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160 )',
            value: "1SCH160-",
          },
          {
            title: '1-1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 60 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (48.26) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 60 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 60 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 120 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Min. 5 mm (Min. 5 mm)",
            value: "Min.-5-mm",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Tip Length "TL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r734,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 734 RTD with N/U/N & Coated Taper Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 734 RTD with N/U/N & Coated Taper Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "10 (10)",
            value: "10",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160 )',
            value: "1SCH160-",
          },
          {
            title: '1-1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 60 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (48.26) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 60 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 60 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 120 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "OD2" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Min. 5 mm (Min. 5 mm)",
            value: "Min.-5-mm",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Tapered Length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Outer Coating",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "PTFE",
            value: "PTFE",
          },
          {
            title: "PFA",
            value: "PFA",
          },
          {
            title: "PVDF",
            value: "PVDF",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images6.r735,
    product: "RTDs with Thermowells / Protection Tubes",
    title: `R - 735 RTD with N/U/N & Coated Taper Thermowell`,
    path: `/products/${slugify("RTDs with Thermowells / Protection Tubes")}/${slugify(
      "R - 735 RTD with N/U/N & Coated Taper Thermowell"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermowell Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Inconel 600 (600)",
            value: "600",
          },
          {
            title: "Inconel 800 (800)",
            value: "800",
          },
          {
            title: "HRS 446 (446)",
            value: "446",
          },
          {
            title: "Solid Sintered Tungsten Carbide (SSTC)",
            value: "SSTC",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Thermowell "OD" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "14",
            value: "14",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "18",
            value: "18",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: "25",
            value: "25",
          },
          {
            title: "28",
            value: "28",
          },
          {
            title: "30",
            value: "30",
          },
          {
            title: '1/4"SCH 10 (13.72) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (13.72) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (13.72) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (17.15) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (17.15) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (17.15) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (21.34) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (21.34) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (21.34) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (21.34) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (26.67) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (26.67) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (26.67) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (26.67) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (33.40) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (33.40) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (33.40) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (33.40) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1 1/4"SCH 10 (42.16) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1 1/4"SCH 40 (42.16) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1 1/4"SCH 80 (42.16) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1 1/4"SCH 160 (42.16) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1 1/2"SCH 40 (48.26) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1 1/2"SCH 60 (48.26) (1-1/2SCH 60)',
            value: "1-1/2SCH-60",
          },
          {
            title: '1 1/2"SCH 120 (48.26) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1 1/2"SCH 160 (48.26) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (73.03) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (73.03) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (73.03) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (73.03) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: 'Thermowell "ID" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "5",
            value: "5",
          },
          {
            title: "7",
            value: "7",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "10",
            value: "10",
          },
          {
            title: "13",
            value: "13",
          },
          {
            title: "12",
            value: "12",
          },
          {
            title: "16",
            value: "16",
          },
          {
            title: "19",
            value: "19",
          },
          {
            title: "22",
            value: "22",
          },
          {
            title: '1/4"SCH 10 (10.42) (1/4SCH10)',
            value: "1/4SCH10",
          },
          {
            title: '1/4"SCH 40 (9.24) (1/4SCH40)',
            value: "1/4SCH40",
          },
          {
            title: '1/4"SCH 80 (7.68) (1/4SCH80)',
            value: "1/4SCH80",
          },
          {
            title: '3/8"SCH 10 (13.85) (3/8SCH10)',
            value: "3/8SCH10",
          },
          {
            title: '3/8"SCH 40 (12.53) (3/8SCH40)',
            value: "3/8SCH40",
          },
          {
            title: '3/8"SCH 80 (10.75) (3/8SCH80)',
            value: "3/8SCH80",
          },
          {
            title: '1/2"SCH 10 (17.12) (1/2SCH10)',
            value: "1/2SCH10",
          },
          {
            title: '1/2"SCH 40 (15.80) (1/2SCH40)',
            value: "1/2SCH40",
          },
          {
            title: '1/2"SCH 80 (13.88) (1/2SCH80)',
            value: "1/2SCH80",
          },
          {
            title: '1/2"SCH 160 (11.84) (1/2SCH160)',
            value: "1/2SCH160",
          },
          {
            title: '3/4"SCH 10 (22.45) (3/4SCH40)',
            value: "3/4SCH40",
          },
          {
            title: '3/4"SCH 40 (20.93) (3/4SCH60)',
            value: "3/4SCH60",
          },
          {
            title: '3/4"SCH 80 (18.85) (3/4SCH120)',
            value: "3/4SCH120",
          },
          {
            title: '3/4"SCH 160 (15.59) (3/4SCH160)',
            value: "3/4SCH160",
          },
          {
            title: '1"SCH 10 (27.86) (1SCH40)',
            value: "1SCH40",
          },
          {
            title: '1"SCH 40 (26.64) (1SCH60)',
            value: "1SCH60",
          },
          {
            title: '1"SCH 80 (24.30) (1SCH120)',
            value: "1SCH120",
          },
          {
            title: '1"SCH 160 (20.70) (1SCH160)',
            value: "1SCH160",
          },
          {
            title: '1-1/4"SCH 10 (36.62) (1-1/4SCH40)',
            value: "1-1/4SCH40",
          },
          {
            title: '1-1/4"SCH 40 (35.04) (1-1/4SCH60)',
            value: "1-1/4SCH60",
          },
          {
            title: '1-1/4"SCH 80 (32.46) (1-1/4SCH120)',
            value: "1-1/4SCH120",
          },
          {
            title: '1-1/4"SCH 160 (29.46) (1-1/4SCH160)',
            value: "1-1/4SCH160",
          },
          {
            title: '1-1/2"SCH 10 (42.72) (1-1/2SCH40)',
            value: "1-1/2SCH40",
          },
          {
            title: '1-1/2"SCH 40 (40.90) (1-1/2SCH60)',
            value: "1-1/2SCH60",
          },
          {
            title: '1-1/2"SCH 80 (38.10) (1-1/2SCH120)',
            value: "1-1/2SCH120",
          },
          {
            title: '1-1/2"SCH 160 (33.98) (1-1/2SCH160)',
            value: "1-1/2SCH160",
          },
          {
            title: '2"SCH 10 (54.79) (2SCH10)',
            value: "2SCH10",
          },
          {
            title: '2"SCH 40 (52.51) (2SCH40)',
            value: "2SCH40",
          },
          {
            title: '2"SCH 80 (49.25) (2SCH80)',
            value: "2SCH80",
          },
          {
            title: '2"SCH 160 (42.91) (2SCH160)',
            value: "2SCH160",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Length of "T" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "20",
            value: "20",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection (Flange)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2" 150# RF (1/2-150#)',
            value: "1/2-150#",
          },
          {
            title: '1/2" 300# RF (1/2-300#)',
            value: "1/2-300#",
          },
          {
            title: '1/2" 400# RF (1/2-400#)',
            value: "1/2-400#",
          },
          {
            title: '1/2" 600# RF (1/2-600#)',
            value: "1/2-600#",
          },
          {
            title: '3/4" 150# RF (3/4-150#)',
            value: "3/4-150#",
          },
          {
            title: '3/4" 300# RF (3/4-300#)',
            value: "3/4-300#",
          },
          {
            title: '3/4" 400# RF (3/4-400#)',
            value: "3/4-400#",
          },
          {
            title: '3/4" 600# RF (3/4-600#)',
            value: "3/4-600#",
          },
          {
            title: '1" 150# RF (1-150#)',
            value: "1-150#",
          },
          {
            title: '1" 300# RF (1-300#)',
            value: "1-300#",
          },
          {
            title: '1" 400# RF (1-400#)',
            value: "1-400#",
          },
          {
            title: '1" 600# RF (1-600#)',
            value: "1-600#",
          },
          {
            title: '1 1/2" 150# RF (1-1/2-150#)',
            value: "1-1/2-150#",
          },
          {
            title: '1 1/2" 300# RF (1-1/2-300#)',
            value: "1-1/2-300#",
          },
          {
            title: '1 1/2" 400# RF (1-1/2-400#)',
            value: "1-1/2-400#",
          },
          {
            title: '1 1/2" 600# RF (1-1/2-600#)',
            value: "1-1/2-600#",
          },
          {
            title: '1 1/4" 150# RF (1-1/4-150#)',
            value: "1-1/4-150#",
          },
          {
            title: '1 1/4" 300# RF (1-1/4-300#)',
            value: "1-1/4-300#",
          },
          {
            title: '1 1/4" 400# RF (1-1/4-400#)',
            value: "1-1/4-400#",
          },
          {
            title: '1 1/4" 600# RF (1-1/4-600#)',
            value: "1-1/4-600#",
          },
          {
            title: '2" 150# RF (2-150#)',
            value: "2-150#",
          },
          {
            title: '2" 300# RF (2-300#)',
            value: "2-300#",
          },
          {
            title: '2" 400# RF (2-400#)',
            value: "2-400#",
          },
          {
            title: '2" 600# RF (2-600#)',
            value: "2-600#",
          },
          {
            title: "Standard Adj flange P.C.D 70 mm - (PCD70)",
            value: "PCD70",
          },
          {
            title: "Standard Adj flange PCD 90 mm -(PCD90)",
            value: "PCD90",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Flange Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "SS310 (310)",
            value: "310",
          },
          {
            title: "SS304 (304)",
            value: "304",
          },
          {
            title: "Cast Steel (CS)",
            value: "CS",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Instrument Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Insertion Length "IL" (mm) ',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "230",
            value: "230",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "255",
            value: "255",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Outer Cladding",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Hestelloy (A)",
            value: "A",
          },
          {
            title: "Titanium (B)",
            value: "B",
          },
          {
            title: "Tantalum (C)",
            value: "C",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
];
