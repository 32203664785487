import { slugify } from "./functions";

import * as images7 from "../assets/images/cables/7/index";

export const THERMOCOUPLES_7 = [
  {
    image: images7.r103,
    product: "Mineral Insulated RTDs",
    title: `R - 103 Standard RTD with Head & Tip Assembly`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
      "R - 103 Standard RTD with Head & Tip Assembly"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: 'Tip Length "TL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: 'Tip Dia. "OD1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1",
            value: "1",
          },
          {
            title: "1.5",
            value: "1.5",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images7.r104,
    product: "Mineral Insulated RTDs",
    title: `R - 104 RTD With Head & Weld Pad`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
      "R - 104 RTD With Head & Weld Pad"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Weld Pad",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "25X25X50mm Adj (A)",
            value: "A",
          },
          {
            title: "35X25X15mm Adj (B)",
            value: "B",
          },
          {
            title: "25X25X5mm Fixed (C)",
            value: "C",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Weld Pad Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (A)",
            value: "A",
          },
          {
            title: "SS310 (B)",
            value: "B",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images7.r105,
    product: "Mineral Insulated RTDs",
    title: `R - 105 RTD with Nipple`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify("R - 105 RTD with Nipple")}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nipple (mm) ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images7.r106,
    product: "Mineral Insulated RTDs",
    title: `R - 106 RTD with Nipple Union Nipple`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
      "R - 106 RTD with Nipple Union Nipple"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Die Cast Aluminium A Type (A)",
            value: "A",
          },
          {
            title: "Die Cast Aluminium B Type (B)",
            value: "B",
          },
          {
            title: "Die Cast Aluminium IP-67 (Single Entry) (C1)",
            value: "C1",
          },
          {
            title: "Die Cast Aluminium IP-67 (Double Entry) (C2)",
            value: "C2",
          },
          {
            title: "SS Head (SS)",
            value: "SS",
          },
          {
            title: "Miniature Die Cast Aluminium (M)",
            value: "M",
          },
          {
            title: "Plastic (P)",
            value: "P",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB1)\n        ",
            value: "EB1",
          },
          {
            title: "\n          SS Explosion/ Flame Proof IIB (Zone1 &amp; 2) (EB2)\n        ",
            value: "EB2",
          },
          {
            title: "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) (EC1)\n        ",
            value: "EC1",
          },
          {
            title: "SS Explosion/ Flame Proof IIC (T6) (EC2)",
            value: "EC2",
          },
          {
            title:
              "\n          Die Cast Aluminium Explosion/ Flame Proof IIC (T6) ATEX (EC3)\n        ",
            value: "EC3",
          },
          {
            title: "Junction Box(JB)",
            value: "JB",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "N-U-N",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/2"SCH 80 (A)',
            value: "A",
          },
          {
            title: '1/2"SCH 40 (B)',
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Process Connection",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
          {
            title: "Not Applicable (NA)",
            value: "NA",
          },
        ],
      },
      {
        label: 'Extension Length "EL" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images7.r200,
    product: "Mineral Insulated RTDs",
    title: `R - 200 RTD with Cable (Mineral Insulated)`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
      "R - 200 RTD with Cable (Mineral Insulated)"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images7.r201,
    product: "Mineral Insulated RTDs",
    title: `R - 201 RTD with Cable and Adjustable Comp. Fitting`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
      "R - 201 RTD with Cable and Adjustable Comp. Fitting"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images7.r203,
    product: "Mineral Insulated RTDs",
    title: `R - 203 RTD with Cable & Bayo Nut`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
      "R - 203 RTD with Cable & Bayo Nut"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: 'Spring length "L1" (mm)',
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "75",
            value: "75",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images7.r204,
    product: "Mineral Insulated RTDs",
    title: `R - 204 RTD with Cable & Fitting (Spring Loaded)`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
      "R - 204 RTD with Cable & Fitting (Spring Loaded)"
    )}`,
    data: [],
  },
  {
    image: images7.r206,
    product: "Mineral Insulated RTDs",
    title: `R - 206 RTD with Weld Pad & Cable`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
      "R - 206 RTD with Weld Pad & Cable"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Weld Pad",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "25X25X50mm Adj (A)",
            value: "A",
          },
          {
            title: "35X25X15mm Adj (B)",
            value: "B",
          },
          {
            title: "25X25X5mm Fixed (C)",
            value: "C",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Weld Pad Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (A)",
            value: "A",
          },
          {
            title: "SS310 (B)",
            value: "B",
          },
          {
            title: "Inconel 600",
            value: "Inconel-600",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images7.r271,
    product: "Mineral Insulated RTDs",
    title: `R - 271 RTD with Cable & Connector`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
      "R - 271 RTD with Cable & Connector"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Standard Plug/ Socket (A)",
            value: "A",
          },
          {
            title: "Miniature Plug/ Socket (B)",
            value: "B",
          },
          {
            title: "3-Pin BSM Connector (C)",
            value: "C",
          },
          {
            title: "\n          Terminal &amp; Ceramic Block (Spring Loaded) (D)\n        ",
            value: "D",
          },
          {
            title: "XLR Connector (E)",
            value: "E",
          },
          {
            title: "M/F Lemo Connector (F)",
            value: "F",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images7.r272,
    product: "Mineral Insulated RTDs",
    title: `R - 272 RTD (Probe) with Cable & Connector`,
    path: `/products/${slugify("Mineral Insulated RTDs")}/${slugify(
      "R - 272 RTD (Probe) with Cable & Connector"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Standard Plug/ Socket (A)",
            value: "A",
          },
          {
            title: "Miniature Plug/ Socket (B)",
            value: "B",
          },
          {
            title: "3-Pin BSM Connector (C)",
            value: "C",
          },
          {
            title: "\n          Terminal &amp; Ceramic Block (Spring Loaded) (D)\n        ",
            value: "D",
          },
          {
            title: "XLR Connector (E)",
            value: "E",
          },
          {
            title: "M/F Lemo Connector (F)",
            value: "F",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "RTD Sheath Outer Diameter (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "3",
            value: "3",
          },
          {
            title: "4.5",
            value: "4.5",
          },
          {
            title: "6",
            value: "6",
          },
          {
            title: "8",
            value: "8",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Fitting",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: '1/4" BSP (1/4BSP)',
            value: "1/4BSP",
          },
          {
            title: '1/4" NPT (1/4NPT)',
            value: "1/4NPT",
          },
          {
            title: '1/2" BSP (1/2BSP)',
            value: "1/2BSP",
          },
          {
            title: '1/2" NPT (1/2NPT)',
            value: "1/2NPT",
          },
          {
            title: '3/8" BSP (3/8BSP)',
            value: "3/8BSP",
          },
          {
            title: '3/8" NPT (3/8NPT)',
            value: "3/8NPT",
          },
          {
            title: '3/4" BSP (3/4BSP)',
            value: "3/4BSP",
          },
          {
            title: '3/4" NPT (3/4NPT)',
            value: "3/4NPT",
          },
          {
            title: '1" BSP (1BSP)',
            value: "1BSP",
          },
          {
            title: '1" NPT (1NPT)',
            value: "1NPT",
          },
          {
            title: '1 1/4" BSP (1-1/4BSP)',
            value: "1-1/4BSP",
          },
          {
            title: '1 1/4" NPT (1-1/4NPT)',
            value: "1-1/4NPT",
          },
          {
            title: '1 1/2" BSP (1-1/2BSP)',
            value: "1-1/2BSP",
          },
          {
            title: '1 1/2" NPT (1-1/2NPT)',
            value: "1-1/2NPT",
          },
          {
            title: '2" BSP (2BSP)',
            value: "2BSP",
          },
          {
            title: '2" NPT (2NPT)',
            value: "2NPT",
          },
          {
            title: "M20X1.5 (M20X1.5)",
            value: "M20X1.5",
          },
          {
            title: "M18X1.5 (M18X1.5)",
            value: "M18X1.5",
          },
          {
            title: "M33X2 (M33X2)",
            value: "M33X2",
          },
          {
            title: "M27X2 (M27X2)",
            value: "M27X2",
          },
          {
            title: "Consult Factory O)",
            value: "Consult-Factory-O)",
          },
          {
            title: "Not Applicable NA)",
            value: "Not-Applicable-NA)",
          },
        ],
      },
      {
        label: "RTD Nominal Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
];
