import { slugify } from "./functions";

import * as images4 from "../assets/images/cables/4/index";

export const THERMOCOUPLES_4 = [
  {
    image: images4.t988,
    product: "Refractory Thermocouples",
    title: "T - 988 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH CABLE",
    path: `/products/${slugify("Refractory Thermocouples")}/${slugify(
      "T - 988 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH CABLE"
    )}`,
    data: [
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "W5%Re - W26%Re (Simplex) (C)",
            value: "C",
          },
          {
            title: "W5%Re-W26%Re (Duplex) (CC)",
            value: "CC",
          },
          {
            title: "W3%Re - W25%Re (Simplex) (D)",
            value: "D",
          },
          {
            title: "W3%Re-W25%Re (Duplex) (DD)",
            value: "DD",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.4)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.5)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material ultra high temperature",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Inconel -600 (I)",
            value: "I",
          },
          {
            title: "Platinam Alloy (P)",
            value: "P",
          },
          {
            title: "Tantalum (T)",
            value: "T",
          },
          {
            title: "Molybdenum (M)",
            value: "M",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter(mm) (Ultra High Temperature)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1.6",
            value: "1.6",
          },
          {
            title: "3.2",
            value: "3.2",
          },
          {
            title: "4.8",
            value: "4.8",
          },
          {
            title: "6.4",
            value: "6.4",
          },
          {
            title: "8",
            value: "8",
          },
        ],
      },
      {
        label: "Insulation Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Magnesium Oxide (MgO) (M)",
            value: "M",
          },
          {
            title: "Aluminium Oxide (Al2O3) (A)",
            value: "A",
          },
          {
            title: "Beryllium Oxide (BeO) (B)",
            value: "B",
          },
          {
            title: "Hafnium Oxide (HfO2) (H)",
            value: "H",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images4.t989,
    product: "Refractory Thermocouples",
    title: "T - 989 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH LEMO CONNECTOR",
    path: `/products/${slugify("Refractory Thermocouples")}/${slugify(
      "T - 989 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH LEMO CONNECTOR"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "M/F Lemo Connector (F)",
            value: "F",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "W5%Re - W26%Re (Simplex) (C)",
            value: "C",
          },
          {
            title: "W5%Re-W26%Re (Duplex) (CC)",
            value: "CC",
          },
          {
            title: "W3%Re - W25%Re (Simplex) (D)",
            value: "D",
          },
          {
            title: "W3%Re-W25%Re (Duplex) (DD)",
            value: "DD",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.4)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.5)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material ultra high temperature",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Inconel -600 (I)",
            value: "I",
          },
          {
            title: "Platinam Alloy (P)",
            value: "P",
          },
          {
            title: "Tantalum (T)",
            value: "T",
          },
          {
            title: "Molybdenum (M)",
            value: "M",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter(mm) (Ultra High Temperature)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1.6",
            value: "1.6",
          },
          {
            title: "3.2",
            value: "3.2",
          },
          {
            title: "4.8",
            value: "4.8",
          },
          {
            title: "6.4",
            value: "6.4",
          },
          {
            title: "8",
            value: "8",
          },
        ],
      },
      {
        label: "Insulation Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Magnesium Oxide (MgO) (M)",
            value: "M",
          },
          {
            title: "Aluminium Oxide (Al2O3) (A)",
            value: "A",
          },
          {
            title: "Beryllium Oxide (BeO) (B)",
            value: "B",
          },
          {
            title: "Hafnium Oxide (HfO2) (H)",
            value: "H",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images4.t990,
    product: "Refractory Thermocouples",
    title: "T - 990 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH MALE FEMALE CONNECTOR",
    path: `/products/${slugify("Refractory Thermocouples")}/${slugify(
      "T - 990 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH MALE FEMALE CONNECTOR"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Standard Plug/ Socket (A)",
            value: "A",
          },
          {
            title: "Miniature Plug/ Socket (B)",
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "W5%Re - W26%Re (Simplex) (C)",
            value: "C",
          },
          {
            title: "W5%Re-W26%Re (Duplex) (CC)",
            value: "CC",
          },
          {
            title: "W3%Re - W25%Re (Simplex) (D)",
            value: "D",
          },
          {
            title: "W3%Re-W25%Re (Duplex) (DD)",
            value: "DD",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.4)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.5)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material ultra high temperature",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Inconel -600 (I)",
            value: "I",
          },
          {
            title: "Platinam Alloy (P)",
            value: "P",
          },
          {
            title: "Tantalum (T)",
            value: "T",
          },
          {
            title: "Molybdenum (M)",
            value: "M",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter(mm) (Ultra High Temperature)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1.6",
            value: "1.6",
          },
          {
            title: "3.2",
            value: "3.2",
          },
          {
            title: "4.8",
            value: "4.8",
          },
          {
            title: "6.4",
            value: "6.4",
          },
          {
            title: "8",
            value: "8",
          },
        ],
      },
      {
        label: "Insulation Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Magnesium Oxide (MgO) (M)",
            value: "M",
          },
          {
            title: "Aluminium Oxide (Al2O3) (A)",
            value: "A",
          },
          {
            title: "Beryllium Oxide (BeO) (B)",
            value: "B",
          },
          {
            title: "Hafnium Oxide (HfO2) (H)",
            value: "H",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images4.t991,
    product: "Refractory Thermocouples",
    title: "T 991 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH TRANSITION JOINT",
    path: `/products/${slugify("Refractory Thermocouples")}/${slugify(
      "T 991 ULTRA HIGH TEMPERATURE THERMOCOUPLE WITH TRANSITION JOINT"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Standard Plug/ Socket (A)",
            value: "A",
          },
          {
            title: "Miniature Plug/ Socket (B)",
            value: "B",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "W5%Re - W26%Re (Simplex) (C)",
            value: "C",
          },
          {
            title: "W5%Re-W26%Re (Duplex) (CC)",
            value: "CC",
          },
          {
            title: "W3%Re - W25%Re (Simplex) (D)",
            value: "D",
          },
          {
            title: "W3%Re-W25%Re (Duplex) (DD)",
            value: "DD",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.4)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.5)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material ultra high temperature",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Inconel -600 (I)",
            value: "I",
          },
          {
            title: "Platinam Alloy (P)",
            value: "P",
          },
          {
            title: "Tantalum (T)",
            value: "T",
          },
          {
            title: "Molybdenum (M)",
            value: "M",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter(mm) (Ultra High Temperature)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1.6",
            value: "1.6",
          },
          {
            title: "3.2",
            value: "3.2",
          },
          {
            title: "4.8",
            value: "4.8",
          },
          {
            title: "6.4",
            value: "6.4",
          },
          {
            title: "8",
            value: "8",
          },
        ],
      },
      {
        label: "Insulation Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Magnesium Oxide (MgO) (M)",
            value: "M",
          },
          {
            title: "Aluminium Oxide (Al2O3) (A)",
            value: "A",
          },
          {
            title: "Beryllium Oxide (BeO) (B)",
            value: "B",
          },
          {
            title: "Hafnium Oxide (HfO2) (H)",
            value: "H",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
  {
    image: images4.t992,
    product: "Refractory Thermocouples",
    title: "T - 992 ULTRA HIGH TEMPERATURE THERMOCOUPLE, TRANSITION JOINT WITH LEMO CONNECTOR",
    path: `/products/${slugify("Refractory Thermocouples")}/${slugify(
      "T - 992 ULTRA HIGH TEMPERATURE THERMOCOUPLE, TRANSITION JOINT WITH LEMO CONNECTOR"
    )}`,
    data: [
      {
        label: "Termination Style (Connector)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "M/F Lemo Connector (F)",
            value: "F",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Thermocouple Element (Special)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "W5%Re - W26%Re (Simplex) (C)",
            value: "C",
          },
          {
            title: "W5%Re-W26%Re (Duplex) (CC)",
            value: "CC",
          },
          {
            title: "W3%Re - W25%Re (Simplex) (D)",
            value: "D",
          },
          {
            title: "W3%Re-W25%Re (Duplex) (DD)",
            value: "DD",
          },
          {
            title: "Pt-Rh 13% Pt (Simplex) (R)",
            value: "R",
          },
          {
            title: "Pt-Rh 13% Pt (Duplex) (RR)",
            value: "RR",
          },
          {
            title: "Pt-Rh 10% Pt (Simplex) (S)",
            value: "S",
          },
          {
            title: "Pt-Rh 10% Pt (Duplex) (SS)",
            value: "SS",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Simplex) (B)",
            value: "B",
          },
          {
            title: "Pt-Rh 30% Pt-Rh 6% (Duplex) (BB)",
            value: "BB",
          },
        ],
      },
      {
        label: "Element Dia. (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Ø0.35 (0.35)",
            value: "0.35",
          },
          {
            title: "Ø0.40 (0.4)",
            value: "0.4",
          },
          {
            title: "Ø0.45 (0.45)",
            value: "0.45",
          },
          {
            title: "Ø0.50 (0.5)",
            value: "0.5",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Sheath Material ultra high temperature",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Inconel -600 (I)",
            value: "I",
          },
          {
            title: "Platinam Alloy (P)",
            value: "P",
          },
          {
            title: "Tantalum (T)",
            value: "T",
          },
          {
            title: "Molybdenum (M)",
            value: "M",
          },
        ],
      },
      {
        label: "Sheath Outer Diameter(mm) (Ultra High Temperature)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "1.6",
            value: "1.6",
          },
          {
            title: "3.2",
            value: "3.2",
          },
          {
            title: "4.8",
            value: "4.8",
          },
          {
            title: "6.4",
            value: "6.4",
          },
          {
            title: "8",
            value: "8",
          },
        ],
      },
      {
        label: "Insulation Material ",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Magnesium Oxide (MgO) (M)",
            value: "M",
          },
          {
            title: "Aluminium Oxide (Al2O3) (A)",
            value: "A",
          },
          {
            title: "Beryllium Oxide (BeO) (B)",
            value: "B",
          },
          {
            title: "Hafnium Oxide (HfO2) (H)",
            value: "H",
          },
          {
            title: "Consult Factory (Other)",
            value: "Other",
          },
        ],
      },
      {
        label: "Nominal Length(mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "50",
            value: "50",
          },
          {
            title: "100",
            value: "100",
          },
          {
            title: "150",
            value: "150",
          },
          {
            title: "200",
            value: "200",
          },
          {
            title: "250",
            value: "250",
          },
          {
            title: "300",
            value: "300",
          },
          {
            title: "350",
            value: "350",
          },
          {
            title: "400",
            value: "400",
          },
          {
            title: "450",
            value: "450",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "550",
            value: "550",
          },
          {
            title: "600",
            value: "600",
          },
          {
            title: "650",
            value: "650",
          },
          {
            title: "700",
            value: "700",
          },
          {
            title: "750",
            value: "750",
          },
          {
            title: "800",
            value: "800",
          },
          {
            title: "850",
            value: "850",
          },
          {
            title: "900",
            value: "900",
          },
          {
            title: "950",
            value: "950",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1050",
            value: "1050",
          },
          {
            title: "1100",
            value: "1100",
          },
          {
            title: "1150",
            value: "1150",
          },
          {
            title: "1200",
            value: "1200",
          },
          {
            title: "1250",
            value: "1250",
          },
          {
            title: "1300",
            value: "1300",
          },
          {
            title: "1350",
            value: "1350",
          },
          {
            title: "1400",
            value: "1400",
          },
          {
            title: "1450",
            value: "1450",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "ANSI MC96.1 (A)",
            value: "A",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
];
