import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// import pdfFile from "assets/document.pdf"; // Adjust the path as needed

import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function App() {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const renderPages = () => {
    const pages = [];
    const scale = window.innerWidth < 768 ? 0.5 : 1; // Adjust scale threshold as needed
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {i}
          </div>
          <Page key={i} pageNumber={i} scale={scale} />
        </div>
      );
    }
    return pages;
  };

  return (
    <div
      style={{
        width: "100vw",
        maxWidth: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Document
        file={window.location.origin + "/document.pdf"}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {numPages && renderPages()}
      </Document>
    </div>
  );
}

export default App;
