import { slugify } from "./functions";

import * as images8 from "../assets/images/cables/8/index";

export const THERMOCOUPLES_8 = [
  {
    image: images8.r800,
    product: "Special Resistance Temperature Detectors",
    title: `R - 800 Slide Shoe RTD with Bakelite Cup & 'U' Clamp`,
    path: `/products/${slugify("Special Resistance Temperature Detectors")}/${slugify(
      "R - 800 Slide Shoe RTD with Bakelite Cup & 'U' Clamp"
    )}`,
    data: [
      {
        label: "RTD element",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Pt-100X1 Simplex (1)",
            value: "1",
          },
          {
            title: "Pt-100X2 Duplex (2)",
            value: "2",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Sheath Material",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "SS316 (316)",
            value: "316",
          },
          {
            title: "Consult factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Junction Style",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "2-wire (W2)",
            value: "W2",
          },
          {
            title: "3-wire (W3)",
            value: "W3",
          },
          {
            title: "4-wire (W4)",
            value: "W4",
          },
        ],
      },
      {
        label: "Cable Length (mm)",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "500",
            value: "500",
          },
          {
            title: "1000",
            value: "1000",
          },
          {
            title: "1500",
            value: "1500",
          },
          {
            title: "2000",
            value: "2000",
          },
          {
            title: "2500",
            value: "2500",
          },
          {
            title: "3000",
            value: "3000",
          },
          {
            title: "3500",
            value: "3500",
          },
          {
            title: "4000",
            value: "4000",
          },
          {
            title: "4500",
            value: "4500",
          },
          {
            title: "5000",
            value: "5000",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "Cable",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "Teflon/Teflon (A)",
            value: "A",
          },
          {
            title: "Teflon/Teflon/SS Braided (B)",
            value: "B",
          },
          {
            title: "FG/FG Fibre Glass Insulated (C)",
            value: "C",
          },
          {
            title: "PVC/PVC (D)",
            value: "D",
          },
          {
            title: "FG/FG/SS Braided (E)",
            value: "E",
          },
          {
            title: "Silicon/Silicon (F)",
            value: "F",
          },
          {
            title: "Teflon/Silicon (G)",
            value: "G",
          },
          {
            title: "Teflon/FG (H)",
            value: "H",
          },
          {
            title: "Teflon/FG/SS Braided (I)",
            value: "I",
          },
          {
            title: "Consult Factory (O)",
            value: "O",
          },
        ],
      },
      {
        label: "RTD Calibration Standard",
        options: [
          {
            title: "Choose an Option...",
            value: "",
          },
          {
            title: "DIN (D)",
            value: "D",
          },
          {
            title: "IEC (I)",
            value: "I",
          },
        ],
      },
    ],
  },
];
