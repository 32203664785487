/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import "react-photo-view/dist/react-photo-view.css";

// Material Kit 2 React routes
// import routes from "routes";
import PageHeaders from "layouts/sections/page-sections/page-headers";
import { PRODUCT_ROUTES } from "utils/constants";
import OptionConfigurator from "layouts/sections/page-sections/featuers";
import { THERMOCOUPLES_1 } from "utils/thermocouples_1";
import { THERMOCOUPLES_2 } from "utils/thermocouples_2";
import { THERMOCOUPLES_3 } from "utils/thermocouples_3";
import { THERMOCOUPLES_4 } from "utils/thermocouples_4";
import { THERMOCOUPLES_5 } from "utils/thermocouples_5";
import { THERMOCOUPLES_6 } from "utils/thermocouples_6";
import { THERMOCOUPLES_7 } from "utils/thermocouples_7";
import { THERMOCOUPLES_8 } from "utils/thermocouples_8";
import { slugify } from "utils/functions";
import { PRODUCT_ROUTES_2 } from "utils/constants";
import PageHeaders2 from "layouts/sections/page-sections/page-headers/PageHeaders2";
import PDFViewer from "./pages/PDFViewer";

export default function App() {
  const THERMOCOUPLES = [
    THERMOCOUPLES_1,
    THERMOCOUPLES_2,
    THERMOCOUPLES_3,
    THERMOCOUPLES_4,
    THERMOCOUPLES_5,
    THERMOCOUPLES_6,
    THERMOCOUPLES_7,
    THERMOCOUPLES_8,
  ];
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }

  //     if (route.route) {
  //       return <Route exact path={route.route} element={route.component} key={route.key} />;
  //     }

  //     return null;
  //   });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {/* {getRoutes(routes)} */}
        <Route path="/" element={<Presentation />} />
        {PRODUCT_ROUTES_2.map((route, idx) => {
          return <Route key={idx} path={route.path} element={<PageHeaders2 {...route} />} />;
        })}
        {PRODUCT_ROUTES.map((route, idx) => {
          return <Route key={idx} path={route.path} element={<PageHeaders {...route} />} />;
        })}

        <Route path="/pdf/1" element={<PDFViewer />} />
        <Route
          path={`/products/${slugify("Mineral Insulated Thermocouple Cable")}`}
          element={<p>ss</p>}
        />
        {THERMOCOUPLES.map((THERMOCOUPLE, id) => {
          return THERMOCOUPLE.map((route, idx) => {
            return (
              <Route
                key={`${id}-${idx}`}
                path={route.path}
                element={<OptionConfigurator {...route} />}
              />
            );
          });
        })}
        <Route />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
